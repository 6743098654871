import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { filter, first, map } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import { LanguageInput } from '../../types/graphql';
import { ILanguageRow } from '../../types/types/interfaces';

export enum FilterOption {
  All = 'All',
  Missing = 'Missing',
}

@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss'],
})
export class LanguageComponent implements OnInit {
  languageId: string;
  languageRows$: Observable<ILanguageRow[]>;

  filterMenuItems: MenuItem[];

  settingsMenuActivatedItem: MenuItem;

  private selectedFilterSubject = new BehaviorSubject<FilterOption>(
    FilterOption.All
  );

  constructor(private route: ActivatedRoute, public root: RootStore) {
    this.languageId = this.route.snapshot.paramMap.get('id');
  }

  async ngOnInit(): Promise<void> {
    await this.root.localizationStore.fetchLanguages();

    const t = await this.root.localizationStore.selectedLanguage();

    this.filterMenuItems = [
      {
        label: t.all,
        command: () => this.selectedFilterSubject.next(FilterOption.All),
      },
      {
        label: t.missing,
        command: () => this.selectedFilterSubject.next(FilterOption.Missing),
      },
    ];
    this.settingsMenuActivatedItem = this.filterMenuItems[0];

    this.languageRows$ = combineLatest([
      this.root.localizationStore
        .languageRows(this.languageId)
        .pipe(filter((languages) => !!languages?.length)),
      this.selectedFilterSubject,
    ]).pipe(
      map(([language, selectedFilter]) => {
        let result = language;
        if (selectedFilter === FilterOption.Missing) {
          result = language.filter((el) => !el.value);
        }
        return result;
      })
    );
  }

  async handleRowEditSave(key: string, value: string): Promise<void> {
    const language: LanguageInput =
      this.root.localizationStore.languageInputFromLang(
        (
          await this.root.localizationStore.languages$.pipe(first()).toPromise()
        ).find((el) => el.id === this.languageId)
      );

    (language as any)[key] = value;

    await this.root.localizationStore.updateLanguage(this.languageId, language);
  }
}
