<p-table
  [value]="rows"
  styleClass="p-datatable-striped"
  sortMode="multiple"
  [paginator]="paginator"
  [rows]="rowCount"
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <ng-template pTemplate="header">
    <tr>
      <th [pSortableColumn]="column.field" *ngFor="let column of columns">
        {{ column.name }} <p-sortIcon [field]="column.field || ''"></p-sortIcon>
      </th>
      <th *ngIf="viewFn || deleteFn || editFn">
        {{ selectedLanguage.actions }}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-value>
    <tr
      [ngClass]="{ 'is-prio': value.isHighPriority }"
      class="{{ value?.category?.key }}"
    >
      <td *ngFor="let column of columns">
        <p-tag
          *ngIf="column.field === 'category'; else normal"
          styleClass="p-mr-2 {{
            root.helperService.resolveField(value, column.field).key
          }}"
          [value]="root.helperService.resolveField(value, column.field).name"
          [rounded]="true"
        ></p-tag>
        <ng-template #normal>
          {{ root.helperService.resolveField(value, column.field) }}
        </ng-template>
      </td>
      <td *ngIf="editFn || deleteFn || unarchiveFn || lockFn || viewFn">
        <button
          *ngIf="viewFn"
          pButton
          icon="pi pi-eye"
          type="button"
          [title]="selectedLanguage.view"
          class="p-button-rounded p-button-secondary p-button-text"
          (click)="viewFn(value)"
        ></button>
        <button
          *ngIf="editFn"
          pButton
          icon="pi pi-pencil"
          type="button"
          [title]="selectedLanguage.edit"
          class="p-button-rounded p-button-secondary p-button-text"
          (click)="editFn(value)"
        ></button>
        <button
          *ngIf="deleteFn"
          pButton
          icon="pi pi-trash"
          type="button"
          [title]="selectedLanguage.delete"
          class="p-button-rounded p-button-secondary p-button-text"
          (click)="deleteFn(value)"
        ></button>
        <button
          *ngIf="unarchiveFn"
          pButton
          icon="pi pi-upload"
          type="button"
          [title]="selectedLanguage.unarchive_task"
          class="p-button-rounded p-button-secondary p-button-text"
          (click)="handleUnarchive($event, value)"
        ></button>
        <button
          *ngIf="lockFn"
          pButton
          [icon]="'pi ' + (value[activeField] ? 'pi-unlock' : 'pi-lock')"
          type="button"
          [title]="
            value[activeField] ? selectedLanguage.lock : selectedLanguage.unlock
          "
          class="p-button-rounded p-button-secondary p-button-text"
          (click)="handleLock($event, value)"
        ></button>
      </td>
    </tr>
  </ng-template>
</p-table>
