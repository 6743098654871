import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { Subject } from 'rxjs';

import { RootStore } from '../../store/root.store';
import { Company, CompanyInput } from '../../types/graphql';

export interface IModel extends Partial<CompanyInput> {}

@Component({
  selector: 'app-company-form',
  templateUrl: './company-form.component.html',
  styleUrls: ['./company-form.component.scss'],
})
export class CompanyFormComponent implements OnInit, OnDestroy {
  @Input('company') set _company(company: Company) {
    this.isReadyToRender = false;
    this.additionalLocationEntriesAmount = 0;
    this.company = company;
    this.setFormValues();
  }

  @Output() submitForm = new EventEmitter<IModel>();

  locationFields: FormlyFieldConfig[] = [];

  form = new FormGroup({});

  model: IModel = {
    companyName: '',
  };

  fields: FormlyFieldConfig[] = [];
  isReadyToRender = false;

  private company: Company;
  private componentDestroyedSubject = new Subject<void>();

  private additionalLocationEntriesAmount = 1;

  constructor(
    public root: RootStore,
    private messageService: MessageService,
    private cd: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) {}

  private async setFormValues(): Promise<void> {
    this.isReadyToRender = false;

    const t = await this.root.localizationStore.selectedLanguage();

    this.company?.locations.forEach((location, index) => {
      (this.model as any)['locationId_' + index] = location.id;
    });

    this.locationFields =
      this.company?.locations.flatMap((location, index) => [
        {
          key: 'locationName_' + index,
          type: 'input',
          templateOptions: {
            self: this,
            label: t.location_name,
            defaultValue:
              (this.model as any)['locationName_' + index] || location.name,
            wrapperClasses: ['p-mt-6'],
          },
        },
        {
          key: 'locationCity_' + index,
          type: 'input',
          templateOptions: {
            self: this,
            label: t.location_city,
            defaultValue:
              (this.model as any)['locationCity_' + index] || location.city,
          },
        },
        {
          key: 'locationCountry_' + index,
          type: 'input',
          templateOptions: {
            self: this,
            label: t.country,
            defaultValue:
              (this.model as any)['locationsCountry_' + index] ||
              location.country,
          },
        },
        {
          key: 'isActive_' + index,
          type: 'checkbox',
          templateOptions: {
            self: this,
            label: t.is_active,
            defaultValue:
              (this.model as any)['isActive_' + index] !== undefined
                ? (this.model as any)['isActive_' + index]
                : location.isActive !== undefined
                ? location.isActive
                : false,
          },
        },
      ]) || [];

    const additionalLocationFields = new Array(
      this.additionalLocationEntriesAmount
    )
      .fill(0)
      .flatMap((_, index) => {
        return [
          {
            key: 'newLocationName_' + index,
            type: 'input',
            templateOptions: {
              self: this,
              label: t.location_name,
              defaultValue:
                (this.model as any)['newLocationName_' + index] || '',
              wrapperClasses: ['p-mt-6'],
            },
          },
          {
            key: 'newLocationCity_' + index,
            type: 'input',
            templateOptions: {
              self: this,
              label: t.location_city,
              defaultValue:
                (this.model as any)['newLocationCity_' + index] || '',
            },
          },
          {
            key: 'newLocationCountry_' + index,
            type: 'input',
            templateOptions: {
              self: this,
              label: t.country,
              defaultValue:
                (this.model as any)['newLocationCountry_' + index] || '',
            },
          },
          ...(this.company
            ? [
                {
                  key: 'newIsActive_' + index,
                  type: 'checkbox',
                  templateOptions: {
                    self: this,
                    label: t.is_active,
                    defaultValue:
                      (this.model as any)['newIsActive_' + index] || true,
                  },
                },
              ]
            : []),
        ];
      });

    this.locationFields = [...this.locationFields, ...additionalLocationFields];
    this.fields = [
      {
        key: 'companyName',
        type: 'input',
        templateOptions: {
          self: this,
          label: t.company,
          defaultValue:
            (this.model as any).companyName || this.company?.companyName || '',
          required: true,
        },
      },
      ...this.locationFields,
    ].map((field) => ({
      ...field,
      templateOptions: {
        ...field.templateOptions,
        /**
         * @description should be called whenever a graphql mutation is warranted
         */
        handleChange: this.handleChange,
        /**
         * @description should be called every time the input changes
         * unless there is no difference to handleChange
         */
        handleInput: this.handleInput,
      },
    }));

    this.isReadyToRender = true;
    this.root.helperService.detectChanges(this.cd);
  }

  async ngOnInit(): Promise<void> {
    this.setFormValues();
  }

  ngOnDestroy(): void {
    this.componentDestroyedSubject.next();
    this.componentDestroyedSubject.complete();
  }

  async handleChange(): Promise<void> {
    this.root.helperService.detectChanges(this.cd);
  }

  handleInput(): void {
    this.root.helperService.detectChanges(this.cd);
  }

  /***
   * @description This function is only called if the submit button is clicked
   */
  handleSubmit(): void {
    this.submitForm.next(this.model);
  }

  async handleAddLocation(event: any): Promise<void> {
    const t = await this.root.localizationStore.selectedLanguage();

    this.confirmationService.confirm({
      message: t.warning_create_location,
      acceptLabel: t.yes,
      rejectLabel: t.no,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        event.preventDefault();
        this.additionalLocationEntriesAmount++;
        this.setFormValues();
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }
}
