import { gql } from 'apollo-angular';

export const MyselfQuery = gql`
  {
    myself {
      __typename
      id
      birthday
      createdAt
      deletedAt
      email
      emailVerifiedAt
      firstName
      lastName
      role
      mobile
      salutation
      positionInCompany
      telephone
      updatedAt
      role
      assignedLanguage {
        id
        language_name
        language_code
      }
      assignedLocation {
        id
        name
        assignedCompany {
          id
          companyName
        }
      }
      country
      followedTasks {
        id
      }
      isActiveUser
      profileImage {
        profileImagePath
      }
    }
  }
`;
