<div
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <div class="p-grid p-mb-6">
    <div class="p-col-2">
      <p-button
        label="+ {{ selectedLanguage.company }}"
        [style]="{ width: '100%' }"
        routerLink="create"
      ></p-button>
    </div>
    <div class="p-col-9">
      <input type="text" pInputText [(ngModel)]="searchText" />
    </div>
  </div>

  <ng-container *ngIf="displayedLocations$ | async as displayedLocations">
    <h1 class="p-ml-2 p-mb-3">{{ selectedLanguage.company }}</h1>
    <app-primeng-table
      [columns]="columns"
      [rows]="displayedLocations"
      [editFn]="navigateToCompany.bind(this)"
      [lockFn]="toggleLockForLocation.bind(this)"
      activeField="isActive"
    ></app-primeng-table>
  </ng-container>
</div>
