import { gql } from 'apollo-angular';

export const EditCompanyMutation = gql`
  mutation EditCompany($company: CompanyUpdate!, $companyId: String!) {
    editCompany(company: $company, companyId: $companyId) {
      __typename
      id
      companyLogo
      companyName
      createdAt
      deletedAt
      updatedAt
      locations {
        id
        name
        isActive
        country
        city
      }
    }
  }
`;
