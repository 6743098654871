import { gql } from 'apollo-angular';

export const MyselfLocationsQuery = gql`
  {
    myself {
      __typename
      id
      assignedLocation {
        id
        assignedCompany {
          id
          locations {
            id
            name
          }
        }
      }
    }
  }
`;
