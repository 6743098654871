import { OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';

export abstract class TextChangeField
  extends FieldType
  implements OnInit, OnDestroy
{
  error = '';

  slowTextChange$ = new Subject();
  slowTextChangeSubscription: Subscription;

  protected constructor(private root: RootStore) {
    super();
  }
  ngOnInit(): void {
    this.slowTextChangeSubscription = this.slowTextChange$
      .pipe(debounceTime(500))
      .subscribe(this.to.handleChange.bind(this.to.self));
  }

  ngOnDestroy(): void {
    if (this.slowTextChangeSubscription) {
      this.slowTextChangeSubscription.unsubscribe();
    }
  }

  handleTextChange(): void {
    if (this.to.handleInput) {
      this.to.handleInput.bind(this.to.self)();
    }

    this.slowTextChange$.next();

    this.error = this.root.helperService.errorMessageFromErrors(
      this.formControl.errors
    );
  }
}
