
    <div
      class="p-grid p-field"
      [ngClass]="to.wrapperClasses"
      *ngIf="
        root.localizationStore.selectedLanguage$ | async as selectedLanguage
      "
    >
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col-5 p-pb-0">
        <input
          type="file"
          style="display: none"
          [formlyAttributes]="field"
          (change)="handleFilesSelected($event)"
          #fileUpload
        />

        <p-button
          *ngIf="!to.disabled"
          (click)="handleClick(fileUpload)"
          label="+ {{ selectedLanguage.upload_attachments }}"
          styleClass="p-button-outlined"
        ></p-button>
      </div>
    </div>
  