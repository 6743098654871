import { Routes } from '@angular/router';

import { PermissionGuard } from '../guards/permission.guard';
import { LanguageComponent } from '../pages/language/language.component';
import { LanguagesComponent } from '../pages/languages/languages.component';
import { UserAction } from '../types/types/enums';

import { companyRoutes } from './company.routes';
import { userRoutes } from './user.routes';

export const settingsRoutes: Routes = [
  {
    path: 'settings',
    redirectTo: 'settings/users',
    pathMatch: 'full',
  },
  {
    path: 'settings',
    children: [
      ...companyRoutes,
      ...userRoutes,
      { path: 'languages', component: LanguagesComponent },
      { path: 'languages/:id', component: LanguageComponent },
    ],
    canActivateChild: [PermissionGuard],
    data: { do: [UserAction.FilterAll] },
  },
];
