import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RootStore } from '../../store/root.store';

@Component({
  selector: 'app-languages-settings',
  templateUrl: './languages.component.html',
  styleUrls: ['./languages.component.scss'],
})
export class LanguagesComponent implements OnInit {
  constructor(public root: RootStore, private router: Router) {}

  ngOnInit(): void {
    this.root.localizationStore.fetchLanguages();
  }

  navigateToLanguage(languageId: string): void {
    this.router.navigateByUrl('settings/languages/' + languageId);
  }
}
