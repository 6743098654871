import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { first, map, tap } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import { TaskInput } from '../../types/graphql';
import { IDropdownOption } from '../../types/types/interfaces';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
})
export class CreateTaskComponent implements OnInit {
  constructor(private root: RootStore, private router: Router) {}

  async ngOnInit(): Promise<void> {}

  async handleSubmit(model: TaskInput): Promise<void> {
    const taskInput = this.root.tasksService.sanitizeTaskInput(model);
    const task = await this.root.tasksStore.createTask(taskInput);

    this.root.applicationStore.changesSaved();

    task
      .pipe(
        tap((el) => this.router.navigateByUrl('tasks/' + el.value.id)),
        first()
      )
      .subscribe();
  }
}
