import * as l from 'lodash';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';

import {
  AddFollowerMutation,
  ArchiveTaskMutation,
  CreateCommentMutation,
  CreateFileMutation,
  DeleteFileMutation,
  EditTaskMutation,
  RemoveFollowerMutation,
} from '../../graphql/mutations';
import { CreateTaskMutation } from '../../graphql/mutations';
import { TaskQuery, TasksQuery } from '../../graphql/queries';
import { CommentInput, Task, TaskUpdate, User } from '../../types/graphql';
import { File as GraphqlFile } from '../../types/graphql';
import { TaskInput } from '../../types/graphql';
import { GraphqlService } from '../services/graphql.service';
import { HelperService } from '../services/helper.service';
import { TasksService } from '../services/tasks.service';

import { LocalizationStore } from './localization.store';
import { TaskStore } from './task.store';

/**
 * @description The TasksStore takes care of fetching, editing and deleting of tasks
 */
export class TasksStore {
  tasks$: Observable<TaskStore[]>;

  private localizationStore: LocalizationStore;
  private graphqlService: GraphqlService;

  private tasksSubject = new BehaviorSubject<TaskStore[]>([]);
  constructor(
    private messageService: MessageService,
    private helperService: HelperService,
    private tasksService: TasksService
  ) {
    this.tasks$ = this.tasksSubject
      .asObservable()
      .pipe(filter((tasks) => !!tasks[0]?.value?.assignedLocation)); // Ensure that all required fields were loaded
  }

  private insertTask(taskStore: TaskStore): void {
    this.helperService.insertIntoListSubject(this.tasksSubject, taskStore);
  }

  /**
   * @description Not an update function!
   * This converts a TaskInput into a TaskUpdate
   */
  private async taskUpdateFromTaskInput(
    taskId: string,
    taskInput: Partial<TaskInput>
  ): Promise<Partial<TaskUpdate>> {
    return await this.tasks$
      .pipe(
        first(),
        map((tasks) => tasks.map((el) => el.value)),
        map((tasks) => tasks.find((el) => el.id === taskId)),
        map((task: Task) => {
          return this.helperService.updateFromInput(
            taskInput,
            this.tasksService.taskInputFromTask(task)
          );
        })
      )
      .toPromise();
  }

  construct(
    localizationStore: LocalizationStore,
    graphqlService: GraphqlService
  ): void {
    this.localizationStore = localizationStore;
    this.graphqlService = graphqlService;
  }

  async deleteFile(fileId: string): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();

    await this.graphqlService
      .sendRequest<GraphqlFile>({
        field: 'deleteFile',
        mutation: DeleteFileMutation,
        variables: {
          fileId,
        },
        loadingMessage: t?.loading_update_task,
      })
      .pipe(first())
      .toPromise();
  }

  async addFileToTask(
    taskId: string,
    file: File,
    fileTitle?: string,
    fileDescription?: string
  ): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();

    await this.getTask(taskId)
      .pipe(
        first(),
        switchMap((taskStore: TaskStore) => {
          return this.graphqlService
            .sendRequest<GraphqlFile>({
              field: 'createFile',
              mutation: CreateFileMutation,
              variables: {
                file: {
                  taskId,
                  file,
                  fileTitle: fileTitle || file.name,
                  fileDescription,
                },
              },
              loadingMessage: t?.loading_create_file,
              useMultipart: true,
            })
            .pipe(
              tap((graphqlFile) => {
                const nonce: TaskStore = l.cloneDeep(taskStore);
                nonce.value.files.push(graphqlFile);

                this.insertTask(nonce);
              })
            );
        })
      )
      .toPromise()
      .then(() => {
        this.messageService.add({
          severity: 'success',
          summary: t.feedback_updated_task_file,
          detail: t.feedback_updated_task_file,
        });
      });
  }

  async updateTask(
    taskId: string,
    taskInput: Partial<TaskInput>
  ): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();
    const taskUpdate = await this.taskUpdateFromTaskInput(taskId, taskInput);

    if (!!Object.keys(taskUpdate)?.length) {
      await this.graphqlService
        .sendRequest<Task>({
          field: 'editTask',
          mutation: EditTaskMutation,
          variables: { task: taskUpdate, taskId },
          // loadingMessage: t?.loading_update_task,
        })
        .pipe(
          map((task) => new TaskStore(task)),
          tap(this.insertTask.bind(this)),
          first()
        )
        .toPromise()
        .then(() => {
          if (taskUpdate.assignedLocationId) {
            this.messageService.add({
              severity: 'success',
              summary: t.feedback_updated_task_location,
              detail: t.feedback_updated_task_location,
            });
          } else if (taskUpdate.assignedUserId) {
            this.messageService.add({
              severity: 'success',
              summary: t.feedback_updated_task_assignee,
              detail: t.feedback_updated_task_assignee,
            });
          } else if (taskUpdate.status) {
            this.messageService.add({
              severity: 'success',
              summary: t.feedback_updated_task_status,
              detail: t.feedback_updated_task_status,
            });
          }
        });
    }
  }

  async archiveTask(taskId: string, archive: boolean): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();

    await this.graphqlService
      .sendRequest<Task>({
        field: 'archiveTask',
        mutation: ArchiveTaskMutation,
        variables: { taskId, archive },
        loadingMessage: archive
          ? t?.loading_archive_task
          : t?.loading_unarchive_task,
      })
      .pipe(
        first(),
        map((task) => new TaskStore(task)),
        tap(this.insertTask.bind(this))
      )
      .toPromise();
  }

  async createTask(taskInput: TaskInput): Promise<Observable<TaskStore>> {
    const t = await this.localizationStore.selectedLanguage();

    return this.graphqlService
      .sendRequest<Task>({
        field: 'createTask',
        mutation: CreateTaskMutation,
        variables: { task: taskInput },
        loadingMessage: t?.loading_create_task,
      })
      .pipe(
        map((task) => new TaskStore(task)),
        tap(this.insertTask.bind(this)),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t?.feedback_created_task,
          })
        ),
        first()
      );
  }

  async fetchTask(taskId: string): Promise<void> {
    const t = await this.localizationStore?.selectedLanguage();
    await this.graphqlService
      .sendRequest<Task>({
        field: 'task',
        query: TaskQuery,
        variables: { taskId },
        loadingMessage: t?.loading_get_task,
      })
      .pipe(
        map((task) => new TaskStore(task)),
        tap(this.insertTask.bind(this)),
        first()
      )
      .toPromise();
  }

  async fetchTasks(): Promise<void> {
    const t = await this.localizationStore?.selectedLanguage();

    await this.graphqlService
      .sendRequest<Task[]>({
        field: 'tasks',
        query: TasksQuery,
        loadingMessage: t?.loading_get_tasks,
      })
      .pipe(
        map((tasks) => tasks.map((task: Task) => new TaskStore(task))),
        tap((taskStores) => this.tasksSubject.next(taskStores)),
        first()
      )
      .toPromise();
  }

  getTask(taskId: string): Observable<TaskStore> {
    return this.tasks$.pipe(
      map((tasks) => tasks.find((task) => task.value.id === taskId))
    );
  }

  /**
   * @description This function fetches the task after successful mutation
   */
  async createComment(commentInput: CommentInput): Promise<void> {
    const t = await this.localizationStore?.selectedLanguage();
    await this.graphqlService
      .sendRequest<Comment>({
        field: 'createComment',
        mutation: CreateCommentMutation,
        variables: { comment: commentInput },
        loadingMessage: t?.loading_create_comment,
      })
      .pipe(
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t?.feedback_created_comment,
          })
        ),
        tap(() => this.fetchTask(commentInput.taskId)),
        first()
      )
      .toPromise();
  }

  /**
   * @description This function doesn't mutate the task in the tasks store!
   */
  async removeFollower(taskId: string, followerId: string): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();
    await this.graphqlService
      .sendRequest<User>({
        field: 'removeFollower',
        mutation: RemoveFollowerMutation,
        variables: { taskId, followerId },
        loadingMessage: t.loading_remove_follower,
      })
      .pipe(first())
      .toPromise();
  }

  /**
   * @description This function doesn't mutate the task in the tasks store!
   */
  async addFollower(taskId: string, followerId: string): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();
    await this.graphqlService
      .sendRequest<User>({
        field: 'addFollower',
        mutation: AddFollowerMutation,
        variables: { taskId, followerId },
        loadingMessage: t.loading_add_follower,
      })
      .pipe(first())
      .toPromise();
  }

  resetTasks(): void {
    this.tasksSubject.next([]);
  }
}
