import { gql } from 'apollo-angular';

export const CreateCompanyMutation = gql`
  mutation CreateCompany($company: CompanyInput!) {
    createCompany(company: $company) {
      __typename
      id
    }
  }
`;
