import { DatePipe, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormlyModule } from '@ngx-formly/core';
import { FormlySelectModule } from '@ngx-formly/core/select';
import { AccordionModule } from 'primeng/accordion';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { AvatarModule } from 'primeng/avatar';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MultiSelectModule } from 'primeng/multiselect';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { SplitterModule } from 'primeng/splitter';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FormlyCheckboxComponent } from './components/formly/formly-checkbox/formly-checkbox.component';
import { FormlyDatePickerComponent } from './components/formly/formly-date-picker/formly-date-picker.component';
import { FormlyFileUploadComponent } from './components/formly/formly-file-upload/formly-file-upload.component';
import { FormlyInputComponent } from './components/formly/formly-input/formly-input.component';
import { FormlySelectComponent } from './components/formly/formly-select/formly-select.component';
import { FormlyTextEditorComponent } from './components/formly/formly-text-editor/formly-text-editor.component';
import { PrimengTableComponent } from './components/primeng-table/primeng-table.component';
import { DeskNavigationComponent } from './components/scaffolding/desk-navigation/desk-navigation.component';
import { ScaffoldingComponent } from './components/scaffolding/scaffolding.component';
import { HasPermissionsDirective } from './directives/has-permissions.directive';
import { CompanyFormComponent } from './form-components/company-form/company-form.component';
import { TaskFormComponent } from './form-components/task-form/task-form.component';
import { UserFormComponent } from './form-components/user-form/user-form.component';
import { GraphQLModule } from './graphql.module';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { CompaniesSettingsComponent } from './pages/companies-settings/companies-settings.component';
import { CompanyComponent } from './pages/company/company.component';
import { CreateCompanyComponent } from './pages/create-company/create-company.component';
import { CreateTaskComponent } from './pages/create-task/create-task.component';
import { CreateUserComponent } from './pages/create-user/create-user.component';
import { DoesntExistComponent } from './pages/doesnt-exist/doesnt-exist.component';
import { LanguageComponent } from './pages/language/language.component';
import { LanguagesComponent } from './pages/languages/languages.component';
import { LoginComponent } from './pages/login/login.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { TaskComponent } from './pages/task/task.component';
import { TaskCardComponent } from './pages/tasks/task-card/task-card.component';
import { TasksComponent } from './pages/tasks/tasks.component';
import { TranslateLanguageComponent } from './pages/translate-language/translate-language.component';
import { UserComponent } from './pages/user/user.component';
import { UsersComponent } from './pages/users/users.component';
import { LocalizePipe } from './pipes/localize.pipe';

registerLocaleData(localeDe);

const primeNgModules = [
  TagModule,
  EditorModule,
  CardModule,
  AccordionModule,
  AutoCompleteModule,
  CalendarModule,
  FormsModule,
  InputTextModule,
  InputTextareaModule,
  InputMaskModule,
  InputNumberModule,
  InputSwitchModule,
  SidebarModule,
  BrowserAnimationsModule,
  MenubarModule,
  MenuModule,
  AvatarModule,
  SelectButtonModule,
  ProgressSpinnerModule,
  TabMenuModule,
  DropdownModule,
  InputTextareaModule,
  OverlayPanelModule,
  CheckboxModule,
  ToastModule,
  DividerModule,
  SplitterModule,
  TableModule,
  ConfirmDialogModule,
  MultiSelectModule,
  SkeletonModule,
];

const formlyComponents = [
  FormlyInputComponent,
  FormlySelectComponent,
  FormlyDatePickerComponent,
  FormlyTextEditorComponent,
  FormlyCheckboxComponent,
  FormlyFileUploadComponent,
];

const components = [
  formlyComponents,
  PrimengTableComponent,
  TaskFormComponent,
  UserFormComponent,
  AppComponent,
  LoginComponent,
  CompaniesSettingsComponent,
  LanguagesComponent,
  LanguageComponent,
  MyProfileComponent,
  TasksComponent,
  CreateCompanyComponent,
  TaskComponent,
  CreateUserComponent,
  UserComponent,
  TranslateLanguageComponent,
  CreateTaskComponent,
  UsersComponent,
  ScaffoldingComponent,
  DoesntExistComponent,
  DeskNavigationComponent,
  TaskCardComponent,
  CompanyComponent,
  CompanyFormComponent,
];

const pipes = [LocalizePipe];

const directives = [HasPermissionsDirective];

/**
 * @description This is the one and only module used in this project
 */
@NgModule({
  declarations: [components, pipes, directives],
  imports: [
    primeNgModules,
    BrowserModule,
    AppRoutingModule,
    GraphQLModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    FormlyModule.forRoot({
      types: [
        { name: 'input', component: FormlyInputComponent },
        { name: 'select', component: FormlySelectComponent },
        { name: 'date-picker', component: FormlyDatePickerComponent },
        { name: 'text-editor', component: FormlyTextEditorComponent },
        { name: 'checkbox', component: FormlyCheckboxComponent },
        { name: 'file-upload', component: FormlyFileUploadComponent },
      ],
      validationMessages: [
        {
          name: 'required',
          message: 'cant_be_empty',
        },
      ],
      extras: { lazyRender: true },
    }),
    FormlySelectModule,
    RadioButtonModule,
    RippleModule,
  ],
  providers: [
    MessageService,
    ConfirmationService,
    PendingChangesGuard,
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
