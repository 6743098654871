<div
  class="p-grid"
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <div class="p-col-6">
    <h2 class="p-mb-6">{{ selectedLanguage.user_details }}</h2>
    <div class="p-fluid" *ngIf="(user$ | async)?.value as user; else skeleton">
      <app-user-form
        [isSaveable]="true"
        [user]="user"
        [asAdmin]="true"
        (submitForm)="handleSubmit($event)"
      >
      </app-user-form>
    </div>
    <ng-template #skeleton>
      <div class="p-fluid">
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3 p-mt-6" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3 p-mt-6" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
      </div>
    </ng-template>
  </div>
</div>
