import * as l from 'lodash';
import * as moment from 'moment';
import { Task } from 'src/app/types/graphql';

/**
 * @description This class has to be instantiated by an instance of the TasksStore ("parent")!
 */
export class TaskStore {
  // TODO: Reimplement
  // @action deleteTask(): void {
  //   this.parent.removeTask(this.id);
  // }

  get categoryTuple(): [string, string] {
    return [l.startCase(this.value.category), this.value.category];
  }
  get statusTuple(): [string, string] {
    return [l.startCase(this.value.status), this.value.status];
  }

  get dueDate(): Date {
    return this.value?.dueDate ? new Date(this.value.dueDate) : null;
  }

  private backup: Task;
  constructor(public value: Task) {
    this.backup = { ...value };
  }

  dueDateAsDate(): Date | undefined {
    return this.value.dueDate ? moment(this.value.dueDate).toDate() : undefined;
  }

  dueDateColor(): 'green' | 'yellow' | 'red' {
    const dueDate = moment(this.value.dueDate);
    const now = moment(new Date());
    const difference = moment.duration(dueDate.diff(now)).asHours();

    if (difference <= 24) {
      return 'red';
    } else if (difference < 72 && difference > 24) {
      return 'yellow';
    } else {
      return 'green';
    }
  }

  reset(): void {
    this.value = { ...this.backup };
  }
}
