import * as l from 'lodash';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import {
  CreateProfileImageMutation,
  CreateUserMutation,
  DeleteProfileImageMutation,
} from '../../graphql/mutations';
import { EditUserMutation } from '../../graphql/mutations/edit-user.mutation';
import { MyselfUsersQuery, UserQuery, UsersQuery } from '../../graphql/queries';
import { ProfileImage, User, UserInput, UserUpdate } from '../../types/graphql';
import { IUser } from '../../types/types/interfaces/user.interface';
import { AuthService } from '../services/auth.service';
import { GraphqlService } from '../services/graphql.service';
import { HelperService } from '../services/helper.service';
import { UsersService } from '../services/users.service';

import { LocalizationStore } from './localization.store';
import { UserStore } from './user.store';

/**
 * @description The UsersStore takes care of fetching users
 */
export class UsersStore {
  usersSubject = new BehaviorSubject<UserStore[]>([]);
  users$: Observable<UserStore[]>;

  private usersService: UsersService;
  private graphqlService: GraphqlService;
  private localizationStore: LocalizationStore;
  private authService: AuthService;
  constructor(
    private helperService: HelperService,
    private messageService: MessageService
  ) {
    this.users$ = this.usersSubject.asObservable();
  }

  private insertUser(userStore: UserStore): void {
    this.helperService.insertIntoListSubject(this.usersSubject, userStore);
  }

  /***
   * @description Not an update function!
   * This converts a UserInput into a UserUpdate
   * This version of this function differs from the others and might lead to inconsistencies down the line! TODO: Adjust implementation to be consistent with others
   */
  private async userUpdateFromUserInput(
    userId: string,
    userInput: Partial<UserInput>
  ): Promise<Partial<UserUpdate>> {
    return this.helperService.updateFromInput(
      l.omit(userInput, ['birthday', 'salutation', 'emailVerifiedAt']) // HOTFIX: delete unused Fields
    );
  }

  construct(
    usersService: UsersService,
    graphqlService: GraphqlService,
    localizationStore: LocalizationStore
  ): void {
    this.usersService = usersService;
    this.graphqlService = graphqlService;
    this.localizationStore = localizationStore;
  }

  async fetchUser(userId: string): Promise<void> {
    const t = await this.localizationStore?.selectedLanguage();

    await (
      await this.graphqlService.sendRequest<User>({
        field: 'user',
        query: UserQuery,
        variables: { userId },
        loadingMessage: t?.loading_get_users,
      })
    )
      .pipe(
        map((user) => new UserStore(this.usersService.IUserFromUser(user))),
        tap((val) => this.insertUser(val))
      )
      .toPromise();
  }

  async fetchMyLocationUsers(): Promise<User[]> {
    const t = await this.localizationStore?.selectedLanguage();

    return await this.graphqlService
      .sendRequest<User>({
        field: 'myself',
        query: MyselfUsersQuery,
      })
      .pipe(
        filter((val) => !!val?.assignedLocation?.users),
        map((user) => user.assignedLocation.users),
        first()
      )
      .toPromise();
  }

  async updateUser(
    userId: string,
    userInput: Partial<UserInput>
  ): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();
    const userUpdate = await this.userUpdateFromUserInput(userId, userInput);

    await this.graphqlService
      .sendRequest<User>({
        field: 'editUser',
        mutation: EditUserMutation,
        variables: { user: userUpdate, userId },
        loadingMessage: t.loading_update_user,
      })
      .pipe(
        map((user) => new UserStore(this.usersService.IUserFromUser(user))),
        tap((user) => {
          this.insertUser(user);
        }),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t.feedback_updated_user,
          })
        ),
        first()
      )
      .toPromise();
  }

  async fetchUsers(): Promise<void> {
    const selectedLanguage = await this.localizationStore?.selectedLanguage();

    await (
      await this.graphqlService.sendRequest<User[]>({
        field: 'users',
        query: UsersQuery,
        loadingMessage: selectedLanguage.loading_get_users,
      })
    )
      .pipe(
        map((users) =>
          users.map(
            (user) => new UserStore(this.usersService.IUserFromUser(user))
          )
        ),
        tap((userStores) => this.usersSubject.next(userStores))
      )
      .toPromise();
  }

  getUser(userId: string): Observable<UserStore> {
    return this.users$.pipe(
      map((users) => users.find((user) => user.value.id === userId))
    );
  }

  filteredUsers(users: IUser[], text: string): IUser[] {
    return !!text?.length
      ? users.filter((user) =>
          [
            user.lastName,
            user.firstName,
            user.email,
            user.assignedLocation?.assignedCompany?.companyName,
            user.country,
          ].find((fieldValue) =>
            fieldValue?.toLowerCase().includes(text.toLowerCase())
          )
        )
      : users;
  }

  async createUser(userInput: UserInput): Promise<Observable<UserStore>> {
    const t = await this.localizationStore.selectedLanguage();

    return this.graphqlService
      .sendRequest<User>({
        field: 'createUser',
        mutation: CreateUserMutation,
        variables: { user: userInput },
        loadingMessage: t.loading_create_user,
      })
      .pipe(
        map((user) => new UserStore(this.usersService.IUserFromUser(user))),
        tap((user) => this.insertUser(user)),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t.feedback_created_user,
          })
        ),
        first()
      );
  }

  async changeProfileImage(
    profileImageFile: File,
    userId: string,
    hasProfile: boolean
  ): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();

    if (hasProfile) {
      await this.graphqlService
        .sendRequest<ProfileImage>({
          field: 'deleteProfileImage',
          mutation: DeleteProfileImageMutation,
          variables: {
            userId,
          },
        })
        .toPromise();
    }
    await this.graphqlService
      .sendRequest<ProfileImage>({
        field: 'createProfileImage',
        mutation: CreateProfileImageMutation,
        variables: {
          profileImage: {
            profileImage: profileImageFile,
            profileImageTitle: profileImageFile.name,
          },
        },
        useMultipart: true,
      })
      .toPromise();
  }
}
