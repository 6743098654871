import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as l from 'lodash';
import { Observable, Subject } from 'rxjs';
import { filter, first, map, takeUntil, tap } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import { CompanyStore } from '../../store/stores/company.store';
import { Language, Location, UserInput, UserRole } from '../../types/graphql';
import { IDropdownOption } from '../../types/types/interfaces';
import { IUser } from '../../types/types/interfaces/user.interface';

interface Model extends Partial<UserInput> {
  assignedCompany: string;
}

@Component({
  selector: 'app-user-form',
  templateUrl: './user-form.component.html',
  styleUrls: ['./user-form.component.scss'],
})
export class UserFormComponent implements OnInit, OnDestroy {
  @Input() user: IUser;
  @Input() isSaveable = true;
  @Input() asAdmin: boolean;

  @Output() submitForm = new EventEmitter<Partial<UserInput>>();

  form = new FormGroup({});

  model: Model = {
    firstName: '',
    lastName: '',
    email: '',
    telephone: '',
    mobile: '',
    country: '',
    assignedLocation: null,
    assignedCompany: null,
    isActiveUser: null,
    positionInCompany: '',
    role: null,
    assignedLanguage: null,
  };

  fields: FormlyFieldConfig[] = [];

  private componentDestroyedSubject = new Subject<void>();

  constructor(private root: RootStore, private cd: ChangeDetectorRef) {}

  private async setFormValues(): Promise<void> {
    await this.root.companiesStore.fetchCompanies();
    await this.root.companiesStore.fetchLocations();

    this.model.isActiveUser = this.user ? this.user.isActiveUser : true; // Set User to active on creation

    let roleOptions = await this.root.helperService.dropdownOptionsFromEnum(
      UserRole
    );
    if (!this.asAdmin) {
      roleOptions = roleOptions.filter((el) => el.value !== UserRole.Admin);
    }

    const defaultRole =
      this.user?.role ||
      (await this.root.helperService.dropdownOptionsFromEnum(UserRole))[1] // cant be [0] because Role Admin gets filtered out
        .value;

    const languageOptions = await this.root.localizationStore.languages$
      .pipe(
        map((languages: Language[]) =>
          languages.map((language) =>
            this.root.localizationStore.dropdownOptionFromLanguage(language)
          )
        ),
        filter((val) => !!val),
        first()
      )
      .toPromise();

    const defaultLanguageId =
      this.user?.assignedLanguage?.id ||
      (await this.root.localizationStore.selectedLanguage()).id;

    const defaultCompany =
      this.user?.assignedLocation.assignedCompany ||
      (
        (await this.root.authService.myself$
          .pipe(
            filter((val) => !!val),
            first()
          )
          .toPromise()) as IUser
      ).assignedLocation.assignedCompany;

    let companyOptions: IDropdownOption[];

    if (this.asAdmin) {
      companyOptions = (
        (await this.root.companiesStore.companies$
          .pipe(
            filter((val) => !!val?.length),
            first()
          )
          .toPromise()) as CompanyStore[]
      ).map((company) =>
        this.root.companiesStore.dropdownOptionFromCompany(company.value)
      );
    } else {
      companyOptions = [
        this.root.companiesStore.dropdownOptionFromCompany(defaultCompany),
      ];
    }

    const defaultLocation =
      this.user?.assignedLocation ||
      (
        (await this.root.authService.myself$
          .pipe(
            filter((val) => !!val),
            first()
          )
          .toPromise()) as IUser
      ).assignedLocation;

    const locationOptions = [
      this.root.companiesStore.dropdownOptionFromLocation(defaultLocation),
    ];

    const t = await this.root.localizationStore.selectedLanguage();

    this.fields = [
      {
        key: 'firstName',
        type: 'input',
        templateOptions: {
          label: t.first_name,
          defaultValue: this.user?.firstName || '',
        },
      },
      {
        key: 'lastName',
        type: 'input',
        templateOptions: {
          label: t.last_name,
          defaultValue: this.user?.lastName || '',
          required: true,
        },
      },
      {
        key: 'email',
        type: 'input',
        templateOptions: {
          required: true,
          type: 'email',
          label: t.email_address,
          defaultValue: this.user?.email || '',
          disabled: !this.asAdmin,
        },
      },
      {
        key: 'telephone',
        type: 'input',
        templateOptions: {
          type: 'tel',
          label: t.phone_number,
          defaultValue: this.user?.telephone || '',
          errorMessage: 'PATTERN',
        },
      },
      {
        key: 'mobile',
        type: 'input',
        templateOptions: {
          type: 'tel',
          label: t.mobile_number,
          defaultValue: this.user?.mobile || '',
          errorMessage: 'PATTERN',
        },
      },
      {
        key: 'country',
        type: 'input',
        templateOptions: {
          label: t.country,
          defaultValue: this.user?.country || '',
        },
      },
      {
        key: 'assignedCompany',
        type: 'select',
        templateOptions: {
          disabled: !this.asAdmin,
          label: t.company,
          defaultValue: defaultCompany.id,
          multiple: false,
          options: companyOptions,
          wrapperClasses: ['p-mt-6'],
        },
      },
      {
        key: 'assignedLocation',
        type: 'select',
        templateOptions: {
          disabled: !this.asAdmin,
          label: t.location,
          options: locationOptions,
          defaultValue: defaultLocation.id,
          multiple: false,
        },
        hooks: {
          onInit: (field: FormlyFieldConfig) => {
            if (this.asAdmin) {
              this.handleCompanyIdChange(
                field,
                field.form.get('assignedCompany').value
              );

              field.form
                .get('assignedCompany')
                .valueChanges.pipe(
                  tap((assignedCompanyId: string) => {
                    this.handleCompanyIdChange(field, assignedCompanyId);
                  }),
                  takeUntil(this.componentDestroyedSubject)
                )
                .subscribe();
            }
          },
        },
      },
      {
        key: 'positionInCompany',
        type: 'input',
        templateOptions: {
          label: t.position_in_company,
          defaultValue: this.user?.positionInCompany || '',
        },
      },
      {
        key: 'role',
        type: 'select',
        templateOptions: {
          disabled: !this.asAdmin,
          required: true,
          label: t.role,
          defaultValue: defaultRole,
          multiple: false,
          options: roleOptions,
          wrapperClasses: ['p-mt-6'],
        },
      },
      {
        key: 'assignedLanguage',
        type: 'select',
        templateOptions: {
          required: true,
          label: t.language,
          defaultValue: defaultLanguageId,
          multiple: false,
          options: languageOptions,
        },
      },
    ].map((el) => ({ ...el, self: this }));

    this.fields = this.fields.map((field) => ({
      ...field,
      templateOptions: {
        ...field.templateOptions,
        self: this,
        /**
         * @description should be called whenever a graphql mutation is warranted
         */
        handleChange: this.handleChange,
        /**
         * @description should be called every time the input changes
         * unless there is no difference to handleChange
         */
        handleInput: this.handleInput,
      },
    }));

    this.root.helperService.detectChanges(this.cd);
  }

  /**
   * @description This function has a side effect
   */
  private handleCompanyIdChange(
    field: FormlyFieldConfig,
    companyId: string
  ): void {
    (field.templateOptions.options as Observable<IDropdownOption[]>) =
      this.root.companiesStore.locations$.pipe(
        filter((val) => !!val?.length),
        map((locations: Location[]) =>
          locations.filter(
            (location) => location.assignedCompany.id === companyId
          )
        ),
        map((locations: Location[]) =>
          locations.map((location) =>
            this.root.companiesStore.dropdownOptionFromLocation(location)
          )
        )
      );
  }

  async ngOnInit(): Promise<void> {
    const userRole = (
      await this.root.authService.myself$.pipe(first()).toPromise()
    ).role;
    if (userRole === UserRole.Admin || userRole === UserRole.Manager) {
      this.asAdmin = true;
    } else {
      this.asAdmin = false;
    }

    await this.setFormValues();

    this.root.applicationStore.warnOfUnsavedChanges(
      this.form,
      this.componentDestroyedSubject
    );
  }

  ngOnDestroy(): void {
    this.componentDestroyedSubject.next();
    this.componentDestroyedSubject.complete();
  }

  handleChange(): void {}

  handleInput(): void {}

  handleSubmit(userModel: Model): void {
    this.submitForm.emit(
      l.omit(userModel, ['assignedCompany']) as Partial<UserInput>
    );
  }
}
