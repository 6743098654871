
    <div class="p-grid p-field-checkbox" [ngClass]="to.wrapperClasses">
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col-1 p-pb-0">
        <p-checkbox
          [disabled]="to.disabled"
          [(ngModel)]="to.defaultValue"
          [binary]="true"
          (onChange)="handleChange(field, $event)"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
        ></p-checkbox>
      </div>
    </div>
  