import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import * as l from 'lodash';
import { MessageService } from 'primeng/api';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, filter, first, map, tap } from 'rxjs/operators';

import { IModel } from '../../form-components/task-form/task-form.component';
import { FileQuery } from '../../graphql/queries';
import { RootStore } from '../../store/root.store';
import { File as GraphqlFile, Task, TaskInput } from '../../types/graphql';

// TODO: Real time updates don't yet work with this component for some reason
@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskComponent implements OnInit, OnDestroy {
  task$: Observable<Task>;

  taskId: string;
  loaded = false;
  @ViewChild('commentInput') commentInput: ElementRef;

  private clearFetchTaskInterval: () => void;

  constructor(
    public root: RootStore,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private messageService: MessageService
  ) {
    this.taskId = this.route.snapshot.paramMap.get('id');
  }

  private dataURItoBlob(dataURI: string): Blob {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  async ngOnInit(): Promise<void> {
    this.root.tasksStore.resetTasks();

    this.clearFetchTaskInterval = this.root.helperService.regularlyCall(() =>
      this.root.tasksStore.fetchTask(this.taskId)
    );

    const task$: Observable<Task> = this.root.tasksStore.tasks$.pipe(
      filter((tasks) => !!tasks.length),
      map((tasks) => tasks.find((task) => task.value.id === this.taskId)),
      map((task) => task?.value),
      filter((val) => !!val)
    );

    this.task$ = task$.pipe(
      map((task) => l.cloneDeep(task)),
      distinctUntilChanged((prev, curr) => l.isEqual(prev, curr))
    );

    combineLatest([
      this.route.url.pipe(map((els) => els.map((el) => el.path))),
      this.task$,
    ])
      .pipe(
        first(),
        tap(([urlSegments, task]: [string[], Task]) => {
          if (
            task.archivedAt &&
            !urlSegments.find((url) => url === 'archive')
          ) {
            this.router.navigateByUrl('archive/tasks/' + task.id);
          } else if (
            !task.archivedAt &&
            urlSegments.find((url) => url === 'archive')
          ) {
            this.router.navigateByUrl('tasks/' + task.id);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.clearFetchTaskInterval();
  }

  handleSubmit(model: IModel): void {
    this.root.tasksStore
      .getTask(this.taskId)
      .pipe(
        map((task) => task.value),
        tap((task: Task) => {
          // Remove removed followers
          const removedFollowerIds = task.followers
            .filter(
              (follower) => !model.followerIds.find((el) => el === follower.id)
            )
            .map((follower) => follower.id);

          for (const followerId of removedFollowerIds) {
            this.root.tasksStore.removeFollower(this.taskId, followerId);
          }

          // Add added followers
          const addedFollowerIds = model.followerIds.filter(
            (followerId) => !task.followers?.find((el) => el.id === followerId)
          );

          for (const followerId of addedFollowerIds) {
            this.root.tasksStore.addFollower(this.taskId, followerId);
          }

          // // Delete removed files
          // const deletedFileIds = task.files
          //   .filter(
          //     (file) => !model.files.find((el) => el.name === file.fileTitle)
          //   )
          //   .map((val) => val.id);
          //
          // for (const fileId of deletedFileIds) {
          //   this.root.tasksStore.deleteFile(fileId);
          // }

          // Add added files
          const newFiles = model.files.filter(
            (file) => !task.files?.find((el) => el.fileTitle === file.name)
          );

          for (const file of newFiles) {
            this.root.tasksStore.addFileToTask(this.taskId, file);
          }
        }),
        first()
      )
      .subscribe();

    let taskInput: TaskInput = [l.omit(model, ['files'])].map((el) => ({
      ...el,
      dueDate: el.dueDate.toISOString(),
    }))[0];

    taskInput = this.root.tasksService.sanitizeTaskInput(taskInput);
    taskInput = l.omit(taskInput, ['followerIds']) as TaskInput;

    this.root.tasksStore.updateTask(this.taskId, taskInput);
  }

  handleAddComment(commentText: string): void {
    if (commentText) {
      this.root.tasksStore
        .createComment({
          commentTitle: '',
          comment: commentText,
          taskId: this.taskId,
        })
        .then(() => (this.commentInput.nativeElement.value = ''));
    }
  }

  async handleFileDownload(commentFile: any): Promise<void> {
    let blob: Blob;
    if ((commentFile as File).size) {
      // It's a file
      blob = commentFile as File;
    } else {
      const t = await this.root.localizationStore.selectedLanguage();
      // It's not a file - Download it from backend first
      const base64 = (
        (await this.root.graphqlService
          .sendRequest<GraphqlFile>({
            field: 'file',
            query: FileQuery,
            variables: { fileId: (commentFile as { id: string }).id },
            loadingMessage: t.loading_get_file,
          })
          .pipe(first())
          .toPromise()) as GraphqlFile
      ).file;

      blob = this.dataURItoBlob(base64);
    }

    saveAs(blob, commentFile.fileTitle);
  }

  async handleFakeSave(): Promise<void> {
    const t = await this.root.localizationStore.selectedLanguage();

    this.messageService.add({
      severity: 'success',
      summary: t.save,
    });
  }
}
