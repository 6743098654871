<div xmlns="http://www.w3.org/1999/html">
  <p-tabMenu
    [model]="filterMenuItems"
    [activeItem]="settingsMenuActivatedItem"
  ></p-tabMenu>

  <p-table
    styleClass="p-datatable-striped"
    [value]="languageRows$ | async"
    paginator="true"
    [rows]="10"
    dataKey="key"
    editMode="row"
    *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
  >
    <ng-template pTemplate="header">
      <tr>
        <th>{{ selectedLanguage.system_language_name }}</th>
        <th>{{ selectedLanguage.translation }}</th>
        <th></th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-languageRow let-editing="editing">
      <tr [pEditableRow]="languageRow">
        <td>{{ languageRow.eng }}</td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <textarea
                [rows]="1"
                [cols]="70"
                pInputTextarea
                autoResize="autoResize"
                [(ngModel)]="languageRow.value"
                required
              ></textarea>
            </ng-template>
            <ng-template pTemplate="output">
              {{ languageRow.value }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td style="text-align: center">
          <button
            *ngIf="!editing"
            pButton
            pRipple
            type="button"
            pInitEditableRow
            icon="pi pi-pencil"
            class="p-button-rounded p-button-text"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pSaveEditableRow
            icon="pi pi-check"
            (click)="handleRowEditSave(languageRow.key, languageRow.value)"
            class="p-button-rounded p-button-text p-button-success p-mr-2"
          ></button>
          <button
            *ngIf="editing"
            pButton
            pRipple
            type="button"
            pCancelEditableRow
            icon="pi pi-times"
            class="p-button-rounded p-button-text p-button-danger"
          ></button>
        </td></tr
    ></ng-template>
  </p-table>

  <!--  <p-table-->
  <!--    [value]="root.localizationStore.languageRows(languageId)"-->
  <!--    dataKey="key"-->
  <!--    editMode="row"-->
  <!--  >-->
  <!--    <ng-template pTemplate="header">-->
  <!--      <tr>-->
  <!--        <th>English</th>-->
  <!--        <th>Translation</th>-->
  <!--      </tr>-->
  <!--    </ng-template>-->
  <!--    <ng-template-->
  <!--      pTemplate="body"-->
  <!--      let-languageRow-->
  <!--      let-editing="editing"-->
  <!--      let-ri="rowIndex"-->
  <!--    >-->
  <!--      <tr [pEditableRow]="languageRow">-->
  <!--        <td>{{ languageRow.eng }}</td>-->
  <!--        <td>-->
  <!--          <p-cellEditor>-->
  <!--            <ng-template pTemplate="input">-->
  <!--              <input-->
  <!--                pInputText-->
  <!--                type="text"-->
  <!--                [defaultValue]="languageRow.value"-->
  <!--              />-->
  <!--            </ng-template>-->
  <!--            <ng-template pTemplate="output">-->
  <!--              {{ languageRow.value }}-->
  <!--            </ng-template>-->
  <!--          </p-cellEditor>-->
  <!--        </td>-->
  <!--        <td style="text-align: center">-->
  <!--          <button-->
  <!--            *ngIf="!editing"-->
  <!--            pButton-->
  <!--            pRipple-->
  <!--            type="button"-->
  <!--            pInitEditableRow-->
  <!--            icon="pi pi-pencil"-->
  <!--            class="p-button-rounded p-button-text"-->
  <!--          ></button>-->
  <!--          <button-->
  <!--            *ngIf="editing"-->
  <!--            pButton-->
  <!--            pRipple-->
  <!--            type="button"-->
  <!--            pSaveEditableRow-->
  <!--            icon="pi pi-check"-->
  <!--            class="p-button-rounded p-button-text p-button-success p-mr-2"-->
  <!--          ></button>-->
  <!--          <button-->
  <!--            *ngIf="editing"-->
  <!--            pButton-->
  <!--            pRipple-->
  <!--            type="button"-->
  <!--            pCancelEditableRow-->
  <!--            icon="pi pi-times"-->
  <!--            class="p-button-rounded p-button-text p-button-danger"-->
  <!--          ></button>-->
  <!--        </td>-->
  <!--      </tr>-->
  <!--    </ng-template>-->
  <!--  </p-table>-->
</div>
