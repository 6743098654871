import * as l from 'lodash';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Task, TaskInput, User } from '../../types/graphql';
import { DisplayFilter } from '../../types/types/enums';
import { FilterArgs } from '../../types/types/interfaces';
import { TaskStore } from '../stores/task.store';

import { DateService } from './date.service';
import { HelperService } from './helper.service';

/**
 * @description This service is for pure functions only
 */
export class TasksService {
  private helperService: HelperService;
  private dateService: DateService;

  construct(helperService: HelperService, dateService: DateService): void {
    this.helperService = helperService;
    this.dateService = dateService;
  }

  textsFromTask({
    category,
    title,
    description,
    assignedUser,
    assignedCompany: { companyName },
  }: Task): string[] {
    return [
      category?.toUpperCase() || '',
      title || '',
      description || '',
      assignedUser?.firstName || '' + ' ' + assignedUser?.lastName || '',
      companyName || '',
    ];
  }

  filterTasksByText(
    tasks: Task[],
    text: string,
    shortenText = false
  ): { taskId: string; matchingTexts: string[] }[] {
    return (
      tasks
        .map((task) => ({
          taskId: task.id,
          matchingTexts: (() => {
            let result = this.textsFromTask(task).filter((el) =>
              this.helperService.defensiveIncludes(el, text)
            );

            if (shortenText) {
              result = result.map((el) =>
                l.truncate(
                  el.slice(this.helperService.defensiveIndexOf(el, text))
                )
              );
            }

            return result;
          })(),
        }))
        // Filter to tasks which have including text
        .filter((task) => task.matchingTexts.length > 0)
    );
  }

  filterTasks(
    tasks: TaskStore[],
    { taskStatus, displayFilter, text }: FilterArgs,
    myself: User
  ): TaskStore[] {
    let result = l.cloneDeep(tasks);

    if (taskStatus) {
      result = result.filter((task) => task.value.status === taskStatus);
    }

    if (displayFilter) {
      switch (displayFilter) {
        case DisplayFilter.AssignedToMe: {
          result = result.filter(
            (task) => task.value.assignedUser?.id === myself.id
          );
          break;
        }
        case DisplayFilter.Following: {
          result = result.filter((task) =>
            task.value.followers.find((follower) => follower.id === myself.id)
          );
          break;
        }
        default: {
          break;
        }
      }
    }

    if (text) {
      result = this.filterTasksByText(
        tasks.map((task) => task.value),
        text
      )
        .map(({ taskId }) => result.find((task) => task.value.id === taskId))
        .filter((val) => val);
    }

    return result;
  }

  sortTasksByDueDate(tasks: TaskStore[]): TaskStore[] {
    return tasks.sort((a, b) => (a.value.dueDate < b.value.dueDate ? -1 : 1));
  }

  sanitizeTaskInput(taskInput: TaskInput): TaskInput {
    return l.omitBy(
      {
        ...taskInput,
        dueDate: taskInput.dueDate
          ? this.dateService.sqlDateFromDate(new Date(taskInput.dueDate))
          : null,
      },
      l.isNil
    ) as TaskInput;
  }

  taskInputFromTask(task: Task): TaskInput {
    const result = task as unknown as TaskInput;

    if (task.assignedUser) {
      result.assignedUserId = task.assignedUser.id;
    }

    if (task.assignedLocation) {
      result.assignedLocationId = task.assignedLocation.id;
    }

    return result;
  }

  tasksByArchiveState(
    tasks$: Observable<TaskStore[]>,
    shouldBeArchived: boolean
  ): Observable<TaskStore[]> {
    return tasks$.pipe(
      map((tasks) =>
        tasks.filter((task) => {
          let result = false;
          if (shouldBeArchived && task.value.archivedAt) {
            result = true;
          }
          if (!shouldBeArchived && !task.value.archivedAt) {
            result = true;
          }
          return result;
        })
      )
    );
  }
}
