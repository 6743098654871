import * as l from 'lodash';

import { Company } from '../../types/graphql';

/**
 * @description This class has to be instantiated by an instance of the CompaniesStore ("parent")!
 */
export class CompanyStore {
  private backup: Company;
  constructor(public value: Company) {
    this.backup = l.cloneDeep(value);
  }

  reset(): void {
    this.value = l.cloneDeep(this.backup);
  }
}
