<div
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <h1 class="p-ml-2 p-mb-3">{{ selectedLanguage.system_languages }}</h1>

  <ng-container
    *ngIf="root?.localizationStore?.languages$ | async as languages"
  >
    <p-table [value]="languages" styleClass="p-datatable-striped">
      <ng-template pTemplate="header">
        <tr>
          <th>{{ selectedLanguage.language }}</th>
          <th>{{ selectedLanguage.abbreviation }}</th>
          <th>{{ selectedLanguage.translation_status }}</th>
          <th>{{ selectedLanguage.actions }}</th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-language>
        <tr>
          <td>{{ language.language_name }}</td>
          <td>{{ language.language_code | uppercase }}</td>
          <td>
            {{
              root.localizationStore.translationPercentage(language.id) | async
            }}%
          </td>
          <td>
            <button
              pButton
              icon="pi pi-pencil"
              type="button"
              class="p-button-rounded p-button-secondary p-button-text"
              (click)="navigateToLanguage(language.id)"
            ></button>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </ng-container>
</div>
