<div *ngIf="!!fields.length">
  <form [formGroup]="form" (ngSubmit)="handleSubmit(model)">
    <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
    <button
      *ngIf="isSaveable && !!fields.length"
      pButton
      type="submit"
      label="Save"
      [disabled]="!form.valid"
      style="width: 25%"
      class="p-mt-5"
    ></button>
  </form>
</div>
