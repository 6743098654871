import { Component, OnInit } from '@angular/core';

import { RootStore } from '../../store/root.store';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  constructor(public root: RootStore) {}

  ngOnInit(): void {
    this.root.authService.login();
  }
}
