<ng-container
  *ngIf="
    (root.localizationStore.selectedLanguage$ | async) && (company$ | async);
    else skeleton
  "
>
  <div
    class="p-grid"
    *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
  >
    <div class="p-col-6">
      <h2 class="p-mb-6">{{ selectedLanguage.company }}</h2>
      <div class="p-fluid">
        <app-company-form
          *ngIf="company$ | async as company"
          [company]="company"
          (submitForm)="handleSubmit($event)"
        ></app-company-form>
      </div>
    </div>
    <div class="p-col-5"></div>
  </div>
</ng-container>
<ng-template #skeleton>
  <div
    class="p-grid"
    *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
  >
    <div class="p-col-6">
      <h2 class="p-mb-6">{{ selectedLanguage.company }}</h2>
      <div class="p-fluid">
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>

        <p-skeleton styleClass="p-mb-3 p-mt-6" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>

        <p-skeleton styleClass="p-mb-3 p-mt-6" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
      </div>
    </div>
    <div class="p-col-5"></div>
  </div>
</ng-template>
