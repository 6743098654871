import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { UserRole } from '../../types/graphql';
import { IUser } from '../../types/types/interfaces/user.interface';
import { UsersService } from '../services/users.service';
import { UsersStore } from '../stores/users.store';

/**
 * @description The UsersStore takes care of fetching users
 */
export class UsersViewStore {
  textQuery$ = new BehaviorSubject<string>('');
  private usersStore: UsersStore;
  private usersService: UsersService;

  construct(usersStore: UsersStore, usersService: UsersService): void {
    this.usersStore = usersStore;
    this.usersService = usersService;
  }

  filteredUsersWithRole(roles: UserRole[]): Observable<IUser[]> {
    return combineLatest([this.usersStore.users$, this.textQuery$]).pipe(
      map(([users, text]) =>
        users
          .filter((user) => roles.find((role) => user.value.role === role))
          .map((user) => this.usersService.IUserFromUser(user.value))
          .filter((user) =>
            [
              user.lastName,
              user.firstName,
              user.email,
              user.assignedLocation?.assignedCompany?.companyName,
              user.country,
            ].find((fieldValue) =>
              fieldValue?.toLowerCase().includes(text.toLowerCase())
            )
          )
      )
    );
  }
}
