import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { first } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import { Language, User } from '../../types/graphql';
import { UserDomain } from '../../types/types/enums';

@Component({
  selector: 'app-scaffolding',
  templateUrl: './scaffolding.component.html',
  styleUrls: ['./scaffolding.component.scss'],
})
export class ScaffoldingComponent implements OnInit {
  readonly userDomain = UserDomain;

  showOpen = true;
  readonly currentDate = new Date();
  itemsTop: MenuItem[];
  myself: User;
  fullPath: string;
  l: Language;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    public root: RootStore
  ) {}

  async ngOnInit(): Promise<void> {
    this.itemsTop = [
      {
        items: [
          {
            label: (await this.root.localizationStore.selectedLanguage())
              .edit_user,
            icon: 'pi pi-user-edit',
            routerLink: '/profile',
          },
          {
            separator: true,
          },
          {
            label: (await this.root.localizationStore.selectedLanguage())
              .logout,
            icon: 'pi pi-sign-out',
            command: () => this.root.authService.logout(),
          },
        ],
      },
    ];

    this.myself = await this.root.authService.myself$.pipe(first()).toPromise();
  }

  toggleSidebar(): void {
    this.showOpen = !this.showOpen;
  }
}
