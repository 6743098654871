import { Router } from '@angular/router';
import { Auth0Lock } from 'auth0-lock';
import moment from 'moment';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, switchMap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { MyselfQuery } from '../../graphql/queries/myself.query';
import { User } from '../../types/graphql';
import { LocalStorageKey } from '../../types/types/enums';
import { IAuth0Profile, IPermission } from '../../types/types/interfaces';
import { IUser } from '../../types/types/interfaces/user.interface';

import { GraphqlService } from './graphql.service';
import { HelperService } from './helper.service';
import { UsersService } from './users.service';

export class AuthService {
  profile$: Observable<IAuth0Profile>;
  myself$: Observable<IUser>;
  lang = navigator.languages;
  lock = new Auth0Lock(environment.auth0.clientId, environment.auth0.domain, {
    auth: {
      responseType: 'token',
      audience: environment.auth0.audience,
      redirect: true,
      redirectUrl: environment.auth0.redirectUrl,
    },
    container: 'auth0',
    language: this.lang[1], // use index 1 for the right formatted language code ( 0: de_DE, 1: de)
    languageDictionary: {
      title: 'Login',
    },
    theme: {
      logo: '/assets/img/Forstsys_Logo.svg',
      primaryColor: '#4d927d',
    },
    allowSignUp: false,
  });

  private profileSubject = new BehaviorSubject<IAuth0Profile>(null);

  constructor(
    private router: Router,
    private usersService: UsersService,
    private helperService: HelperService
  ) {}

  private getProfile(): IAuth0Profile {
    let result: IAuth0Profile;

    try {
      result = JSON.parse(localStorage.getItem(LocalStorageKey.Profile));
    } catch {}

    return result;
  }

  private logoutAfterTimeout(): void {
    const msTillLogout = this.getExpirationDate() - (moment.now() - 2000);

    setTimeout(() => {
      this.logout();
    }, msTillLogout);
  }

  construct(graphqlService: GraphqlService): void {
    this.profile$ = this.profileSubject.asObservable();

    this.myself$ = this.profile$.pipe(
      filter((val) => !!val),
      switchMap(() =>
        graphqlService.sendRequest<User>({
          field: 'myself',
          query: MyselfQuery,
          loadingMessage: '',
        })
      ),
      map((user) => this.usersService.IUserFromUser(user))
    );

    this.lock.on('authenticated', (authResult: AuthResult) => {
      localStorage.setItem(LocalStorageKey.AccessToken, authResult.accessToken);
      localStorage.setItem(
        LocalStorageKey.ExpirationDate,
        String(moment.now() + authResult.expiresIn * 1000)
      );

      this.logoutAfterTimeout();

      this.lock.getProfile(authResult.accessToken, (err, profile: any) => {
        localStorage.setItem(LocalStorageKey.Profile, JSON.stringify(profile));
        this.profileSubject.next(profile as IAuth0Profile);

        this.lock.hide();

        this.router.navigateByUrl('/tasks');
      });
    });

    this.profileSubject.next(this.getProfile());

    if (this.getExpirationDate()) {
      this.logoutAfterTimeout();
    }
  }

  async hasPermission(
    permission: Partial<IPermission>,
    user?: IUser
  ): Promise<boolean> {
    let result = false;

    const hasOverlap = this.helperService.hasOverlap;

    const usr = user || (await this.myself$.pipe(first()).toPromise());

    if (
      !(
        hasOverlap(usr.cannot.do, permission.do) ||
        hasOverlap(usr.cannot.create, permission.create) ||
        hasOverlap(usr.cannot.read, permission.read) ||
        hasOverlap(usr.cannot.update, permission.update) ||
        hasOverlap(usr.cannot.delete, permission.delete)
      )
    ) {
      result = true;
    }

    return result;
  }

  /**
   * @description This isn't a getter just as a reminder that this isn't an observable
   */
  getExpirationDate(): number {
    let result = 0;

    try {
      const item = parseInt(
        localStorage.getItem(LocalStorageKey.ExpirationDate),
        10
      );

      if (item) {
        result = Math.round(item);
      }
    } catch {}

    return result;
  }

  /**
   * @description This isn't a getter just as a reminder that this isn't an observable
   */
  getAccessToken(): string {
    let result = '';

    try {
      result = localStorage.getItem(LocalStorageKey.AccessToken);
    } catch {}

    return result;
  }

  login(): void {
    this.lock.show();
  }

  logout(): void {
    localStorage.removeItem(LocalStorageKey.AccessToken);
    localStorage.removeItem(LocalStorageKey.Profile);
    localStorage.removeItem(LocalStorageKey.ExpirationDate);

    this.profileSubject.next(null);

    this.router.navigateByUrl('/login');
  }
}
