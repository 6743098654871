
    <div class="p-grid p-field" [ngClass]="to.wrapperClasses">
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col p-pb-0">
        <input
          [disabled]="to.disabled"
          (input)="handleTextChange()"
          *ngIf="to.type !== 'number'; else numberTmp"
          pInputText
          [ngModel]="to.defaultValue"
          [type]="to.type || 'text'"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
        />
        <ng-template #numberTmp>
          <input
            [disabled]="to.disabled"
            (input)="handleTextChange()"
            type="number"
            pInputText
            [formControl]="$any(formControl)"
            [formlyAttributes]="field"
          />
        </ng-template>
        <div class="p-mt-1" style="font-size: 12px; color: red">
          {{ error }}
        </div>
      </div>
    </div>
  