import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ScaffoldingComponent } from './components/scaffolding/scaffolding.component';
import { AuthGuard } from './guards/auth.guard';
import { LoginGuard } from './guards/login.guard';
import { DoesntExistComponent } from './pages/doesnt-exist/doesnt-exist.component';
import { LoginComponent } from './pages/login/login.component';
import { MyProfileComponent } from './pages/my-profile/my-profile.component';
import { settingsRoutes, taskRoutes } from './routes';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [LoginGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/tasks',
  },
  {
    path: '',
    component: ScaffoldingComponent,
    children: [
      ...taskRoutes,
      ...settingsRoutes,
      {
        path: 'profile',
        component: MyProfileComponent,
      },
    ],
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: DoesntExistComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
