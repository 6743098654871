<div
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <div class="p-grid">
    <div *ngIf="mode === modeEnum.UnarchivedOnly" class="p-col-2">
      <p-button
        label="+ {{ selectedLanguage.task }}"
        [style]="{ width: '100%' }"
        routerLink="create"
      ></p-button>
    </div>
    <div class="p-col-9">
      <input type="text" pInputText [(ngModel)]="handleSearch" />
    </div>
    <div *ngIf="mode === modeEnum.UnarchivedOnly" class="p-col-1 p-text-right">
      <p-selectButton
        [options]="viewsItems"
        [(ngModel)]="selectedViewsItem"
        (click)="handleViewModeClick(selectedViewsItem)"
        optionLabel="Tasks-View"
      >
      </p-selectButton>
    </div>
  </div>

  <ng-container *ngIf="selectedViewsItem?.value === 'tile'; else table">
    <p-accordion
      [multiple]="true"
      styleClass="status-accordion"
      *ngIf="root.tasksViewStore.taskStatusSubject | async as status"
    >
      <p-accordionTab
        [header]="selectedLanguage.open_tasks"
        [selected]="status === taskStatus.Open"
      >
        <div class="tasks-wrapper">
          <app-task-card
            *ngFor="let task of openTasks$ | async"
            [taskStore]="task"
          ></app-task-card>
        </div>
      </p-accordionTab>
      <p-accordionTab
        [header]="selectedLanguage.planning"
        [selected]="status === taskStatus.Planning"
      >
        <div class="tasks-wrapper">
          <app-task-card
            *ngFor="let task of planningTasks$ | async"
            [taskStore]="task"
          ></app-task-card>
        </div>
      </p-accordionTab>
      <p-accordionTab
        [header]="selectedLanguage.in_progress"
        [selected]="status === taskStatus.InProgress"
      >
        <div class="tasks-wrapper">
          <app-task-card
            *ngFor="let task of inProgressTasks$ | async"
            [taskStore]="task"
          ></app-task-card>
        </div>
      </p-accordionTab>
      <p-accordionTab
        [header]="selectedLanguage.waiting_for_feedback"
        [selected]="status === taskStatus.WaitingForFeedback"
      >
        <div class="tasks-wrapper">
          <app-task-card
            *ngFor="let task of waitingForFeedbackTasks$ | async"
            [taskStore]="task"
          ></app-task-card>
        </div>
      </p-accordionTab>
      <p-accordionTab
        [header]="selectedLanguage.waiting_for_confirmation"
        [selected]="status === taskStatus.WaitingForConfirmation"
      >
        <div class="tasks-wrapper">
          <app-task-card
            *ngFor="let task of waitingForConfirmationTasks$ | async"
            [taskStore]="task"
          ></app-task-card>
        </div>
      </p-accordionTab>
      <p-accordionTab
        [header]="selectedLanguage.on_hold"
        [selected]="status === taskStatus.OnHold"
      >
        <div class="tasks-wrapper">
          <app-task-card
            *ngFor="let task of onHoldTasks$ | async"
            [taskStore]="task"
          ></app-task-card>
        </div>
      </p-accordionTab> </p-accordion
  ></ng-container>
  <ng-template #table>
    <ng-container *ngIf="!!columns?.length">
      <app-primeng-table
        *ngIf="mode === modeEnum.UnarchivedOnly; else archivedTable"
        [columns]="columns"
        [rows]="tasks$ | async"
        [paginator]="true"
        [rowCount]="20"
        [viewFn]="viewTask.bind(this)"
      ></app-primeng-table>
      <ng-template #archivedTable>
        <app-primeng-table
          [columns]="columns"
          [rows]="tasks$ | async"
          [unarchiveFn]="unarchiveTask.bind(this)"
          [viewFn]="viewTask.bind(this)"
          [paginator]="true"
          [rowCount]="20"
        ></app-primeng-table>
      </ng-template>
    </ng-container>
  </ng-template>
</div>
