<div>
  <div class="p-grid">
    <div class="p-col-6">
      <div class="p-fluid">
        <app-task-form (submitForm)="handleSubmit($event)"></app-task-form>
      </div>
    </div>
    <p-divider layout="vertical"></p-divider>
    <div class="p-col-5 p-d-inline-flex p-ai-center">
      <svg
        version="1.1"
        id="Ebene_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        viewBox="0 0 287 50"
        style="enable-background: new 0 0 287 50"
        xml:space="preserve"
      >
        <style type="text/css">
          .st0 {
            enable-background: new;
          }
          .st1 {
            fill: #e9e9e9;
          }
        </style>
        <g class="st0">
          <path
            class="st1"
            d="M12.8,47.2H4V4.8h24.3v7.4H12.8v10.9h14.4v7.3H12.8V47.2z"
          />
          <path
            class="st1"
            d="M75.1,26c0,7-1.7,12.4-5.2,16.2c-3.5,3.8-8.5,5.7-15,5.7s-11.5-1.9-15-5.7c-3.5-3.8-5.2-9.2-5.2-16.2
		s1.7-12.4,5.2-16.2c3.5-3.7,8.5-5.6,15-5.6c6.5,0,11.5,1.9,14.9,5.6S75.1,18.9,75.1,26z M44.1,26c0,4.7,0.9,8.3,2.7,10.7
		c1.8,2.4,4.5,3.6,8.1,3.6c7.2,0,10.8-4.8,10.8-14.3c0-9.6-3.6-14.3-10.7-14.3c-3.6,0-6.3,1.2-8.1,3.6C45,17.7,44.1,21.2,44.1,26z"
          />
          <path
            class="st1"
            d="M92.8,30.9v16.3h-9V4.8h12.4c5.8,0,10,1,12.8,3.1c2.8,2.1,4.1,5.3,4.1,9.6c0,2.5-0.7,4.7-2.1,6.7
		c-1.4,1.9-3.3,3.5-5.8,4.6c6.4,9.5,10.5,15.7,12.5,18.5h-10L97.6,30.9H92.8z M92.8,23.6h2.9c2.8,0,4.9-0.5,6.3-1.4
		c1.4-0.9,2-2.4,2-4.5c0-2-0.7-3.4-2.1-4.3c-1.4-0.9-3.5-1.3-6.4-1.3h-2.7V23.6z"
          />
          <path
            class="st1"
            d="M148.1,35.4c0,3.8-1.4,6.8-4.1,9c-2.8,2.2-6.6,3.3-11.5,3.3c-4.5,0-8.5-0.9-12-2.6v-8.4
		c2.9,1.3,5.3,2.2,7.3,2.7c2,0.5,3.8,0.8,5.4,0.8c2,0,3.5-0.4,4.5-1.1c1.1-0.8,1.6-1.9,1.6-3.4c0-0.8-0.2-1.6-0.7-2.2
		c-0.5-0.6-1.1-1.3-2-1.9c-0.9-0.6-2.7-1.6-5.5-2.9c-2.6-1.2-4.5-2.4-5.8-3.5c-1.3-1.1-2.3-2.4-3.1-3.9c-0.8-1.5-1.2-3.2-1.2-5.2
		c0-3.8,1.3-6.7,3.8-8.8c2.5-2.1,6.1-3.2,10.5-3.2c2.2,0,4.3,0.3,6.3,0.8c2,0.5,4.1,1.3,6.3,2.2l-2.9,7c-2.3-0.9-4.1-1.6-5.6-1.9
		c-1.5-0.4-2.9-0.6-4.4-0.6c-1.7,0-3,0.4-3.9,1.2c-0.9,0.8-1.4,1.8-1.4,3.1c0,0.8,0.2,1.5,0.6,2.1c0.4,0.6,1,1.2,1.8,1.7
		c0.8,0.6,2.7,1.5,5.7,3c4,1.9,6.7,3.8,8.1,5.7C147.3,30.3,148.1,32.7,148.1,35.4z"
          />
          <path
            class="st1"
            d="M172.1,47.2h-9V12.3h-11.5V4.8h32v7.5h-11.5V47.2z"
          />
          <path
            class="st1"
            d="M215.2,35.4c0,3.8-1.4,6.8-4.1,9c-2.8,2.2-6.6,3.3-11.5,3.3c-4.5,0-8.5-0.9-12-2.6v-8.4
		c2.9,1.3,5.3,2.2,7.3,2.7c2,0.5,3.8,0.8,5.4,0.8c2,0,3.5-0.4,4.5-1.1c1.1-0.8,1.6-1.9,1.6-3.4c0-0.8-0.2-1.6-0.7-2.2
		c-0.5-0.6-1.1-1.3-2-1.9c-0.9-0.6-2.7-1.6-5.5-2.9c-2.6-1.2-4.5-2.4-5.8-3.5c-1.3-1.1-2.3-2.4-3.1-3.9c-0.8-1.5-1.2-3.2-1.2-5.2
		c0-3.8,1.3-6.7,3.8-8.8c2.5-2.1,6.1-3.2,10.5-3.2c2.2,0,4.3,0.3,6.3,0.8c2,0.5,4.1,1.3,6.3,2.2l-2.9,7c-2.3-0.9-4.1-1.6-5.6-1.9
		c-1.5-0.4-2.9-0.6-4.4-0.6c-1.7,0-3,0.4-3.9,1.2s-1.4,1.8-1.4,3.1c0,0.8,0.2,1.5,0.6,2.1c0.4,0.6,1,1.2,1.8,1.7
		c0.8,0.6,2.7,1.5,5.7,3c4,1.9,6.7,3.8,8.1,5.7C214.4,30.3,215.2,32.7,215.2,35.4z"
          />
          <path
            class="st1"
            d="M236.1,22.3l8.8-17.5h9.7l-14.1,25.9v16.5h-8.9V31L217.5,4.8h9.7L236.1,22.3z"
          />
          <path
            class="st1"
            d="M284.9,35.4c0,3.8-1.4,6.8-4.1,9c-2.8,2.2-6.6,3.3-11.5,3.3c-4.5,0-8.5-0.9-12-2.6v-8.4
		c2.9,1.3,5.3,2.2,7.3,2.7c2,0.5,3.8,0.8,5.4,0.8c2,0,3.5-0.4,4.5-1.1c1.1-0.8,1.6-1.9,1.6-3.4c0-0.8-0.2-1.6-0.7-2.2
		c-0.5-0.6-1.1-1.3-2-1.9c-0.9-0.6-2.7-1.6-5.5-2.9c-2.6-1.2-4.5-2.4-5.8-3.5c-1.3-1.1-2.3-2.4-3.1-3.9c-0.8-1.5-1.2-3.2-1.2-5.2
		c0-3.8,1.3-6.7,3.8-8.8c2.5-2.1,6.1-3.2,10.5-3.2c2.2,0,4.3,0.3,6.3,0.8c2,0.5,4.1,1.3,6.3,2.2l-2.9,7c-2.3-0.9-4.1-1.6-5.6-1.9
		s-2.9-0.6-4.4-0.6c-1.7,0-3,0.4-3.9,1.2c-0.9,0.8-1.4,1.8-1.4,3.1c0,0.8,0.2,1.5,0.6,2.1c0.4,0.6,1,1.2,1.8,1.7
		c0.8,0.6,2.7,1.5,5.7,3c4,1.9,6.7,3.8,8.1,5.7C284.2,30.3,284.9,32.7,284.9,35.4z"
          />
        </g>
      </svg>
    </div>
  </div>
</div>
