import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as l from 'lodash';
import { first } from 'rxjs/operators';

import { IModel } from '../../form-components/company-form/company-form.component';
import { RootStore } from '../../store/root.store';

enum LocationField {
  Name = 'name',
  Country = 'country',
  IsActive = 'isActive',
  Id = 'id',
  City = 'city',
}

interface ISimpleLocation {
  id: string;
  name: string;
  country: string;
  isActive: boolean;
  city: string;
}

@Component({
  selector: 'app-create-company',
  templateUrl: './create-company.component.html',
  styleUrls: ['./create-company.component.scss'],
})
export class CreateCompanyComponent implements OnInit {
  constructor(public root: RootStore, private router: Router) {}

  private locationsFromLocationTuples(
    locationTuples: [LocationField, string | boolean][]
  ): ISimpleLocation[] {
    const result: ISimpleLocation[] = [];

    let foundSomething = true;
    let i = -1;

    while (foundSomething) {
      i++;

      const objEntries = locationTuples.filter(
        ([k, _]) => parseInt(k.split('_')[1], 10) === i
      );

      if (!!objEntries.length) {
        const obj: any = {};

        objEntries.forEach(([k, v]) => {
          let key = k.toLowerCase();

          if (key.includes(LocationField.IsActive.toLowerCase())) {
            key = LocationField.IsActive;
          } else if (key.includes(LocationField.Country.toLowerCase())) {
            key = LocationField.Country;
          } else if (key.includes(LocationField.Name.toLowerCase())) {
            key = LocationField.Name;
          } else if (key.includes(LocationField.City.toLowerCase())) {
            key = LocationField.City;
          } else {
            key = LocationField.Id;
          }

          obj[key] = v;
        });

        result.push(obj);
      } else {
        foundSomething = false;
      }
    }

    return result;
  }

  ngOnInit(): void {}

  async handleSubmit(model: IModel): Promise<void> {
    const company = await (
      await this.root.companiesStore.createCompany({
        companyName: model.companyName,
      } as any)
    ) // TODO: Type safety
      .pipe(first())
      .toPromise();

    const locationTuples = Object.entries(model) as [[LocationField, string]];

    // Add new locations

    const locationTuplesToAdd = locationTuples.filter(([k, _]) =>
      k.includes('new')
    );

    const locationsToAdd: Partial<ISimpleLocation>[] =
      this.locationsFromLocationTuples(locationTuplesToAdd).map((location) =>
        l.omit(location, 'id')
      );

    for (const location of locationsToAdd) {
      await this.root.companiesStore.createLocation(
        location as any,
        company.value.id
      ); // TODO: Type safety
    }

    this.router.navigateByUrl('settings/companies/' + company.value.id);
  }
}
