import * as l from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { TaskStatus } from '../../types/graphql';
import { DisplayFilter, TasksSortParam } from '../../types/types/enums';
import { IDropdownOption } from '../../types/types/interfaces';
import { AuthService } from '../services/auth.service';
import { TasksService } from '../services/tasks.service';
import { CompaniesStore } from '../stores/companies.store';
import { TaskStore } from '../stores/task.store';
import { TasksStore } from '../stores/tasks.store';
import { UsersStore } from '../stores/users.store';

/**
 * @description The TasksStore takes care of fetching, editing and deleting of tasks
 */
export class TasksViewStore {
  displayFilterSubject = new BehaviorSubject<DisplayFilter>(
    DisplayFilter.AssignedToMe
  );
  searchQuerySubject = new BehaviorSubject<string>('');
  taskStatusSubject = new BehaviorSubject<TaskStatus>(TaskStatus.Open);
  tasksSortParamSubject = new BehaviorSubject(TasksSortParam.ByDueDate);

  usersOptions$: Observable<IDropdownOption[]>;
  locationOptions$: Observable<IDropdownOption[]>;

  displayedTasks$: Observable<TaskStore[]>;
  private usersStore: UsersStore;
  private tasksService: TasksService;
  private companiesStore: CompaniesStore;
  private tasksStore: TasksStore;
  private authService: AuthService;

  construct(
    usersStore: UsersStore,
    tasksService: TasksService,
    companiesStore: CompaniesStore,
    tasksStore: TasksStore,
    authService: AuthService
  ): void {
    this.usersStore = usersStore;
    this.tasksService = tasksService;
    this.companiesStore = companiesStore;
    this.tasksStore = tasksStore;
    this.authService = authService;
  }

  initialize(): void {
    this.usersOptions$ = this.usersStore.users$.pipe(
      map((users) =>
        users.map(({ value: { id, firstName, lastName } }) => ({
          label: `${firstName} ${lastName}`,
          value: id,
        }))
      )
    );

    this.locationOptions$ = this.companiesStore.locations$.pipe(
      map((locations) =>
        locations.map((location) => ({
          label:
            location.assignedCompany.companyName + ' (' + location.name + ')',
          value: location.id,
        }))
      )
    );

    this.displayedTasks$ = combineLatest([
      this.tasksStore.tasks$,
      this.displayFilterSubject,
      this.searchQuerySubject,
      this.tasksSortParamSubject,
      this.authService.myself$,
    ]).pipe(
      map(([tasks, displayFilter, searchQuery, tasksSortParam, myself]) => {
        return this.tasksService
          .filterTasks(
            tasks,
            {
              displayFilter,
              text: searchQuery,
            },
            myself
          )
          .sort((a, b) => {
            let result: number;

            if (tasksSortParam === TasksSortParam.ByDueDate) {
              result = +(a.value.dueDate > b.value.dueDate);
            } else if (tasksSortParam === TasksSortParam.ByLastEdited) {
              result = +(a.value.updatedAt < b.value.updatedAt);
            }

            return result || -1;
          });
      }),
      map((tasks) => l.cloneDeep(tasks)),
      shareReplay()
    );
  }
}
