import { Routes } from '@angular/router';

import { PendingChangesGuard } from '../guards/pending-changes.guard';
import { CompaniesSettingsComponent } from '../pages/companies-settings/companies-settings.component';
import { CompanyComponent } from '../pages/company/company.component';
import { CreateCompanyComponent } from '../pages/create-company/create-company.component';

export const companyRoutes: Routes = [
  {
    path: 'companies',
    component: CompaniesSettingsComponent,
  },
  {
    path: 'companies/create',
    component: CreateCompanyComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'companies/:id',
    component: CompanyComponent,
    canDeactivate: [PendingChangesGuard],
  },
];
