<form
  [formGroup]="form"
  (ngSubmit)="handleSubmit()"
  *ngIf="
    isReadyToRender && root.localizationStore.selectedLanguage$
      | async as selectedLanguage
  "
>
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  <div class="p-grid p-d-flex p-jc-end p-mt-4 p-mb-6">
    <div class="p-col-9">
      <p-button
        (click)="handleAddLocation($event)"
        label="+ {{ selectedLanguage.add_location }}"
        styleClass="p-button-link"
      ></p-button>
    </div>
  </div>
  <button
    pButton
    type="submit"
    label="{{ selectedLanguage.save }}"
    [disabled]="!form.valid || !!!locationFields.length"
    style="width: 50%"
    class="p-mt-6"
  ></button>
</form>
