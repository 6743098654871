<ng-container
  *ngIf="
    (root.graphqlService.queriesInProgress$ | async) &&
      (task$ | async) === false;
    else task
  "
>
  <div
    class="p-grid"
    *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
  >
    <div class="p-col-6">
      <h2 class="p-mb-6">{{ selectedLanguage.task_details }}</h2>
      <div class="p-fluid">
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="250px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
      </div>
    </div>
    <p-divider layout="vertical"></p-divider>
    <div class="p-col-5">
      <h2 class="p-mb-6">{{ selectedLanguage.task_comments }}</h2>
      <p-skeleton styleClass="p-mb-3" height="150px"></p-skeleton>
      <p-skeleton styleClass="p-mb-3" height="150px"></p-skeleton>
      <p-skeleton styleClass="p-mb-3" height="150px"></p-skeleton>
    </div>
  </div>
</ng-container>

<ng-template #task>
  <div *ngIf="task$ | async as task">
    <div
      class="p-grid"
      *ngIf="
        root.localizationStore.selectedLanguage$ | async as selectedLanguage
      "
    >
      <div class="p-col-6">
        <h2 class="p-mb-6">{{ selectedLanguage.task_details }}</h2>
        <div class="p-fluid">
          <app-task-form
            [task]="task"
            [hasSubmit]="false"
            (submitForm)="handleSubmit($event)"
          ></app-task-form>
          <div class="p-grid">
            <div class="p-col-3"></div>
            <div class="p-col-5">
              <ng-container *ngFor="let file of task.files; let i = index">
                <p-button
                  (click)="handleFileDownload(file)"
                  [label]="file.fileDisplayTitle"
                  styleClass="p-button-link"
                ></p-button>
              </ng-container>
            </div>
          </div>
          <div class="p-grid p-mt-6">
            <div class="p-col-6">
              <button
                (click)="handleFakeSave()"
                pButton
                type="button"
                [label]="selectedLanguage.save"
              ></button>
            </div>
          </div>
        </div>
      </div>
      <p-divider layout="vertical"></p-divider>
      <div class="p-col-5">
        <h2 class="p-mb-6">{{ selectedLanguage.task_comments }}</h2>
        <div
          #taskComments
          class="comments-section"
          [scrollTop]="taskComments.offsetHeight"
        >
          <p-card class="p-mb-5" *ngFor="let comment of task.comments">
            <div class="comment-heading">
              <h3 class="comment-username">
                <p-avatar
                  *ngIf="
                    comment?.user?.profileImage?.profileImagePath;
                    else avataricon
                  "
                  [image]="comment.user.profileImage.profileImagePath"
                  styleClass="rounded-profile p-mr-3"
                  shape="circle"
                ></p-avatar>
                <ng-template #avataricon>
                  <button
                    pButton
                    type="button"
                    icon="pi pi-user"
                    class="p-button-rounded p-button-primary p-mr-3"
                  ></button>
                </ng-template>
                {{ comment.user?.firstName }} {{ comment.user?.lastName }}
                {{ comment.commentTitle }}
              </h3>
              <div class="comment-date p-text-light">
                {{ comment.createdAt | date: 'EE dd.MM.yyy HH:mm':'':'de' }}
              </div>
            </div>
            <div class="comment">
              {{ comment.comment }}
            </div>
            <div class="filename p-mt-4" *ngIf="comment.file">
              <p-button
                (click)="handleFileDownload(comment.file)"
                [label]="comment.file?.fileDisplayTitle"
                styleClass="p-button-link"
              ></p-button>
            </div>
          </p-card>
        </div>
        <ng-container *ngIf="!task.archivedAt">
          <div class="comments-input p-mt-6">
            <div class="p-grid p-fluid">
              <div class="p-col-12">
                <textarea
                  #commentInput
                  [rows]="5"
                  [cols]="30"
                  pInputTextarea
                  autoResize="autoResize"
                  placeholder="{{ selectedLanguage.enter_message }}"
                ></textarea>
                <!-- Closing textarea-tag needs to be in same line as the opening-->
              </div>
            </div>
            <div class="p-grid">
              <div class="p-col-12">
                <button
                  (click)="handleAddComment(commentInput.value)"
                  pButton
                  type="button"
                  label="{{ selectedLanguage.add_comment }}"
                ></button>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
