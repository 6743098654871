import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'app-formly-checkbox',
  styleUrls: ['./formly-checkbox.component.scss'],

  template: `
    <div class="p-grid p-field-checkbox" [ngClass]="to.wrapperClasses">
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col-1 p-pb-0">
        <p-checkbox
          [disabled]="to.disabled"
          [(ngModel)]="to.defaultValue"
          [binary]="true"
          (onChange)="handleChange(field, $event)"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
        ></p-checkbox>
      </div>
    </div>
  `,
})
export class FormlyCheckboxComponent extends FieldType {
  handleChange(field: any, event: any): void {
    if (this.to.change) {
      this.to.change(field, event);
    }

    if (this.to.handleChange) {
      this.to.handleChange.bind(this.to.self)();
    }
  }
}
