import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as moment from 'moment';

@Component({
  selector: 'app-formly-date-picker',
  styleUrls: ['./formly-date-picker.component.scss'],

  template: `
    <div class="p-grid p-field" [ngClass]="to.wrapperClasses">
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col-3 p-pb-0">
        <p-calendar
          [disabled]="to.disabled"
          [touchUI]="true"
          [showIcon]="true"
          (onFocus)="to.handleInput()"
          (onSelect)="handleChange(field, $event)"
          [(ngModel)]="to.defaultValue"
          [dateFormat]="to.dateFormat"
          [showTime]="to.showTime"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
        >
        </p-calendar>
      </div>
    </div>
  `,
})
export class FormlyDatePickerComponent extends FieldType {
  readonly moment = moment;

  handleChange(field: any, event: any): void {
    if (this.to.change) {
      this.to.change(field, event);
    }

    if (this.to.handleChange) {
      this.to.handleChange.bind(this.to.self)();
    }
  }
}
