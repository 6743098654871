import { gql } from 'apollo-angular';

export const CompanyQuery = gql`
  query Company($companyId: String!) {
    company(companyId: $companyId) {
      __typename
      id
      companyLogo
      companyName
      createdAt
      deletedAt
      updatedAt
      locations {
        id
        name
        isActive
        country
        city
      }
    }
  }
`;
