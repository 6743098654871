import * as l from 'lodash';

import { User, UserInput, UserRole } from '../../types/graphql';
import { UserAction, UserDomain } from '../../types/types/enums';
import { IUser } from '../../types/types/interfaces/user.interface';

import { HelperService } from './helper.service';

/**
 * @description This service is for pure functions only
 */
export class UsersService {
  constructor(private helperService: HelperService) {}

  private userFromIUser(user: IUser): User {
    return l.omit(user, ['fullName', 'cannot']);
  }

  IUserFromUser(user: User): IUser {
    let cannotArr;

    switch (user.role) {
      case UserRole.Admin:
        cannotArr = {
          do: [],
          create: [],
          read: [],
          update: [],
          delete: [],
        };
        break;
      case UserRole.Manager:
        cannotArr = {
          do: [],
          create: [],
          read: [],
          update: [],
          delete: [],
        };
        break;
      case UserRole.Employee:
        cannotArr = {
          do: [UserAction.FilterAll],
          create: [UserDomain.Settings],
          read: [UserDomain.Settings],
          update: [UserDomain.Settings],
          delete: [UserDomain.Settings],
        };
        break;
      case UserRole.Partner:
        cannotArr = {
          do: [UserAction.FilterAll, UserAction.SelectAllLocations],
          create: [UserDomain.Settings, UserDomain.Archive],
          read: [UserDomain.Settings, UserDomain.Archive],
          update: [UserDomain.Settings, UserDomain.Archive],
          delete: [UserDomain.Settings, UserDomain.Archive],
        };
        break;
      case UserRole.Service:
        cannotArr = {
          do: [UserAction.FilterAll, UserAction.SelectAllLocations],
          create: [UserDomain.Settings, UserDomain.Archive],
          read: [UserDomain.Settings, UserDomain.Archive],
          update: [UserDomain.Settings, UserDomain.Archive],
          delete: [UserDomain.Settings, UserDomain.Archive],
        };
        break;
      case UserRole.Workshop:
        cannotArr = {
          do: [UserAction.FilterAll, UserAction.SelectAllLocations],
          create: [UserDomain.Settings, UserDomain.Archive],
          read: [UserDomain.Settings, UserDomain.Archive],
          update: [UserDomain.Settings, UserDomain.Archive],
          delete: [UserDomain.Settings, UserDomain.Archive],
        };
        break;
    }

    return {
      ...user,
      fullName: user.firstName + ' ' + user.lastName,
      cannot: cannotArr,
    };
  }

  userInputFromIUser(user: IUser): UserInput {
    let result = this.userFromIUser(user) as any;
    result = l.omit(result, [
      'id',
      '__typename',
      'createdAt',
      'updatedAt',
      'deletedAt',
    ]) as UserInput;

    if (user.assignedLocation) {
      result.assignedLocation = user.assignedLocation.id;
    }

    if (user.assignedLanguage) {
      result.assignedLanguage = user.assignedLanguage.id;
    }

    return result;
  }
}
