import { gql } from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Upload: any;
};



export type Query = {
  __typename?: 'Query';
  languages: Array<Language>;
  tasks: Array<Task>;
  task?: Maybe<Task>;
  users: Array<User>;
  user?: Maybe<User>;
  myself?: Maybe<User>;
  companies: Array<Company>;
  company?: Maybe<Company>;
  locations: Array<Location>;
  files: Array<File>;
  file?: Maybe<File>;
  profileImage?: Maybe<ProfileImage>;
};


export type QueryTaskArgs = {
  taskId: Scalars['String'];
};


export type QueryUserArgs = {
  userId: Scalars['String'];
};


export type QueryCompanyArgs = {
  companyId: Scalars['String'];
};


export type QueryFileArgs = {
  fileId: Scalars['String'];
};


export type QueryProfileImageArgs = {
  userId: Scalars['String'];
};

export type Language = {
  __typename?: 'Language';
  system_language_name?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
  missing?: Maybe<Scalars['String']>;
  system_languages?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  translation_status?: Maybe<Scalars['String']>;
  actions?: Maybe<Scalars['String']>;
  number_of_employees?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  language_name: Scalars['String'];
  language_code: Scalars['String'];
  language_date_format?: Maybe<Scalars['String']>;
  yes?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  save?: Maybe<Scalars['String']>;
  create_task?: Maybe<Scalars['String']>;
  create_user?: Maybe<Scalars['String']>;
  create_company?: Maybe<Scalars['String']>;
  add_location?: Maybe<Scalars['String']>;
  finish_task?: Maybe<Scalars['String']>;
  unarchive_task?: Maybe<Scalars['String']>;
  edit_user?: Maybe<Scalars['String']>;
  logout?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['String']>;
  open_tasks?: Maybe<Scalars['String']>;
  planning?: Maybe<Scalars['String']>;
  in_progress?: Maybe<Scalars['String']>;
  waiting_for_feedback?: Maybe<Scalars['String']>;
  waiting_for_confirmation?: Maybe<Scalars['String']>;
  on_hold?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['String']>;
  all?: Maybe<Scalars['String']>;
  assigned_to_me?: Maybe<Scalars['String']>;
  following?: Maybe<Scalars['String']>;
  users?: Maybe<Scalars['String']>;
  companies?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  tasks?: Maybe<Scalars['String']>;
  archived_tasks?: Maybe<Scalars['String']>;
  archived_task?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
  user_details?: Maybe<Scalars['String']>;
  company_details?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  timber_trade?: Maybe<Scalars['String']>;
  engineering?: Maybe<Scalars['String']>;
  upkeep?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['String']>;
  damaged?: Maybe<Scalars['String']>;
  task_details?: Maybe<Scalars['String']>;
  task_comments?: Maybe<Scalars['String']>;
  enter_message?: Maybe<Scalars['String']>;
  add_comment?: Maybe<Scalars['String']>;
  upload_attachments?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['String']>;
  sort_tasks_by?: Maybe<Scalars['String']>;
  last_edited?: Maybe<Scalars['String']>;
  this_field_is_required?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  position_in_company?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  edit_profile_message?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_name?: Maybe<Scalars['String']>;
  location_city?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['String']>;
  feedback_created_task?: Maybe<Scalars['String']>;
  feedback_created_comment?: Maybe<Scalars['String']>;
  feedback_created_location?: Maybe<Scalars['String']>;
  feedback_created_company?: Maybe<Scalars['String']>;
  feedback_created_user?: Maybe<Scalars['String']>;
  feedback_updated_location?: Maybe<Scalars['String']>;
  feedback_updated_language?: Maybe<Scalars['String']>;
  feedback_updated_user?: Maybe<Scalars['String']>;
  feedback_updated_task_assignee?: Maybe<Scalars['String']>;
  feedback_updated_task_location?: Maybe<Scalars['String']>;
  feedback_updated_task_status?: Maybe<Scalars['String']>;
  feedback_updated_task_file?: Maybe<Scalars['String']>;
  summary_invalid_form_value?: Maybe<Scalars['String']>;
  summary_filesize?: Maybe<Scalars['String']>;
  summary_general_error?: Maybe<Scalars['String']>;
  insufficient_rights?: Maybe<Scalars['String']>;
  insufficient_rights_message?: Maybe<Scalars['String']>;
  detail_max_filesize?: Maybe<Scalars['String']>;
  loading?: Maybe<Scalars['String']>;
  loading_get_users?: Maybe<Scalars['String']>;
  loading_get_tasks?: Maybe<Scalars['String']>;
  loading_get_task?: Maybe<Scalars['String']>;
  loading_get_companies?: Maybe<Scalars['String']>;
  loading_get_file?: Maybe<Scalars['String']>;
  loading_get_company?: Maybe<Scalars['String']>;
  loading_create_task?: Maybe<Scalars['String']>;
  loading_create_comment?: Maybe<Scalars['String']>;
  loading_create_user?: Maybe<Scalars['String']>;
  loading_create_location?: Maybe<Scalars['String']>;
  loading_create_company?: Maybe<Scalars['String']>;
  loading_create_file?: Maybe<Scalars['String']>;
  loading_update_language?: Maybe<Scalars['String']>;
  loading_update_task?: Maybe<Scalars['String']>;
  loading_update_user?: Maybe<Scalars['String']>;
  loading_update_location?: Maybe<Scalars['String']>;
  loading_update_company?: Maybe<Scalars['String']>;
  loading_unarchive_task?: Maybe<Scalars['String']>;
  loading_archive_task?: Maybe<Scalars['String']>;
  loading_remove_follower?: Maybe<Scalars['String']>;
  loading_add_follower?: Maybe<Scalars['String']>;
  edit?: Maybe<Scalars['String']>;
  delete?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['String']>;
  unlock?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
  confirmation?: Maybe<Scalars['String']>;
  activate_user_message?: Maybe<Scalars['String']>;
  deactivate_user_message?: Maybe<Scalars['String']>;
  warning_unsaved_changes?: Maybe<Scalars['String']>;
  warning_create_location?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
  workshop?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  comment_add_file?: Maybe<Scalars['String']>;
  comment_change_assignee?: Maybe<Scalars['String']>;
  comment_change_status?: Maybe<Scalars['String']>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  archivedAt?: Maybe<Scalars['String']>;
  dueDate: Scalars['String'];
  assignedUser?: Maybe<User>;
  assignedCompany?: Maybe<Company>;
  assignedLocation?: Maybe<Location>;
  title: Scalars['String'];
  isHighPriority: Scalars['Boolean'];
  category: TaskCategory;
  description: Scalars['String'];
  status: TaskStatus;
  user?: Maybe<User>;
  followers: Array<User>;
  files: Array<File>;
  comments: Array<Comment>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  emailVerifiedAt?: Maybe<Scalars['String']>;
  birthday?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  isActiveUser: Scalars['Boolean'];
  assignedLanguage?: Maybe<Language>;
  telephone?: Maybe<Scalars['String']>;
  mobile: Scalars['String'];
  tasks: Array<Task>;
  files: Array<File>;
  comments: Array<Comment>;
  assignedLocation?: Maybe<Location>;
  positionInCompany: Scalars['String'];
  role: UserRole;
  followedTasks: Array<Task>;
  errorMessage?: Maybe<Scalars['String']>;
  profileImage?: Maybe<ProfileImage>;
};

export type File = {
  __typename?: 'File';
  id: Scalars['ID'];
  file?: Maybe<Scalars['String']>;
  fileTitle?: Maybe<Scalars['String']>;
  fileDisplayTitle?: Maybe<Scalars['String']>;
  fileDescription?: Maybe<Scalars['String']>;
  fileType?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  task?: Maybe<Task>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
};

export type Comment = {
  __typename?: 'Comment';
  id: Scalars['ID'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  commentTitle?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  task?: Maybe<Task>;
  user?: Maybe<User>;
  file?: Maybe<File>;
};

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  streetName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  assignedCompany: Company;
  errorMessage?: Maybe<Scalars['String']>;
  users: Array<User>;
  tasks: Array<Task>;
};

export type Company = {
  __typename?: 'Company';
  id: Scalars['ID'];
  companyName: Scalars['String'];
  companyLogo?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  deletedAt?: Maybe<Scalars['String']>;
  locations: Array<Location>;
};

export enum UserRole {
  Admin = 'admin',
  Manager = 'manager',
  Employee = 'employee',
  Partner = 'partner',
  Workshop = 'workshop',
  Service = 'service'
}

export type ProfileImage = {
  __typename?: 'ProfileImage';
  id: Scalars['ID'];
  profileImage?: Maybe<Scalars['String']>;
  profileImageTitle?: Maybe<Scalars['String']>;
  profileImageType?: Maybe<Scalars['String']>;
  profileImagePath?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  createdAt: Scalars['String'];
  updatedAt?: Maybe<Scalars['String']>;
  deletedAt?: Maybe<Scalars['String']>;
};

export enum TaskCategory {
  TimberTrade = 'timber_trade',
  Engineering = 'engineering',
  Upkeep = 'upkeep',
  Internal = 'internal',
  Damaged = 'damaged'
}

export enum TaskStatus {
  Open = 'open',
  Planning = 'planning',
  InProgress = 'in_progress',
  WaitingForFeedback = 'waiting_for_feedback',
  WaitingForConfirmation = 'waiting_for_confirmation',
  OnHold = 'on_hold'
}

export type Mutation = {
  __typename?: 'Mutation';
  createTask?: Maybe<Task>;
  editTask?: Maybe<Task>;
  deleteTask?: Maybe<Task>;
  createLanguage?: Maybe<Language>;
  editLanguage?: Maybe<Language>;
  deleteLanguage?: Maybe<Language>;
  createUser?: Maybe<User>;
  editUser?: Maybe<User>;
  deleteUser?: Maybe<User>;
  createCompany?: Maybe<Company>;
  createLocation?: Maybe<Location>;
  editCompany?: Maybe<Company>;
  editLocation?: Maybe<Location>;
  deleteCompany?: Maybe<Company>;
  createComment?: Maybe<Comment>;
  addFollower?: Maybe<User>;
  removeFollower?: Maybe<User>;
  editComment?: Maybe<Comment>;
  createFile?: Maybe<File>;
  deleteFile?: Maybe<File>;
  archiveTask?: Maybe<Task>;
  createProfileImage?: Maybe<ProfileImage>;
  deleteProfileImage?: Maybe<ProfileImage>;
};


export type MutationCreateTaskArgs = {
  task: TaskInput;
};


export type MutationEditTaskArgs = {
  task: TaskUpdate;
  taskId: Scalars['String'];
};


export type MutationDeleteTaskArgs = {
  taskId: Scalars['String'];
};


export type MutationCreateLanguageArgs = {
  language: LanguageInput;
};


export type MutationEditLanguageArgs = {
  language: LanguageInput;
  languageId: Scalars['String'];
};


export type MutationDeleteLanguageArgs = {
  languageId: Scalars['String'];
};


export type MutationCreateUserArgs = {
  user: UserInput;
};


export type MutationEditUserArgs = {
  user: UserUpdate;
  userId: Scalars['String'];
};


export type MutationDeleteUserArgs = {
  userId: Scalars['String'];
};


export type MutationCreateCompanyArgs = {
  company: CompanyInput;
};


export type MutationCreateLocationArgs = {
  location: LocationInput;
  companyId: Scalars['String'];
};


export type MutationEditCompanyArgs = {
  company: CompanyUpdate;
  companyId: Scalars['String'];
};


export type MutationEditLocationArgs = {
  location: LocationUpdate;
  locationId: Scalars['String'];
};


export type MutationDeleteCompanyArgs = {
  companyId: Scalars['String'];
};


export type MutationCreateCommentArgs = {
  comment: CommentInput;
};


export type MutationAddFollowerArgs = {
  taskId: Scalars['String'];
  followerId: Scalars['String'];
};


export type MutationRemoveFollowerArgs = {
  taskId: Scalars['String'];
  followerId: Scalars['String'];
};


export type MutationEditCommentArgs = {
  comment: CommentUpdate;
  commentId: Scalars['String'];
};


export type MutationCreateFileArgs = {
  file: FileInput;
};


export type MutationDeleteFileArgs = {
  fileId: Scalars['String'];
};


export type MutationArchiveTaskArgs = {
  taskId: Scalars['String'];
  archive: Scalars['Boolean'];
};


export type MutationCreateProfileImageArgs = {
  profileImage: ProfileImageInput;
};


export type MutationDeleteProfileImageArgs = {
  userId: Scalars['String'];
};

export type TaskInput = {
  dueDate: Scalars['String'];
  description: Scalars['String'];
  assignedUserId: Scalars['String'];
  assignedLocationId: Scalars['String'];
  title: Scalars['String'];
  isHighPriority: Scalars['Boolean'];
  category: TaskCategory;
  status: TaskStatus;
  comments?: Maybe<CommentsForTask>;
  followerIds: Array<Scalars['String']>;
};

export type CommentsForTask = {
  create: Array<CommentForTask>;
};

export type CommentForTask = {
  commentTitle: Scalars['String'];
  comment: Scalars['String'];
};

export type TaskUpdate = {
  dueDate?: Maybe<StrChange>;
  description?: Maybe<StrChange>;
  assignedUserId?: Maybe<StrChange>;
  assignedLocationId?: Maybe<StrChange>;
  title?: Maybe<StrChange>;
  isHighPriority?: Maybe<BoolChange>;
  category?: Maybe<CategoryChange>;
  status?: Maybe<StatusChange>;
};

export type StrChange = {
  value: Scalars['String'];
  change?: Maybe<Scalars['Boolean']>;
};

export type BoolChange = {
  value: Scalars['Boolean'];
  change?: Maybe<Scalars['Boolean']>;
};

export type CategoryChange = {
  value: TaskCategory;
  change?: Maybe<Scalars['Boolean']>;
};

export type StatusChange = {
  value: TaskStatus;
  change?: Maybe<Scalars['Boolean']>;
};

export type LanguageInput = {
  system_language_name?: Maybe<Scalars['String']>;
  translation?: Maybe<Scalars['String']>;
  missing?: Maybe<Scalars['String']>;
  system_languages?: Maybe<Scalars['String']>;
  abbreviation?: Maybe<Scalars['String']>;
  translation_status?: Maybe<Scalars['String']>;
  actions?: Maybe<Scalars['String']>;
  number_of_employees?: Maybe<Scalars['String']>;
  language_name: Scalars['String'];
  language_code: Scalars['String'];
  language_date_format?: Maybe<Scalars['String']>;
  yes?: Maybe<Scalars['String']>;
  no?: Maybe<Scalars['String']>;
  save?: Maybe<Scalars['String']>;
  create_task?: Maybe<Scalars['String']>;
  create_user?: Maybe<Scalars['String']>;
  create_company?: Maybe<Scalars['String']>;
  add_location?: Maybe<Scalars['String']>;
  finish_task?: Maybe<Scalars['String']>;
  unarchive_task?: Maybe<Scalars['String']>;
  edit_user?: Maybe<Scalars['String']>;
  logout?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['String']>;
  open_tasks?: Maybe<Scalars['String']>;
  planning?: Maybe<Scalars['String']>;
  in_progress?: Maybe<Scalars['String']>;
  waiting_for_feedback?: Maybe<Scalars['String']>;
  waiting_for_confirmation?: Maybe<Scalars['String']>;
  on_hold?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['String']>;
  all?: Maybe<Scalars['String']>;
  assigned_to_me?: Maybe<Scalars['String']>;
  following?: Maybe<Scalars['String']>;
  users?: Maybe<Scalars['String']>;
  companies?: Maybe<Scalars['String']>;
  languages?: Maybe<Scalars['String']>;
  tasks?: Maybe<Scalars['String']>;
  archived_tasks?: Maybe<Scalars['String']>;
  archived_task?: Maybe<Scalars['String']>;
  settings?: Maybe<Scalars['String']>;
  user_details?: Maybe<Scalars['String']>;
  company_details?: Maybe<Scalars['String']>;
  profile?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  archive?: Maybe<Scalars['String']>;
  timber_trade?: Maybe<Scalars['String']>;
  engineering?: Maybe<Scalars['String']>;
  upkeep?: Maybe<Scalars['String']>;
  internal?: Maybe<Scalars['String']>;
  damaged?: Maybe<Scalars['String']>;
  task_details?: Maybe<Scalars['String']>;
  task_comments?: Maybe<Scalars['String']>;
  enter_message?: Maybe<Scalars['String']>;
  add_comment?: Maybe<Scalars['String']>;
  upload_attachments?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  due_date?: Maybe<Scalars['String']>;
  priority?: Maybe<Scalars['String']>;
  assignee?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  attachments?: Maybe<Scalars['String']>;
  sort_tasks_by?: Maybe<Scalars['String']>;
  last_edited?: Maybe<Scalars['String']>;
  this_field_is_required?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  first_name?: Maybe<Scalars['String']>;
  last_name?: Maybe<Scalars['String']>;
  email_address?: Maybe<Scalars['String']>;
  phone_number?: Maybe<Scalars['String']>;
  mobile_number?: Maybe<Scalars['String']>;
  position_in_company?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  edit_profile_message?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  location_name?: Maybe<Scalars['String']>;
  location_city?: Maybe<Scalars['String']>;
  is_active?: Maybe<Scalars['String']>;
  feedback_created_task?: Maybe<Scalars['String']>;
  feedback_created_comment?: Maybe<Scalars['String']>;
  feedback_created_location?: Maybe<Scalars['String']>;
  feedback_created_company?: Maybe<Scalars['String']>;
  feedback_created_user?: Maybe<Scalars['String']>;
  feedback_updated_location?: Maybe<Scalars['String']>;
  feedback_updated_language?: Maybe<Scalars['String']>;
  feedback_updated_user?: Maybe<Scalars['String']>;
  feedback_updated_task_assignee?: Maybe<Scalars['String']>;
  feedback_updated_task_location?: Maybe<Scalars['String']>;
  feedback_updated_task_status?: Maybe<Scalars['String']>;
  feedback_updated_task_file?: Maybe<Scalars['String']>;
  summary_invalid_form_value?: Maybe<Scalars['String']>;
  summary_filesize?: Maybe<Scalars['String']>;
  summary_general_error?: Maybe<Scalars['String']>;
  insufficient_rights?: Maybe<Scalars['String']>;
  insufficient_rights_message?: Maybe<Scalars['String']>;
  detail_max_filesize?: Maybe<Scalars['String']>;
  loading?: Maybe<Scalars['String']>;
  loading_get_users?: Maybe<Scalars['String']>;
  loading_get_tasks?: Maybe<Scalars['String']>;
  loading_get_task?: Maybe<Scalars['String']>;
  loading_get_companies?: Maybe<Scalars['String']>;
  loading_get_file?: Maybe<Scalars['String']>;
  loading_get_company?: Maybe<Scalars['String']>;
  loading_create_task?: Maybe<Scalars['String']>;
  loading_create_comment?: Maybe<Scalars['String']>;
  loading_create_user?: Maybe<Scalars['String']>;
  loading_create_location?: Maybe<Scalars['String']>;
  loading_create_company?: Maybe<Scalars['String']>;
  loading_create_file?: Maybe<Scalars['String']>;
  loading_update_language?: Maybe<Scalars['String']>;
  loading_update_task?: Maybe<Scalars['String']>;
  loading_update_user?: Maybe<Scalars['String']>;
  loading_update_location?: Maybe<Scalars['String']>;
  loading_update_company?: Maybe<Scalars['String']>;
  loading_unarchive_task?: Maybe<Scalars['String']>;
  loading_archive_task?: Maybe<Scalars['String']>;
  loading_remove_follower?: Maybe<Scalars['String']>;
  loading_add_follower?: Maybe<Scalars['String']>;
  edit?: Maybe<Scalars['String']>;
  delete?: Maybe<Scalars['String']>;
  lock?: Maybe<Scalars['String']>;
  unlock?: Maybe<Scalars['String']>;
  view?: Maybe<Scalars['String']>;
  confirmation?: Maybe<Scalars['String']>;
  activate_user_message?: Maybe<Scalars['String']>;
  deactivate_user_message?: Maybe<Scalars['String']>;
  warning_unsaved_changes?: Maybe<Scalars['String']>;
  warning_create_location?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['String']>;
  manager?: Maybe<Scalars['String']>;
  employee?: Maybe<Scalars['String']>;
  partner?: Maybe<Scalars['String']>;
  workshop?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  comment_add_file?: Maybe<Scalars['String']>;
  comment_change_assignee?: Maybe<Scalars['String']>;
  comment_change_status?: Maybe<Scalars['String']>;
};

export type UserInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  birthday?: Maybe<Scalars['String']>;
  salutation?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  emailVerifiedAt?: Maybe<Scalars['String']>;
  country: Scalars['String'];
  assignedLanguage: Scalars['String'];
  telephone: Scalars['String'];
  mobile: Scalars['String'];
  isActiveUser: Scalars['Boolean'];
  positionInCompany: Scalars['String'];
  role: UserRole;
  assignedLocation: Scalars['String'];
};

export type UserUpdate = {
  firstName?: Maybe<StrChange>;
  lastName?: Maybe<StrChange>;
  email?: Maybe<StrChange>;
  country?: Maybe<StrChange>;
  assignedLanguage?: Maybe<StrChange>;
  telephone?: Maybe<StrChange>;
  mobile?: Maybe<StrChange>;
  isActiveUser?: Maybe<BoolChange>;
  positionInCompany?: Maybe<StrChange>;
  role?: Maybe<UserRoleChange>;
  assignedLocation?: Maybe<StrChange>;
};

export type UserRoleChange = {
  value: UserRole;
  change?: Maybe<Scalars['Boolean']>;
};

export type CompanyInput = {
  companyName: Scalars['String'];
  companyLogo?: Maybe<Scalars['String']>;
};

export type LocationInput = {
  name?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  streetName?: Maybe<Scalars['String']>;
  buildingNumber?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['String']>;
};

export type CompanyUpdate = {
  companyName?: Maybe<StrChange>;
  companyLogo?: Maybe<StrChange>;
};

export type LocationUpdate = {
  name?: Maybe<StrChange>;
  isActive?: Maybe<BoolChange>;
  streetName?: Maybe<StrChange>;
  buildingNumber?: Maybe<StrChange>;
  postcode?: Maybe<StrChange>;
  city?: Maybe<StrChange>;
  state?: Maybe<StrChange>;
  country?: Maybe<StrChange>;
  phoneNumber?: Maybe<StrChange>;
  mobileNumber?: Maybe<StrChange>;
  latitude?: Maybe<StrChange>;
  longitude?: Maybe<StrChange>;
  companyID?: Maybe<StrChange>;
};

export type CommentInput = {
  commentTitle: Scalars['String'];
  comment: Scalars['String'];
  taskId: Scalars['String'];
};

export type CommentUpdate = {
  commentTitle?: Maybe<StrChange>;
  comment?: Maybe<StrChange>;
};

export type FileInput = {
  fileTitle: Scalars['String'];
  fileDescription?: Maybe<Scalars['String']>;
  taskId: Scalars['String'];
  file: Scalars['Upload'];
};


export type ProfileImageInput = {
  profileImageTitle: Scalars['String'];
  profileImage: Scalars['Upload'];
};

export type Auth0User = {
  __typename?: 'Auth0User';
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

/** The available directions for ordering a list of records. */
export enum SortOrder {
  /** Sort records in ascending order. */
  Asc = 'ASC',
  /** Sort records in descending order. */
  Desc = 'DESC'
}

/** Allows ordering a list of records. */
export type OrderByClause = {
  /** The column that is used for ordering. */
  column: Scalars['String'];
  /** The direction that is used for ordering. */
  order: SortOrder;
};

/** Pagination information about the corresponding list of items. */
export type PaginatorInfo = {
  __typename?: 'PaginatorInfo';
  /** Total count of available items in the page. */
  count: Scalars['Int'];
  /** Current pagination page. */
  currentPage: Scalars['Int'];
  /** Index of first item in the current page. */
  firstItem?: Maybe<Scalars['Int']>;
  /** If collection has more pages. */
  hasMorePages: Scalars['Boolean'];
  /** Index of last item in the current page. */
  lastItem?: Maybe<Scalars['Int']>;
  /** Last page number of the collection. */
  lastPage: Scalars['Int'];
  /** Number of items per page in the collection. */
  perPage: Scalars['Int'];
  /** Total items available in the collection. */
  total: Scalars['Int'];
};

/** Pagination information about the corresponding list of items. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Total number of node in connection. */
  total?: Maybe<Scalars['Int']>;
  /** Count of nodes in current request. */
  count?: Maybe<Scalars['Int']>;
  /** Current page of request. */
  currentPage?: Maybe<Scalars['Int']>;
  /** Last page in connection. */
  lastPage?: Maybe<Scalars['Int']>;
};

/** Specify if you want to include or exclude trashed results from a query. */
export enum Trashed {
  /** Only return trashed results. */
  Only = 'ONLY',
  /** Return both trashed and non-trashed results. */
  With = 'WITH',
  /** Only return non-trashed results. */
  Without = 'WITHOUT'
}
