<div
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <div class="p-grid p-mb-6">
    <div class="p-col-2">
      <p-button
        label="+ {{ selectedLanguage.user }}"
        [style]="{ width: '100%' }"
        routerLink="create"
      ></p-button>
    </div>
    <div class="p-col-9">
      <input type="text" pInputText [(ngModel)]="searchText" />
    </div>
  </div>

  <ng-container *ngIf="root?.usersStore?.users$ | async as users">
    <h1 class="p-ml-2 p-mb-3">{{ selectedLanguage.manager }}</h1>
    <app-primeng-table
      [columns]="columns"
      [rows]="
        root.usersViewStore.filteredUsersWithRole([userRole.Manager]) | async
      "
      [editFn]="navigateToUser.bind(this)"
      [lockFn]="toggleLockForUser.bind(this)"
      [activeField]="activeField"
    ></app-primeng-table>

    <h1 class="p-ml-2 p-mb-3">{{ selectedLanguage.employee }}</h1>
    <app-primeng-table
      [columns]="columns"
      [rows]="
        root.usersViewStore.filteredUsersWithRole([userRole.Employee]) | async
      "
      [editFn]="navigateToUser.bind(this)"
      [lockFn]="toggleLockForUser.bind(this)"
      [activeField]="activeField"
    ></app-primeng-table>

    <h1 class="p-ml-2 p-mb-3">{{ selectedLanguage.partner }}</h1>
    <app-primeng-table
      [columns]="columns"
      [rows]="
        root.usersViewStore.filteredUsersWithRole([
          userRole.Partner,
          userRole.Service,
          userRole.Workshop
        ]) | async
      "
      [editFn]="navigateToUser.bind(this)"
      [lockFn]="toggleLockForUser.bind(this)"
      [activeField]="activeField"
    ></app-primeng-table>
  </ng-container>
</div>
