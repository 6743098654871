import { gql } from 'apollo-angular';

export const EditLanguageMutation = gql`
  mutation EditLanguage($language: LanguageInput!, $languageId: String!) {
    editLanguage(language: $language, languageId: $languageId) {
      __typename
      id

      system_language_name
      translation
      missing

      system_languages
      abbreviation
      translation_status
      actions

      number_of_employees

      language_name
      language_code
      language_date_format

      yes
      no
      save
      create_task
      create_user
      create_company
      add_location
      finish_task
      unarchive_task

      edit_user
      logout

      open
      open_tasks
      planning
      in_progress
      waiting_for_feedback
      waiting_for_confirmation
      on_hold
      archived

      all
      assigned_to_me
      following
      users
      companies
      languages

      tasks
      archived_tasks
      archived_task
      settings
      user_details
      company_details
      profile

      task
      archive

      timber_trade
      engineering
      upkeep
      internal
      damaged

      task_details
      task_comments
      enter_message
      add_comment
      upload_attachments

      title
      category
      due_date
      priority
      assignee
      status
      description
      company
      attachments

      sort_tasks_by
      last_edited

      this_field_is_required

      user
      name
      first_name
      last_name
      email_address
      phone_number
      mobile_number
      position_in_company
      role
      language
      country

      edit_profile_message

      location
      location_name
      location_city
      is_active

      feedback_created_task
      feedback_created_comment
      feedback_created_location
      feedback_created_company
      feedback_created_user

      feedback_updated_location
      feedback_updated_language
      feedback_updated_user
      feedback_updated_task_assignee
      feedback_updated_task_location
      feedback_updated_task_status
      feedback_updated_task_file

      summary_invalid_form_value
      summary_filesize
      summary_general_error
      insufficient_rights
      insufficient_rights_message
      detail_max_filesize

      loading
      loading_get_users
      loading_get_tasks
      loading_get_task
      loading_get_companies
      loading_get_file
      loading_get_company

      loading_create_task
      loading_create_comment
      loading_create_user
      loading_create_location
      loading_create_company
      loading_create_file

      loading_update_language
      loading_update_task
      loading_update_user
      loading_update_location
      loading_update_company
      loading_unarchive_task
      loading_archive_task
      loading_remove_follower
      loading_add_follower

      edit
      delete
      lock
      unlock
      view

      confirmation
      activate_user_message
      deactivate_user_message

      warning_unsaved_changes
      warning_create_location

      admin
      manager
      employee
      partner
      workshop
      service

      comment_add_file
      comment_change_assignee
      comment_change_status
    }
  }
`;
