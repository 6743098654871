import { gql } from 'apollo-angular';

export const CreateCommentMutation = gql`
  mutation CreateComment($comment: CommentInput!) {
    createComment(comment: $comment) {
      __typename
      id
    }
  }
`;
