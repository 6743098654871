import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { BehaviorSubject, Subject } from 'rxjs';
import { filter, first } from 'rxjs/operators';

import { RootStore } from '../../../store/root.store';
import {
  DisplayFilter,
  TasksSortParam,
  UserAction,
} from '../../../types/types/enums';

enum DeskNavigationMode {
  None = 'None',
  Tasks = 'Tasks',
  ArchivedTasks = 'ArchivedTasks',
  Task = 'Task',
  ArchivedTask = 'ArchivedTask',
  CreateTask = 'CreateTask',
  Settings = 'Setting',
  User = 'User',
  CreateUser = 'CreateUser',
  Profile = 'Profile',
  Company = 'Company',
  CreateCompany = 'CreateCompany',
  Language = 'Language',
}
@Component({
  selector: 'app-desk-navigation',
  templateUrl: './desk-navigation.component.html',
  styleUrls: ['./desk-navigation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DeskNavigationComponent implements OnInit, OnDestroy {
  readonly tasksSortParamEnum = TasksSortParam;
  readonly deskNavigationModeEnum = DeskNavigationMode;

  sortByItems: { label: string; click: string }[];
  sortByItemActive: { label: string; click: string };

  modeSubject = new BehaviorSubject(DeskNavigationMode.None);

  tasksMenuItems: MenuItem[];
  tasksMenuActiveItem: MenuItem;

  settingsMenuItems: MenuItem[];
  settingsMenuActiveItem: MenuItem;

  private readonly componentDestroyed$ = new Subject();
  constructor(
    private router: Router,
    public root: RootStore,
    private location: Location
  ) {}

  async ngOnInit(): Promise<void> {
    // initialize MenuItems
    await this.determineSortByItems();
    await this.determineSettingsMenuItems();
    await this.determineTasksMenuItems();

    this.switchNavigationMode(this.location.path());
    this.location.onUrlChange((url: string) => {
      this.switchNavigationMode(url);
    });

    // determine inital active items
    this.determineTasksMenuItemActive();
    this.determineSettingsMenuActiveItem();
    this.determineSortByItemActive();
  }

  ngOnDestroy(): void {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  async determineTasksMenuItems(): Promise<void> {
    const filterAllPermitted = await this.root.authService.hasPermission({
      do: [UserAction.FilterAll],
    });
    this.tasksMenuItems = [
      {
        label: (
          await this.root.localizationStore.selectedLanguage()
        ).assigned_to_me.toUpperCase(),
        command: (event: any) => {
          this.determineTasksMenuItemActive(event);
          this.root.tasksViewStore.displayFilterSubject.next(
            DisplayFilter.AssignedToMe
          );
        },
      },
      {
        label: (
          await this.root.localizationStore.selectedLanguage()
        ).following.toUpperCase(),
        command: (event: any) => {
          this.determineTasksMenuItemActive(event);
          this.root.tasksViewStore.displayFilterSubject.next(
            DisplayFilter.Following
          );
        },
      },
      ...(filterAllPermitted
        ? [
            {
              label: (
                await this.root.localizationStore.selectedLanguage()
              ).all.toUpperCase(),
              command: (event: any) => {
                this.determineTasksMenuItemActive(event);
                this.root.tasksViewStore.displayFilterSubject.next(
                  DisplayFilter.All
                );
              },
            },
          ]
        : []),
    ];
  }

  determineTasksMenuItemActive(event: any = null): void {
    this.tasksMenuActiveItem = event ? event.item : this.tasksMenuItems[0];
  }

  async determineSettingsMenuItems(): Promise<void> {
    this.settingsMenuItems = [
      {
        label: (await this.root.localizationStore.selectedLanguage()).users,
        command: () => this.router.navigateByUrl('settings/users'),
      },
      {
        label: (await this.root.localizationStore.selectedLanguage()).companies,
        command: () => this.router.navigateByUrl('settings/companies'),
      },
      {
        label: (await this.root.localizationStore.selectedLanguage()).languages,
        command: () => this.router.navigateByUrl('settings/languages'),
      },
    ];
  }

  determineSettingsMenuActiveItem(): void {
    if (!this.settingsMenuActiveItem) {
      this.settingsMenuActiveItem = this.settingsMenuItems[0];
    }
  }

  async determineSortByItems(): Promise<void> {
    this.sortByItems = [
      {
        label: (await this.root.localizationStore.selectedLanguage()).due_date,
        click: this.tasksSortParamEnum.ByDueDate,
      },
      {
        label: (await this.root.localizationStore.selectedLanguage())
          .last_edited,
        click: this.tasksSortParamEnum.ByLastEdited,
      },
    ];
  }

  determineSortByItemActive(): void {
    this.sortByItemActive = this.sortByItems[0];
  }

  handleSortButtonClick(tasksSortParam: TasksSortParam): void {
    this.root.tasksViewStore.tasksSortParamSubject.next(tasksSortParam);
  }

  switchNavigationMode(url: string): void {
    if (url.includes('/archive/tasks/')) {
      url = '/archive/tasks/:id';
    } else if (url.includes('/tasks/') && !url.includes('/create')) {
      url = '/tasks/:id';
    } else if (url.includes('/settings/users/') && !url.includes('/create')) {
      url = '/settings/users/:id';
    } else if (
      url.includes('/settings/languages/') &&
      !url.includes('/create')
    ) {
      url = '/settings/languages/:id';
    } else if (
      url.includes('/settings/companies/') &&
      !url.includes('/create')
    ) {
      url = '/settings/companies/:id';
    }

    switch (url) {
      case '/tasks':
        this.modeSubject.next(DeskNavigationMode.Tasks);
        break;
      case '/tasks/create':
        this.modeSubject.next(DeskNavigationMode.CreateTask);
        break;
      case '/tasks/:id':
        this.modeSubject.next(DeskNavigationMode.Task);
        break;
      case '/archive/tasks':
        this.modeSubject.next(DeskNavigationMode.ArchivedTasks);
        break;
      case '/archive/tasks/:id':
        this.modeSubject.next(DeskNavigationMode.ArchivedTask);
        break;
      case '/settings/users':
        this.modeSubject.next(DeskNavigationMode.Settings);
        this.settingsMenuActiveItem = this.settingsMenuItems[0];
        break;
      case '/settings/users/create':
        this.modeSubject.next(DeskNavigationMode.CreateUser);
        break;
      case '/settings/users/:id':
        this.modeSubject.next(DeskNavigationMode.User);
        break;
      case '/profile':
        this.modeSubject.next(DeskNavigationMode.Profile);
        break;
      case '/settings/companies':
        this.modeSubject.next(DeskNavigationMode.Settings);
        this.settingsMenuActiveItem = this.settingsMenuItems[1];
        break;
      case '/settings/companies/create':
        this.modeSubject.next(DeskNavigationMode.CreateCompany);
        this.settingsMenuActiveItem = this.settingsMenuItems[1];
        break;
      case '/settings/companies/:id':
        this.modeSubject.next(DeskNavigationMode.Company);
        this.settingsMenuActiveItem = this.settingsMenuItems[1];
        break;
      case '/settings/languages':
        this.modeSubject.next(DeskNavigationMode.Settings);
        this.settingsMenuActiveItem = this.settingsMenuItems[2];
        break;
      case '/settings/languages/:id':
        this.modeSubject.next(DeskNavigationMode.Language);
        this.settingsMenuActiveItem = this.settingsMenuItems[2];
        break;
      default:
        this.modeSubject.next(DeskNavigationMode.None);
        break;
    }
  }

  async handleArchiveTask(archive: boolean): Promise<void> {
    const routeSegments = this.router.url.split('/');
    const taskId = routeSegments[routeSegments.length - 1];

    await this.root.tasksStore.archiveTask(taskId, archive);

    if (archive) {
      this.router.navigateByUrl('archive/tasks/' + taskId);
    } else {
      this.router.navigateByUrl('tasks/' + taskId);
    }
  }
}
