import { gql } from 'apollo-angular';

export const TaskQuery = gql`
  query Task($taskId: String!) {
    task(taskId: $taskId) {
      __typename
      id
      createdAt
      updatedAt
      deletedAt
      archivedAt
      dueDate
      title
      description
      status
      category
      isHighPriority
      comments {
        id
        createdAt
        updatedAt
        commentTitle
        comment
        user {
          id
          firstName
          lastName
          profileImage {
            profileImagePath
          }
        }
        file {
          id
          fileTitle
          fileDisplayTitle
          fileDescription
          fileType
        }
      }
      followers {
        id
        firstName
        lastName
      }
      files {
        id
        fileTitle
        fileDisplayTitle
        fileDescription
        fileType
      }
      assignedLocation {
        id
        name
        assignedCompany {
          __typename
          id
          companyName
        }
      }
      assignedCompany {
        __typename
        id
        companyLogo
        companyName
        createdAt
        deletedAt
        updatedAt
      }
      assignedUser {
        __typename
        id
        createdAt
        updatedAt
        deletedAt
        emailVerifiedAt
        birthday
        salutation
        firstName
        lastName
        email
        telephone
        mobile
        positionInCompany
        assignedLocation {
          id
          name
        }
      }
    }
  }
`;
