import { gql } from 'apollo-angular';

export const LocationsQuery = gql`
  {
    locations {
      __typename
      id
      name
      isActive
      streetName
      buildingNumber
      postcode
      city
      state
      country
      phoneNumber
      mobileNumber
      latitude
      longitude
      assignedCompany {
        id
        companyLogo
        companyName
        createdAt
        deletedAt
        updatedAt
      }
      users {
        id
        firstName
        lastName
      }
      errorMessage
    }
  }
`;
