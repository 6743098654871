<div
  class="card-wrapper"
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <a [routerLink]="'../tasks/' + taskStore?.value?.id">
    <p-card
      class="card {{ taskStore?.value?.category }} "
      [ngClass]="{
        priority: taskStore?.value?.isHighPriority
      }"
      [header]="selectedLanguage[taskStore?.value?.category]"
      [subheader]="taskStore?.value?.title"
    >
      <ng-template pTemplate="footer">
        <p>
          <i class="pi pi-user"></i>
          <span class="p-ml-2">
            {{ taskStore?.value?.assignedUser?.firstName }}
            {{ taskStore?.value?.assignedUser?.lastName }}
          </span>
        </p>
        <p>
          <i class="pi pi-circle-on {{ taskStore.dueDateColor() }}"></i>
          <span class="p-ml-2">
            {{
              taskStore?.value?.dueDate
                | date: selectedLanguage.language_date_format
            }}
          </span>
        </p>
        <p>
          <i class="pi pi-home"></i>
          <span class="p-ml-2">{{
            taskStore?.value?.assignedCompany?.companyName
          }}</span>
        </p>
      </ng-template>
    </p-card>
  </a>
</div>
