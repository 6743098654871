import { gql } from 'apollo-angular';

export const MyselfUsersQuery = gql`
  {
    myself {
      __typename
      id
      assignedLocation {
        id
        users {
          id
          firstName
          lastName
        }
      }
    }
  }
`;
