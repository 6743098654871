import { gql } from 'apollo-angular';

export const EditTaskMutation = gql`
  mutation EditTask($task: TaskUpdate!, $taskId: String!) {
    editTask(task: $task, taskId: $taskId) {
      __typename
      id
      createdAt
      updatedAt
      deletedAt
      archivedAt
      dueDate
      title
      description
      status
      category
      isHighPriority
      comments {
        id
        createdAt
        updatedAt
        commentTitle
        comment
        user {
          id
          firstName
          lastName
        }
        file {
          id
          fileTitle
          fileDescription
          fileType
        }
      }
      files {
        id
        fileTitle
        fileDescription
        fileType
      }
      assignedCompany {
        __typename
        companyLogo
        companyName
        createdAt
        deletedAt
        id
        updatedAt
      }
      assignedUser {
        __typename
        id
        createdAt
        updatedAt
        deletedAt
        emailVerifiedAt
        birthday
        salutation
        firstName
        lastName
        email
        telephone
        mobile
        positionInCompany
      }
      followers {
        id
        firstName
        lastName
      }
      user {
        __typename
        id
        createdAt
        updatedAt
        deletedAt
        emailVerifiedAt
        birthday
        salutation
        firstName
        lastName
        email
        telephone
        mobile
        positionInCompany
      }
    }
  }
`;
