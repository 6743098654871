<form
  [formGroup]="form"
  (ngSubmit)="handleSubmit()"
  *ngIf="
    !!fields.length &&
      !!(userOptions$ | async)?.length &&
      !!(locationOptions$ | async)?.length;
    else skeleton
  "
>
  <formly-form [form]="form" [fields]="fields" [model]="model"></formly-form>
  <button
    pButton
    *ngIf="hasSubmit"
    type="submit"
    label="Save"
    [disabled]="!form.valid"
    style="width: 50%"
    class="p-mt-5"
  ></button>
</form>
<ng-template #skeleton>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="p-fluid">
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="250px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
      </div>
    </div>
  </div>
</ng-template>
