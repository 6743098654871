import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';

import { RootStore } from '../../store/root.store';
import { UserStore } from '../../store/stores/user.store';
import { UserInput } from '../../types/graphql';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss'],
})
export class UserComponent implements OnInit {
  userId: string;
  user$: Observable<UserStore>;

  constructor(private route: ActivatedRoute, public root: RootStore) {
    this.userId = this.route.snapshot.paramMap.get('id');
    this.user$ = this.root.usersStore.getUser(this.userId);
  }

  ngOnInit(): void {
    this.root.usersStore.fetchUser(this.userId);
  }

  handleSubmit(userInput: Partial<UserInput>): void {
    this.root.usersStore.updateUser(this.userId, userInput);
    this.root.applicationStore.changesSaved();
  }
}
