<div
  [ngStyle]="{
    pointerEvents: !!(root.applicationStore.blockingMutationInProgress$ | async)
      ?.length
      ? 'none'
      : ''
  }"
>
  <ng-container>
    <div
      class="spinner"
      [ngClass]="
        (root.applicationStore.displayLoadingSpinner$ | async)
          ? 'transition'
          : ''
      "
    >
      <p-progressSpinner></p-progressSpinner>
      <div>
        <h2 *ngFor="let message of loadingMessages$ | async">
          {{ message }}
        </h2>
      </div>
    </div>
  </ng-container>

  <router-outlet></router-outlet>

  <p-toast position="bottom-center"></p-toast>
</div>
