import { gql } from 'apollo-angular';

export const ProfileImageQuery = gql`
  query ProfileImage($userId: String!) {
    profileImage(userId: $userId) {
      id
      profileImagePath
      profileImageTitle
      profileImageType
    }
  }
`;
