import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { first, tap } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import { UserStore } from '../../store/stores/user.store';
import { UserInput } from '../../types/graphql';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss'],
})
export class CreateUserComponent implements OnInit {
  constructor(public root: RootStore, private router: Router) {}

  ngOnInit(): void {}

  async handleSubmit(userInput: UserInput): Promise<void> {
    const user = await this.root.usersStore.createUser(userInput);

    this.root.applicationStore.changesSaved();

    user
      .pipe(
        tap((el: UserStore) => this.router.navigateByUrl('settings/users')),
        first()
      )
      .subscribe();
  }
}
