import { Routes } from '@angular/router';

import { PendingChangesGuard } from '../guards/pending-changes.guard';
import { PermissionGuard } from '../guards/permission.guard';
import { CreateTaskComponent } from '../pages/create-task/create-task.component';
import { TaskComponent } from '../pages/task/task.component';
import { TasksComponent } from '../pages/tasks/tasks.component';
import { UserDomain } from '../types/types/enums';

export const taskRoutes: Routes = [
  {
    path: 'tasks',
    component: TasksComponent,
  },
  {
    path: 'tasks/create',
    component: CreateTaskComponent,
    canDeactivate: [PendingChangesGuard],
  },
  { path: 'tasks/:id', component: TaskComponent },
  {
    path: 'archive/tasks',
    component: TasksComponent,
    canActivate: [PermissionGuard],
    data: {
      read: [UserDomain.Archive],
      create: [UserDomain.Archive],
      update: [UserDomain.Archive],
      delete: [UserDomain.Archive],
    },
  },
  {
    path: 'archive/tasks/:id',
    component: TaskComponent,
  },
];
