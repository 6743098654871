import { gql } from 'apollo-angular';

export const FileQuery = gql`
  query User($fileId: String!) {
    file(fileId: $fileId) {
      id
      file
      fileTitle
      fileDisplayTitle
    }
  }
`;
