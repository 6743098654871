import { DatePipe } from '@angular/common';
import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SelectItem } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import { TaskStore } from '../../store/stores/task.store';
import { TaskStatus } from '../../types/graphql';
import { Column } from '../../types/types/interfaces';

enum Mode {
  ArchivedOnly,
  UnarchivedOnly,
}

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TasksComponent implements OnInit, OnDestroy {
  readonly taskStatus = TaskStatus;
  readonly modeEnum = Mode;

  mode: Mode;
  viewsItems: SelectItem[];
  selectedViewsItem: SelectItem;
  columns: Column[] = [];

  tasks$: Observable<any[]>;
  openTasks$: Observable<TaskStore[]>;
  planningTasks$: Observable<TaskStore[]>;
  inProgressTasks$: Observable<TaskStore[]>;
  waitingForFeedbackTasks$: Observable<TaskStore[]>;
  waitingForConfirmationTasks$: Observable<TaskStore[]>;
  onHoldTasks$: Observable<TaskStore[]>;

  private clearFetchTasksInterval: () => void;
  private selectedViewsItemSubject = new Subject<SelectItem>();
  private componentDestroyedSubject = new Subject<void>();
  constructor(
    public root: RootStore,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private cd: ChangeDetectorRef
  ) {}

  private tasksByStatus(
    tasks$: Observable<TaskStore[]>
  ): (status: TaskStatus) => Observable<TaskStore[]> {
    return (status: TaskStatus) =>
      tasks$.pipe(
        map((tasks) => tasks.filter((task) => task.value.status === status))
      );
  }

  async ngOnInit(): Promise<void> {
    if (this.route.snapshot.url.length === 1) {
      this.mode = Mode.UnarchivedOnly;
    } else {
      this.mode = Mode.ArchivedOnly;
    }

    this.root.tasksStore.resetTasks();

    this.determineViewsItems();

    const tasksByArchiveState$ = this.root.tasksService.tasksByArchiveState(
      this.root.tasksViewStore.displayedTasks$,
      this.mode === Mode.ArchivedOnly
    );

    const unarchivedTasksByStatus$ = this.tasksByStatus(tasksByArchiveState$);

    this.openTasks$ = unarchivedTasksByStatus$(TaskStatus.Open);

    this.planningTasks$ = unarchivedTasksByStatus$(TaskStatus.Planning);

    this.inProgressTasks$ = unarchivedTasksByStatus$(TaskStatus.InProgress);

    this.waitingForFeedbackTasks$ = unarchivedTasksByStatus$(
      TaskStatus.WaitingForFeedback
    );

    this.waitingForConfirmationTasks$ = unarchivedTasksByStatus$(
      TaskStatus.WaitingForConfirmation
    );

    this.onHoldTasks$ = unarchivedTasksByStatus$(TaskStatus.OnHold);

    this.clearFetchTasksInterval = this.root.helperService.regularlyCall(() => {
      this.root.helperService.detectChanges(this.cd);
      this.root.tasksStore.fetchTasks();
    });

    this.root.localizationStore.selectedLanguage$
      .pipe(
        takeUntil(this.componentDestroyedSubject),
        filter((val) => !!val),
        tap((t) => {
          this.tasks$ = tasksByArchiveState$.pipe(
            map((taskStores) =>
              taskStores.map(({ value }) => ({
                ...value,
                dueDate: this.datePipe.transform(
                  value.dueDate,
                  'dd.MM.yyy',
                  '',
                  'de'
                ),
                assignedUser: {
                  ...value.assignedUser,
                  fullName:
                    value.assignedUser.firstName +
                    ' ' +
                    value.assignedUser.lastName,
                },
                category: {
                  name: t[value.category],
                  key: value.category,
                },
                status: t[value.status],
              }))
            )
          );

          this.columns = (() => {
            const names = [
              t.title,
              t.assignee,
              t.due_date,
              t.category,
              t.company,
              t.status,
            ];
            const fields = [
              'title',
              'assignedUser.fullName',
              'dueDate',
              'category',
              'assignedCompany.companyName',
              'status',
            ];
            return names.map((name, i) => ({ name, field: fields[i] }));
          })();
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.root.tasksViewStore.searchQuerySubject.next('');
    this.clearFetchTasksInterval();
    this.componentDestroyedSubject.next();
    this.componentDestroyedSubject.complete();
  }

  set handleSearch(text: string) {
    this.root.tasksViewStore.searchQuerySubject.next(text);
  }

  /* handleAutocompletionClick(value: string): void {
    let text = value;

    // TODO: Shortening string to less than the search result could lead to problems
    const indexOfDots = text.indexOf('...');
    if (indexOfDots === text.length - 3) {
      text = text.slice(0, indexOfDots);
    }

    this.root.tasksStore.tasks$.pipe(
      map((tasks) => tasks.map((task) => task.value)),
      map((tasks) => this.root.tasksService.filterTasksByText(tasks, text)),
      map((tasks) => tasks.map((task) => task.taskId)),
      tap((taskIds) => {
        if (!!taskIds.length) {
          this.handleSearch({ query: text });
        } else {
          this.router.navigateByUrl('tasks/' + taskIds[0]);
        }
      })
    );
  } */

  determineViewsItems(): void {
    this.viewsItems = [
      ...(this.mode === Mode.UnarchivedOnly
        ? [{ icon: 'pi pi-th-large', value: 'tile' }]
        : []),
      { icon: 'pi pi-bars', value: 'table' },
    ];

    this.determineSelectedViewsItem();
  }

  determineSelectedViewsItem(): void {
    this.selectedViewsItem = this.viewsItems[0];
    this.selectedViewsItemSubject.next(this.selectedViewsItem);
  }

  handleViewModeClick(selectedViewsItem: SelectItem): void {
    this.selectedViewsItemSubject.next(selectedViewsItem);
  }

  async unarchiveTask(task: any): Promise<void> {
    await this.root.tasksStore.archiveTask(task.id, false);
  }

  viewTask(task: any): void {
    task.archivedAt
      ? this.router.navigateByUrl('/archive/tasks/' + task.id)
      : this.router.navigateByUrl('/tasks/' + task.id);
  }
}
