import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { first } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';

import { RootStore } from '../../store/root.store';
import { User, UserInput } from '../../types/graphql';

@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss'],
})
export class MyProfileComponent implements OnInit {
  files: File[] = [];
  user: User;
  constructor(public root: RootStore, private messageService: MessageService) {}

  private renameFile(originalFile: File, newName: string): File {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  async ngOnInit(): Promise<void> {
    this.user = await this.root.authService.myself$.pipe(first()).toPromise();
  }

  async handleSubmit(userInput: Partial<UserInput>): Promise<void> {
    await this.root.usersStore.updateUser(this.user.id, userInput);

    this.reload();
  }

  handleClick(fileUpload: HTMLInputElement): void {
    fileUpload.click();
  }

  async handleFilesSelected(event: Event): Promise<void> {
    const t = await this.root.localizationStore.selectedLanguage();
    let img: File = (event.target as any).files[0];
    const maxSize = 100000;
    if (img.size > maxSize) {
      this.messageService.add({
        severity: 'error',
        summary: t.summary_filesize,
        detail: t.detail_max_filesize + ' ' + maxSize + ' kB',
      });

      return;
    }

    img = this.renameFile(
      img,
      this.user.firstName +
        '_' +
        this.user.lastName +
        '_Profile_' +
        uuid().split('-')[0]
    );

    const hasProfile = !!this.user?.profileImage?.profileImagePath;

    await this.root.usersStore.changeProfileImage(
      img,
      this.user.id,
      hasProfile
    );
    this.reload();
  }

  reload(): void {
    window.location.reload();
  }
}
