import { Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import * as l from 'lodash';
import { v4 as uuid } from 'uuid';

import { RootStore } from '../../../store/root.store';

@Component({
  selector: 'app-formly-file-upload',
  styleUrls: ['./formly-file-upload.component.scss'],
  template: `
    <div
      class="p-grid p-field"
      [ngClass]="to.wrapperClasses"
      *ngIf="
        root.localizationStore.selectedLanguage$ | async as selectedLanguage
      "
    >
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col-5 p-pb-0">
        <input
          type="file"
          style="display: none"
          [formlyAttributes]="field"
          (change)="handleFilesSelected($event)"
          #fileUpload
        />

        <p-button
          *ngIf="!to.disabled"
          (click)="handleClick(fileUpload)"
          label="+ {{ selectedLanguage.upload_attachments }}"
          styleClass="p-button-outlined"
        ></p-button>
      </div>
    </div>
  `,
})
export class FormlyFileUploadComponent
  extends FieldType
  implements OnInit, OnDestroy
{
  files: File[] = [];

  constructor(public root: RootStore) {
    super();
  }

  private filesChange(): void {
    this.formControl.setValue(this.files);

    if (this.to.change) {
      this.to.change(this.field, this.files);
    }

    if (this.to.handleChange) {
      this.to.handleChange.bind(this.to.self)();
    }
  }

  private renameFile(originalFile: File, newName: string): File {
    return new File([originalFile], newName, {
      type: originalFile.type,
      lastModified: originalFile.lastModified,
    });
  }

  ngOnInit(): void {
    this.files = this.to.defaultValue;
  }

  ngOnDestroy(): void {}

  handleFilesSelected(event: Event): void {
    let file: File = (event.target as any).files[0];

    const nameParts = file.name.split('.');
    const extension = nameParts.pop();
    const name = nameParts.join('.');

    file = this.renameFile(
      file,
      name + '__' + uuid().split('-')[0] + '.' + extension
    );

    this.files = l.uniqBy([...this.files, file], (el: File) => el.name);

    this.filesChange();
  }

  handleClick(fileUpload: HTMLInputElement): void {
    fileUpload.click();
  }

  handleDelete(index: number): void {
    this.files = this.files.filter((file, i) => i !== index);

    this.filesChange();
  }
}
