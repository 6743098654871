import { gql } from 'apollo-angular';

export const EditLocationMutation = gql`
  mutation EditLocation($location: LocationUpdate!, $locationId: String!) {
    editLocation(location: $location, locationId: $locationId) {
      __typename
      id
      name
      isActive
      streetName
      buildingNumber
      postcode
      city
      state
      country
      phoneNumber
      mobileNumber
      latitude
      longitude
      assignedCompany {
        id
        companyLogo
        companyName
        createdAt
        deletedAt
        updatedAt
      }
      users {
        id
        firstName
        lastName
      }
      errorMessage
    }
  }
`;
