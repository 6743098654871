import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { RootStore } from '../../store/root.store';
import { UserRole } from '../../types/graphql';
import { Column } from '../../types/types/interfaces';
import { IUser } from '../../types/types/interfaces/user.interface';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
})
export class UsersComponent implements OnInit {
  readonly activeField = 'isActiveUser';
  readonly userRole = UserRole;

  columns: Column[];

  set searchText(text: string) {
    this.root.usersViewStore.textQuery$.next(text);
  }

  constructor(public root: RootStore, private router: Router) {}

  async ngOnInit(): Promise<void> {
    await this.root.usersStore.fetchUsers();
    const t = await this.root.localizationStore.selectedLanguage();

    this.columns = (() => {
      const names = [t.name, t.email_address, t.company, t.country];
      const fields = [
        'fullName',
        'email',
        'assignedLocation.assignedCompany.companyName',
        'country',
      ];
      return names.map((name, i) => ({ name, field: fields[i] }));
    })();
  }

  navigateToUser(user: IUser): void {
    this.router.navigateByUrl('settings/users/' + user.id);
  }

  async toggleLockForUser(user: IUser): Promise<void> {
    // Quickfix
    /// profileImage is not part of EditUserMutation
    delete user.profileImage;

    await this.root.usersStore.updateUser(
      user.id,
      this.root.usersService.userInputFromIUser({
        ...user,
        isActiveUser: !user.isActiveUser,
      })
    );
  }
}
