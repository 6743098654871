import { FormGroup } from '@angular/forms';
import { BehaviorSubject, iif, Observable, of, Subject } from 'rxjs';
import {
  delay,
  distinctUntilChanged,
  first,
  map,
  mergeMap,
  shareReplay,
  takeUntil,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

export class ApplicationStore {
  blockingMutationInProgress$: Observable<boolean>;

  loadingMessages$: Observable<string[]>;
  displayLoadingSpinner$: Observable<boolean>;

  unsavedChanges$: Observable<boolean>;

  private unsavedChangesSubject = new BehaviorSubject(false);

  private loadingMessagesSubject = new BehaviorSubject<string[]>([]);

  private blockingMutationsSubject = new BehaviorSubject<number>(0);

  constructor() {
    this.unsavedChanges$ = this.unsavedChangesSubject.asObservable();

    this.blockingMutationInProgress$ = this.blockingMutationsSubject
      .asObservable()
      .pipe(map((val) => !!val));

    this.loadingMessages$ = this.loadingMessagesSubject
      .asObservable()
      .pipe(shareReplay());

    const areThereLoadingMessages$ = this.loadingMessages$.pipe(
      map((messages) => !!messages?.length),
      distinctUntilChanged()
    );

    const delayUntilShown = 3000;

    this.displayLoadingSpinner$ = areThereLoadingMessages$.pipe(
      mergeMap((val) =>
        iif(() => val, of(true).pipe(delay(delayUntilShown)), of(false))
      ),
      withLatestFrom(areThereLoadingMessages$),
      map(([a, b]) => (!b ? b : a)),
      distinctUntilChanged()
    );
  }

  changesSaved(): void {
    this.unsavedChangesSubject.next(false);
  }

  addLoadingMessage(message: string, mutative = false): void {
    if (mutative) {
      this.blockingMutationsSubject.next(
        this.blockingMutationsSubject.value + 1
      );
    }

    this.loadingMessagesSubject.next([
      ...this.loadingMessagesSubject.value,
      message,
    ]);
  }

  removeLoadingMessage(message: string, mutative = false): void {
    if (mutative) {
      this.blockingMutationsSubject.next(
        this.blockingMutationsSubject.value - 1
      );
    }

    const loadingMessages = this.loadingMessagesSubject.value;
    loadingMessages.splice(loadingMessages.indexOf(message));

    this.loadingMessagesSubject.next(loadingMessages);
  }

  warnOfUnsavedChanges(
    form: FormGroup,
    componentDestroyedSubject: Subject<void>
  ): void {
    form.valueChanges
      .pipe(
        takeUntil(componentDestroyedSubject),
        tap(() => {
          this.unsavedChangesSubject.next(form.dirty);
        })
      )
      .subscribe();

    componentDestroyedSubject
      .pipe(
        first(),
        tap(() => this.unsavedChangesSubject.next(false))
      )
      .subscribe();
  }
}
