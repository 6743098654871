import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  CanActivateChild,
  Router,
  UrlTree,
} from '@angular/router';

import { RootStore } from '../store/root.store';
import { IPermission } from '../types/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class PermissionGuard implements CanActivate, CanActivateChild {
  constructor(
    public route: ActivatedRoute,
    public router: Router,
    public root: RootStore
  ) {}

  private async guard(
    next: ActivatedRouteSnapshot
  ): Promise<boolean | UrlTree> {
    return (
      (await this.root.authService.hasPermission(
        next.data as Partial<IPermission>
      )) || this.router.createUrlTree([''])
    );
  }

  async canActivate(next: ActivatedRouteSnapshot): Promise<boolean | UrlTree> {
    return this.guard(next);
  }

  async canActivateChild(
    next: ActivatedRouteSnapshot
  ): Promise<boolean | UrlTree> {
    return this.guard(next);
  }
}
