import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootStore } from '../store/root.store';
import { IAuth0Profile } from '../types/types/interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(public router: Router, public root: RootStore) {}

  canActivate(): Observable<true | UrlTree> {
    return this.root.authService.profile$.pipe(
      map((profile: IAuth0Profile) =>
        profile ? true : this.router.createUrlTree(['login'])
      )
    );
  }
}
