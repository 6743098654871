import { gql } from 'apollo-angular';

export const CreateFileMutation = gql`
  mutation CreateFile($file: FileInput!) {
    createFile(file: $file) {
      id
      file
      fileTitle
      fileDescription
      fileType
    }
  }
`;
