
    <div class="p-grid p-field p-mb-3" [ngClass]="to.wrapperClasses">
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col p-pb-0">
        <p-editor
          [readonly]="to.disabled"
          [ngModel]="to.defaultValue"
          (onTextChange)="handleTextChange()"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
          [style]="{ height: '320px' }"
        >
          <p-header>
            <span class="ql-formats">
              <span class="ql-header ql-picker">
                <select class="ql-header">
                  <option value="1">Heading</option>
                  <option value="2">Subheading</option>
                  <option selected="">Normal</option>
                </select>
              </span>
            </span>
            <span class="ql-formats">
              <button class="ql-bold" aria-label="Bold"></button>
              <button class="ql-italic" aria-label="Italic"></button>
              <button class="ql-underline" aria-label="Underline"></button>
            </span>
            <span class="ql-formats">
              <select class="ql-color">
                <option selected="selected"></option>
                <option value="#000000"></option>
                <option value="#4D927D"></option>
                <option value="#4D7F92"></option>
                <option value="#E5BC5B"></option>
                <option value="#6C568F"></option>
                <option value="#924D6D"></option>
              </select>
              <select class="ql-background">
                <option selected="selected"></option>
                <option value="#000000"></option>
                <option value="#4D927D"></option>
                <option value="#4D7F92"></option>
                <option value="#E5BC5B"></option>
                <option value="#6C568F"></option>
                <option value="#924D6D"></option>
              </select>
            </span>
            <span class="ql-formats">
              <button
                class="ql-list"
                aria-label="Ordered List"
                value="ordered"
              ></button>
              <button
                class="ql-list"
                aria-label="Unordered List"
                value="bullet"
              ></button>
              <select class="ql-align">
                <option selected=""></option>
                <option value="center"></option>
                <option value="right"></option>
                <option value="justify"></option>
              </select>
            </span>
            <span class="ql-formats">
              <button class="ql-link" aria-label="Insert Link"></button>
            </span>
          </p-header>
        </p-editor>
      </div>
    </div>
  