import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { FormlyFieldConfig } from '@ngx-formly/core';
import * as l from 'lodash';
import { MessageService } from 'primeng/api';
import { combineLatest, Observable, Subject } from 'rxjs';
import { filter, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import {
  Location,
  Task,
  TaskCategory,
  TaskStatus,
  User,
} from '../../types/graphql';
import { UserAction } from '../../types/types/enums';
import {
  IDropdownOption,
  IExtendedTaskInput,
} from '../../types/types/interfaces';
import { IUser } from '../../types/types/interfaces/user.interface';

// TODO: refactor interface stuff
// This being exported is an exception for now
export interface IModel extends IExtendedTaskInput {
  followerIds: string[];
}

@Component({
  selector: 'app-task-form',
  templateUrl: './task-form.component.html',
  styleUrls: ['./task-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskFormComponent implements OnInit, OnDestroy {
  @Input() task: Task;
  @Input() hasSubmit = true;

  @Output() submitForm = new EventEmitter<IModel>();

  form = new FormGroup({});

  model: IModel = {
    dueDate: new Date(),
    description: null,
    assignedUserId: null,
    assignedLocationId: null,
    title: null,
    isHighPriority: false,
    category: null,
    status: null,
    files: [],
    followerIds: [],
  };

  fields: FormlyFieldConfig[] = [];

  locationOptions$: Observable<Location[]>;
  userOptions$: Observable<IDropdownOption[]>;

  private componentDestroyedSubject = new Subject<void>();

  constructor(
    private root: RootStore,
    private messageService: MessageService,
    private cd: ChangeDetectorRef
  ) {}

  private dropdownOptionFromLocation(location: Location): IDropdownOption {
    return {
      label: location.assignedCompany.companyName + ' (' + location.name + ')',
      value: location.id,
    };
  }

  private async setFormValues({
    locationOptions,
    userOptions,
  }: {
    locationOptions: IDropdownOption[];
    userOptions: IDropdownOption[];
  }): Promise<void> {
    const task = this.task;

    if (!task) {
      this.model.files = null;
      this.model.followerIds = [
        (await this.root.authService.myself$.pipe(first()).toPromise()).id,
      ];
    } else if (!locationOptions) {
      locationOptions = [task.assignedLocation].map((location) => ({
        label: location.assignedCompany.companyName + ' - ' + location.name,
        value: location.id,
      }));
    }

    const t = await this.root.localizationStore.selectedLanguage();

    const categoryOptions =
      await this.root.helperService.dropdownOptionsFromEnum(TaskCategory);
    const statusOptions = await this.root.helperService.dropdownOptionsFromEnum(
      TaskStatus
    );

    const defaultTitle = task?.title;

    const defaultCategory: TaskCategory =
      task?.category || (categoryOptions[0]?.value as TaskCategory);

    const defaultDueDate = task?.dueDate ? new Date(task.dueDate) : new Date();

    const defaultIsHighPriority = !!task?.isHighPriority;

    const defaultAssignedUserId =
      task?.assignedUser?.id ||
      (await this.root.authService.myself$.pipe(first()).toPromise()).id;

    const defaultStatus =
      task?.status || (statusOptions[0]?.value as TaskStatus);

    const defaultDescription = task?.description || '';

    const defaultAssignedLocationId =
      task?.assignedLocation?.id ||
      (await this.root.authService.myself$.pipe(first()).toPromise())
        .assignedLocation.id;

    const defaultFollowerIds =
      task?.followers?.map((follower) => follower.id) || [];

    this.fields = [
      {
        key: 'title',
        type: 'input',
        templateOptions: {
          self: this,
          label: t.title,
          defaultValue: defaultTitle,
          required: true,
        },
      },
      {
        key: 'category',
        type: 'select',
        templateOptions: {
          self: this,
          displayAsTags: true,
          label: t.category,
          defaultValue: defaultCategory,
          multiple: false,
          options: categoryOptions,
        },
      },
      {
        key: 'dueDate',
        type: 'date-picker',
        templateOptions: {
          self: this,
          label: t.due_date,
          dateFormat: 'dd.mm.yy',
          defaultValue: defaultDueDate,
          showTime: false,
        },
      },
      {
        key: 'isHighPriority',
        type: 'checkbox',
        templateOptions: {
          self: this,
          label: t.priority,
          defaultValue: defaultIsHighPriority,
        },
      },
      {
        key: 'status',
        type: 'select',
        templateOptions: {
          self: this,
          label: t.status,
          defaultValue: defaultStatus,
          multiple: false,
          options: statusOptions,
        },
      },
      {
        key: 'description',
        type: 'text-editor',
        templateOptions: {
          self: this,
          label: t.description,
          defaultValue: defaultDescription,
        },
      },
      {
        key: 'assignedLocationId',
        type: 'select',
        templateOptions: {
          self: this,
          label: t.company,
          defaultValue: defaultAssignedLocationId,
          multiple: false,
          filter: true,
          options: locationOptions,
        },
      },
      {
        key: 'assignedUserId',
        type: 'select',
        templateOptions: {
          self: this,
          label: t.assignee,
          defaultValue: defaultAssignedUserId,
          multiple: false,
          filter: true,
          options: userOptions,
        },
      },
      ...(task
        ? [
            {
              key: 'followerIds',
              type: 'select',
              templateOptions: {
                self: this,
                label: t.following,
                multiple: true,
                options: userOptions,
                defaultValue: defaultFollowerIds,
              },
            },
            {
              key: 'files',
              type: 'file-upload',
              templateOptions: {
                self: this,
                label: t.attachments,
                defaultValue:
                  task.files?.map((file) => ({
                    id: file.id,
                    name: file.fileTitle,
                  })) || [],
              },
            },
          ]
        : []),
    ].map((field) => ({
      ...field,
      templateOptions: {
        ...field.templateOptions,
        disabled: !!(
          task?.archivedAt || (field.templateOptions as any).disabled
        ),
        /**
         * @description should be called whenever a graphql mutation is warranted
         */
        handleChange: this.handleChange.bind(this),
        /**
         * @description should be called every time the input changes
         * unless there is no difference to handleChange
         */
        handleInput: this.handleInput.bind(this),
      },
    }));

    this.root.helperService.detectChanges(this.cd);

    setTimeout(() => {
      this.form?.patchValue({
        dueDate: defaultDueDate,
      });

      this.root.helperService.detectChanges(this.cd);
    }, 100);
  }

  async ngOnInit(): Promise<void> {
    await this.root.companiesStore.fetchLocations();
    await this.root.usersStore.fetchUsers();

    const auth = this.root.authService;

    const permFn = async (myself: IUser): Promise<[boolean, IUser]> => [
      await auth.hasPermission({ do: [UserAction.SelectAllLocations] }, myself),
      myself,
    ];

    this.locationOptions$ = auth.myself$.pipe(
      switchMap((myself) => permFn(myself)),
      switchMap(([canSelectAllLocations, myself]: [boolean, IUser]) => {
        let result: Observable<Location[]> =
          this.root.companiesStore.locations$;

        if (!canSelectAllLocations) {
          result = result.pipe(
            map((locations) =>
              locations.filter(
                (location: Location) =>
                  location.isActive &&
                  (location.assignedCompany.id === '1' ||
                    location.assignedCompany.id ===
                      myself.assignedLocation.assignedCompany.id ||
                    location.id === this.task?.assignedLocation?.id)
              )
            )
          );
        } else {
          result = result.pipe(
            map((locations) =>
              locations.filter((location: Location) => location.isActive)
            )
          );
        }

        return result.pipe(
          map((locations) =>
            locations.filter((location) => !!location?.users?.length)
          )
        );
      }),
      filter((locations) => !!locations?.length)
    );

    this.userOptions$ = this.root.usersStore.users$.pipe(
      map((users) =>
        users
          .map((user) => user.value)
          .map((user) => ({
            label: user.firstName + ' ' + user.lastName,
            value: user.id,
          }))
      )
    );

    combineLatest([this.locationOptions$, this.userOptions$])
      .pipe(
        takeUntil(this.componentDestroyedSubject),
        tap(
          async ([locationOptions, userOptions]: [
            Location[],
            IDropdownOption[]
          ]) => {
            let assigneeUsers: User[] = [
              ...locationOptions.flatMap((location) => location.users),
              ...(this.task ? this.task.followers : []),
            ];

            assigneeUsers = l.uniqBy(assigneeUsers, (user) => user.id);

            userOptions = assigneeUsers.map((user) => ({
              label: user.firstName + ' ' + user.lastName,
              value: user.id,
            }));

            await this.setFormValues({
              locationOptions: locationOptions.map((location) =>
                this.dropdownOptionFromLocation(location)
              ),
              userOptions,
            });
          }
        )
      )
      .subscribe();

    if (this.hasSubmit) {
      this.root.applicationStore.warnOfUnsavedChanges(
        this.form,
        this.componentDestroyedSubject
      );
    }
  }

  ngOnDestroy(): void {
    this.componentDestroyedSubject.next();
    this.componentDestroyedSubject.complete();
  }

  async handleChange(): Promise<void> {
    if (!this.hasSubmit) {
      this.handleInput();

      if (this.form?.valid) {
        this.submitForm.next(this.model);
      } else {
        this.messageService.add({
          severity: 'warn',
          summary: (await this.root.localizationStore.selectedLanguage())
            ?.summary_invalid_form_value,
        });
      }
      this.root.helperService.detectChanges(this.cd);
    }
  }

  handleInput(): void {
    this.root.helperService.detectChanges(this.cd);
  }

  /***
   * @description This function is only called if the submit button is clicked
   */
  handleSubmit(): void {
    this.submitForm.next(this.model);
  }
}
