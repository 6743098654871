import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { MessageService } from 'primeng/api';

import { AuthService } from './services/auth.service';
import { DateService } from './services/date.service';
import { GraphqlService } from './services/graphql.service';
import { HelperService } from './services/helper.service';
import { TasksService } from './services/tasks.service';
import { UsersService } from './services/users.service';
import { ApplicationStore } from './stores/application.store';
import { CompaniesStore } from './stores/companies.store';
import { LocalizationStore } from './stores/localization.store';
import { TasksStore } from './stores/tasks.store';
import { UsersStore } from './stores/users.store';
import { TasksViewStore } from './view-stores/tasks.view.store';
import { UsersViewStore } from './view-stores/users.view.store';

/**
 * @description The Root is the only injectable store (service) and it stores all the other stores
 */
@Injectable({
  providedIn: 'root',
})
export class RootStore {
  helperService: HelperService = new HelperService();
  localizationStore: LocalizationStore = new LocalizationStore();

  usersService: UsersService = new UsersService(this.helperService);
  authService: AuthService = new AuthService(
    this.router,
    this.usersService,
    this.helperService
  );
  dateService: DateService = new DateService();
  graphqlService: GraphqlService = new GraphqlService(
    this.apollo,
    this.messageService,
    this.router
  );
  tasksService: TasksService = new TasksService();

  applicationStore: ApplicationStore = new ApplicationStore();
  tasksStore: TasksStore = new TasksStore(
    this.messageService,
    this.helperService,
    this.tasksService
  );
  usersStore: UsersStore = new UsersStore(
    this.helperService,
    this.messageService
  );
  companiesStore: CompaniesStore = new CompaniesStore(
    this.helperService,
    this.messageService
  );

  tasksViewStore: TasksViewStore = new TasksViewStore();
  usersViewStore: UsersViewStore = new UsersViewStore();
  constructor(
    private apollo: Apollo,
    private messageService: MessageService,
    private router: Router
  ) {
    this.helperService.construct(this.localizationStore);
    this.localizationStore.construct(
      this.helperService,
      this.graphqlService,
      this.messageService
    );
    this.authService.construct(this.graphqlService);
    this.graphqlService.construct(
      this.authService,
      this.localizationStore,
      this.applicationStore
    );
    this.tasksService.construct(this.helperService, this.dateService);
    // this.usersService
    // this.applicationStore
    this.tasksStore.construct(this.localizationStore, this.graphqlService);
    this.usersStore.construct(
      this.usersService,
      this.graphqlService,
      this.localizationStore
    );
    this.companiesStore.construct(this.graphqlService, this.localizationStore);
    this.tasksViewStore.construct(
      this.usersStore,
      this.tasksService,
      this.companiesStore,
      this.tasksStore,
      this.authService
    );
    this.usersViewStore.construct(this.usersStore, this.usersService);

    this.localizationStore.initialize();
    this.tasksViewStore.initialize();
  }
}
