import { Component, Input, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';

import { RootStore } from '../../store/root.store';
import { Column } from '../../types/types/interfaces';

@Component({
  selector: 'app-primeng-table',
  templateUrl: './primeng-table.component.html',
  styleUrls: ['./primeng-table.component.scss'],
})
export class PrimengTableComponent<T> implements OnInit {
  @Input() editFn: (value: T) => void;
  @Input() viewFn: (value: T) => void;
  @Input() deleteFn: (value: T) => void;
  @Input() lockFn: (value: T) => void;
  @Input() unarchiveFn: (value: T) => void;
  @Input() activeField: string;
  @Input() paginator: boolean;
  @Input() rowCount: number;

  @Input() rows: T[];

  @Input() columns: Column[];

  language = this.root.localizationStore.selectedLanguage();

  constructor(
    public root: RootStore,
    private confirmationService: ConfirmationService
  ) {}

  ngOnInit(): void {}

  async handleLock(event: Event, value: T): Promise<void> {
    const t = await this.root.localizationStore.selectedLanguage();

    this.confirmationService.confirm({
      message: (value as any).isActiveUser
        ? t.deactivate_user_message
        : t.activate_user_message,
      acceptLabel: t.yes,
      rejectLabel: t.no,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.lockFn(value);
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }

  async handleUnarchive(event: Event, value: T): Promise<void> {
    const t = await this.root.localizationStore.selectedLanguage();

    this.confirmationService.confirm({
      message: t.unarchive_task,
      acceptLabel: t.yes,
      rejectLabel: t.no,
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.unarchiveFn(value);
        this.confirmationService.close();
      },
      reject: () => {
        this.confirmationService.close();
      },
    });
  }
}
