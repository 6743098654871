import { gql } from 'apollo-angular';

export const CompaniesQuery = gql`
  {
    companies {
      __typename
      id
      companyLogo
      companyName
      createdAt
      deletedAt
      updatedAt
    }
  }
`;
