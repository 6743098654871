import { gql } from 'apollo-angular';

export const CreateUserMutation = gql`
  mutation CreateUser($user: UserInput!) {
    createUser(user: $user) {
      __typename
      birthday
      createdAt
      deletedAt
      email
      emailVerifiedAt
      firstName
      id
      lastName
      mobile
      salutation
      positionInCompany
      telephone
      updatedAt
      role
      assignedLanguage {
        id
        language_name
        language_code
      }
      assignedLocation {
        id
        name
        assignedCompany {
          id
          companyName
        }
      }
      country
      isActiveUser
    }
  }
`;
