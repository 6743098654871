<div
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <div
    class="p-grid"
    *ngIf="root.authService.myself$ | async as myself; else skeleton"
  >
    <div class="p-col-6">
      <h2 class="p-mb-6">{{ selectedLanguage.edit_profile_message }}</h2>
      <div class="p-fluid">
        <app-user-form
          [isSaveable]="true"
          [user]="myself"
          (submitForm)="handleSubmit($event)"
        >
        </app-user-form>
      </div>
    </div>
    <div class="p-col-5 p-d-flex p-ai-center" style="flex-direction: column">
      <p-avatar
        *ngIf="myself?.profileImage?.profileImagePath; else icon"
        [image]="myself?.profileImage?.profileImagePath"
        styleClass="bigger-profile p-mt-6"
        size="xlarge"
        shape="circle"
      ></p-avatar>

      <ng-template #icon>
        <p-avatar
          icon="pi pi-user"
          styleClass="bigger-profile p-mt-6"
          size="xlarge"
          shape="circle"
        ></p-avatar>
      </ng-template>
      <input
        type="file"
        accept="image/*"
        style="display: none"
        (change)="handleFilesSelected($event)"
        #fileUpload
      />

      <p-button
        (click)="handleClick(fileUpload)"
        label="+ {{ selectedLanguage.upload_attachments }}"
        styleClass="p-button-outlined p-mt-6"
      ></p-button>
    </div>
  </div>

  <ng-template #skeleton>
    <div class="p-col-6">
      <div class="p-fluid">
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3 p-mt-6" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3 p-mt-6" height="50px"></p-skeleton>
        <p-skeleton styleClass="p-mb-3" height="50px"></p-skeleton>
      </div>
    </div>
  </ng-template>
</div>
