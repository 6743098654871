import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route } from '@angular/router';
import * as l from 'lodash';
import { Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import { IModel } from '../../form-components/company-form/company-form.component';
import { RootStore } from '../../store/root.store';
import { CompanyStore } from '../../store/stores/company.store';
import { Company } from '../../types/graphql';

enum LocationField {
  Name = 'name',
  Country = 'country',
  IsActive = 'isActive',
  Id = 'id',
  City = 'city',
}

interface ISimpleLocation {
  id: string;
  name: string;
  country: string;
  isActive: boolean;
  city: string;
}

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss'],
})
export class CompanyComponent implements OnInit {
  company$: Observable<Company>;

  private readonly companyId: string;

  constructor(public root: RootStore, private route: ActivatedRoute) {
    this.companyId = this.route.snapshot.paramMap.get('id');
  }

  private locationsFromLocationTuples(
    locationTuples: [LocationField, string | boolean][]
  ): ISimpleLocation[] {
    const result: ISimpleLocation[] = [];

    let foundSomething = true;
    let i = -1;

    while (foundSomething) {
      i++;

      const objEntries = locationTuples.filter(
        ([k, _]) => parseInt(k.split('_')[1], 10) === i
      );

      if (!!objEntries.length) {
        const obj: any = {};

        objEntries.forEach(([k, v]) => {
          let key = k.toLowerCase();

          if (key.includes(LocationField.IsActive.toLowerCase())) {
            key = LocationField.IsActive;
          } else if (key.includes(LocationField.Country.toLowerCase())) {
            key = LocationField.Country;
          } else if (key.includes(LocationField.Name.toLowerCase())) {
            key = LocationField.Name;
          } else if (key.includes(LocationField.City.toLowerCase())) {
            key = LocationField.City;
          } else {
            key = LocationField.Id;
          }

          obj[key] = v;
        });

        result.push(obj);
      } else {
        foundSomething = false;
      }
    }

    return result;
  }

  ngOnInit(): void {
    this.company$ = this.root.companiesStore.getCompany(this.companyId).pipe(
      filter((company) => !!company?.value?.locations?.length),
      map((company) => company.value)
    );

    this.root.companiesStore.fetchCompany(this.companyId);
  }

  async handleSubmit(model: IModel): Promise<void> {
    const resourceLocations = (
      (await this.root.companiesStore
        .getCompany(this.companyId)
        .pipe(first())
        .toPromise()) as CompanyStore
    ).value.locations;

    const locationTuples = Object.entries(model) as [[LocationField, string]];
    // Add new locations

    const locationTuplesToAdd = locationTuples.filter(([k, _]) =>
      k.includes('new')
    );

    const locationsToAdd: Partial<ISimpleLocation>[] =
      this.locationsFromLocationTuples(locationTuplesToAdd).map((location) =>
        l.omit(location, 'id')
      );

    for (const location of locationsToAdd) {
      await this.root.companiesStore.createLocation(location, this.companyId);
    }

    // Mutate locations

    const locationTuplesToMutate = locationTuples.filter(
      ([k, _]) => !k.includes('new')
    );

    const locationsToMutate: {
      name: string;
      country: string;
      city: string;
      isActive: boolean;
      id: string;
    }[] = this.locationsFromLocationTuples(locationTuplesToMutate).filter(
      (location, index) =>
        location.country !== resourceLocations[index].country ||
        location.name !== resourceLocations[index].name ||
        location.isActive !== resourceLocations[index].isActive ||
        location.city !== resourceLocations[index].city
    );

    for (const location of locationsToMutate) {
      await this.root.companiesStore.editLocation(
        l.omit(location, ['id']),
        location.id
      );
    }

    this.root.companiesStore.updateCompany(this.companyId, {
      companyName: model.companyName,
    });
  }
}
