import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { first } from 'rxjs/operators';

import { RootStore } from '../store/root.store';

@Injectable()
export class PendingChangesGuard implements CanDeactivate<any> {
  constructor(private root: RootStore) {}

  async canDeactivate(): Promise<boolean> {
    if (
      await this.root.applicationStore.unsavedChanges$.pipe(first()).toPromise()
    ) {
      return confirm(
        (await this.root.localizationStore.selectedLanguage())
          .warning_unsaved_changes
      );

      // result = new Observable((observer: Observer<boolean>) => {
      //   this.confirmationService.confirm({
      //     message:
      //       'You have unsaved changes. Are you sure you want to leave this page?',
      //     accept: () => {
      //       observer.next(true);
      //       observer.complete();
      //     },
      //     reject: () => {
      //       observer.next(false);
      //       observer.complete();
      //     },
      //   });
      // });
    } else {
      return true;
    }
  }
}
