import { IUser } from '../../types/types/interfaces/user.interface';

/**
 * @description This class has to be instantiated by an instance of the UsersStore ("parent")!
 */
export class UserStore {
  private backup: IUser;
  constructor(public value: IUser) {
    this.backup = { ...value };
  }

  reset(): void {
    this.value = { ...this.backup };
  }
}
