import { gql } from 'apollo-angular';

export const UserQuery = gql`
  query User($userId: String!) {
    user(userId: $userId) {
      __typename
      birthday
      createdAt
      deletedAt
      email
      emailVerifiedAt
      firstName
      id
      lastName
      mobile
      salutation
      positionInCompany
      telephone
      updatedAt
      role
      assignedLanguage {
        id
        language_name
        language_code
      }
      assignedLocation {
        id
        name
        assignedCompany {
          id
          companyName
        }
      }
      country
      isActiveUser
      profileImage {
        profileImagePath
      }
    }
  }
`;
