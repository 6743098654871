import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'localize',
})
export class LocalizePipe implements PipeTransform {
  // TODO: Make this pipe insert passed arguments into $$nonce$$ (string interpolation)
  transform(value: string, ...args: string[]): unknown {
    return value;
  }
}
