import {
  Directive,
  Input,
  OnDestroy,
  TemplateRef,
  ViewContainerRef,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil, tap } from 'rxjs/operators';

import { RootStore } from '../store/root.store';
import { IPermission } from '../types/types/interfaces';
import { IUser } from '../types/types/interfaces/user.interface';

@Directive({
  selector: '[appHasPermissions]',
})
export class HasPermissionsDirective implements OnDestroy {
  private permission: Partial<IPermission>;
  private directiveDestroyedSubject = new Subject();

  constructor(
    private root: RootStore,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {
    this.root.authService.myself$
      .pipe(
        takeUntil(this.directiveDestroyedSubject),
        tap(async (user: IUser) => {
          if (
            await this.root.authService.hasPermission(this.permission, user)
          ) {
            this.viewContainer.createEmbeddedView(this.templateRef);
          } else {
            this.viewContainer.clear();
          }
        })
      )
      .subscribe();
  }

  @Input()
  set appHasPermissions(permission: Partial<IPermission>) {
    if (!permission) {
      throw new Error('Permission value has to be truthy');
    }

    this.permission = permission;
  }

  ngOnDestroy(): void {
    this.directiveDestroyedSubject.next();
    this.directiveDestroyedSubject.complete();
  }
}
