import { Routes } from '@angular/router';

import { PendingChangesGuard } from '../guards/pending-changes.guard';
import { CreateUserComponent } from '../pages/create-user/create-user.component';
import { UserComponent } from '../pages/user/user.component';
import { UsersComponent } from '../pages/users/users.component';

export const userRoutes: Routes = [
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'users/create',
    component: CreateUserComponent,
    canDeactivate: [PendingChangesGuard],
  },
  {
    path: 'users/:id',
    component: UserComponent,
    canDeactivate: [PendingChangesGuard],
  },
];
