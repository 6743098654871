<div
  id="scaffold"
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <p-menubar [style]="{ background: '#fff', height: '7.5vh' }">
    <ng-template pTemplate="start">
      <div class="p-grid p-ai-center vertical-container p-pr-6">
        <div class="p-col">
          <p-button
            type="text"
            (onClick)="toggleSidebar()"
            icon="pi pi-bars"
          ></p-button>
        </div>
        <div class="p-col">
          <a routerLink="/tasks"
            ><img src="assets/img/Forstsys_Logo.svg" height="30"
          /></a>
        </div>
      </div>
    </ng-template>

    <ng-template pTemplate="end">
      <div class="p-grid p-ai-center vertical-container p-pr-5">
        <div class="p-col date-wrapper">
          {{ currentDate | date: 'EE dd.MM.yyy':'':'de' }}
        </div>
        <div class="p-col">
          <p-avatar
            *ngIf="myself?.profileImage?.profileImagePath; else avataricon"
            [image]="myself?.profileImage?.profileImagePath"
            styleClass="rounded-profile"
            shape="circle"
            (click)="menuTop.toggle($event)"
          ></p-avatar>
          <ng-template #avataricon>
            <button
              pButton
              type="button"
              icon="pi pi-user"
              class="p-button-rounded p-button-primary"
              (click)="menuTop.toggle($event)"
            ></button>
          </ng-template>
        </div>
      </div>
      <p-menu #menuTop [popup]="true" [model]="itemsTop"> </p-menu>
    </ng-template>
  </p-menubar>
  <div class="p-grid nested-grid" style="height: calc(92.5vh + 0.5rem)">
    <div
      id="sidebar_col"
      [ngClass]="{ open: showOpen }"
      class="p-col-fixed left-navigation"
    >
      <div class="p-col" style="height: 12.5vh"></div>
      <div class="p-col navigation" [ngClass]="{ open: showOpen }">
        <ul class="sidebar-menu">
          <!-- Task -->
          <li class="sidebar-item">
            <a routerLink="/tasks" routerLinkActive="active">
              <p-avatar
                label="{{ selectedLanguage.tasks[0] | uppercase }}"
                size="large"
                [style]="{ backgroundColor: '#4D927D', color: '#ffffff' }"
                class="p-pr-2"
              ></p-avatar>
              <div class="sidebar-item-text">
                {{ selectedLanguage.tasks }}
              </div>
            </a>
          </li>
          <ul class="sidebar-submenu">
            <li
              class="sidebar-item"
              *appHasPermissions="{ read: [userDomain.Archive] }"
            >
              <a routerLink="/archive/tasks" routerLinkActive="active">
                <div class="sidebar-item-text">
                  {{ selectedLanguage.archive }}
                </div>
              </a>
            </li>
          </ul>

          <!-- Settings -->
          <li
            *appHasPermissions="{ read: [userDomain.Settings] }"
            class="sidebar-item"
          >
            <a routerLink="/settings" routerLinkActive="active">
              <p-avatar
                label="{{ selectedLanguage.settings[0] | uppercase }}"
                size="large"
                [style]="{ backgroundColor: '#4D7F92', color: '#ffffff' }"
                class="p-pr-2"
              ></p-avatar>
              <div class="sidebar-item-text">
                {{ selectedLanguage.settings }}
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <div class="p-col">
      <div class="p-grid">
        <div
          class="p-col-12 p-pl-4 p-pt-2 desk-navigation"
          style="height: 12.5vh"
        >
          <app-desk-navigation></app-desk-navigation>
        </div>
        <div class="p-col-12 p-px-6 content">
          <div class="box content-wrap p-py-6" style="height: 79vh">
            <router-outlet></router-outlet>
          </div>
        </div>
      </div>
    </div>
  </div>
  <p-confirmDialog
    header="{{ selectedLanguage.confirmation }}"
    icon="pi pi-exclamation-triangle"
    rejectButtonStyleClass="p-button-danger"
  ></p-confirmDialog>
</div>
