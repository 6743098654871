import { gql } from 'apollo-angular';

export const EditUserMutation = gql`
  mutation EditUser($user: UserUpdate!, $userId: String!) {
    editUser(user: $user, userId: $userId) {
      __typename
      createdAt
      deletedAt
      email
      firstName
      id
      lastName
      mobile
      positionInCompany
      telephone
      updatedAt
      role
      assignedLanguage {
        id
        language_name
        language_code
      }
      assignedLocation {
        id
        name
        assignedCompany {
          id
          companyName
        }
      }
      country
      isActiveUser
    }
  }
`;
