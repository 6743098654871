import { gql } from 'apollo-angular';

export const DeleteProfileImageMutation = gql`
  mutation DeleteProfileImage($userId: String!) {
    deleteProfileImage(userId: $userId) {
      user {
        id
      }
    }
  }
`;
