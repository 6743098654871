import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-translate-language',
  templateUrl: './translate-language.component.html',
  styleUrls: ['./translate-language.component.scss'],
})
export class TranslateLanguageComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
