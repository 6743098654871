<ng-container *ngIf="modeSubject | async as mode">
  <ng-container
    *ngIf="
      root.localizationStore?.selectedLanguage$ | async as selectedLanguage
    "
  >
    <div
      *ngIf="
        mode === deskNavigationModeEnum.Tasks ||
        mode === deskNavigationModeEnum.ArchivedTasks
      "
    >
      <h1 *ngIf="mode === deskNavigationModeEnum.Tasks; else archivedHeader">
        {{ selectedLanguage.tasks }}
      </h1>
      <ng-template #archivedHeader>
        <h1>{{ selectedLanguage.archived_tasks }}</h1>
      </ng-template>

      <div class="tasks-filter">
        <p-tabMenu
          [model]="tasksMenuItems"
          [activeItem]="tasksMenuActiveItem"
        ></p-tabMenu>

        <button
          pButton
          type="button"
          icon="pi pi-filter"
          class="p-button-rounded p-button-text p-button-lg"
          (click)="op.toggle($event)"
        ></button>

        <p-overlayPanel #op>
          <ng-template pTemplate>
            {{ selectedLanguage.sort_tasks_by }}:
            <div class="filter-radio-buttons" *ngFor="let value of sortByItems">
              <p-radioButton
                name="sortBy"
                [value]="value"
                [(ngModel)]="sortByItemActive"
                [label]="value.label"
                (onClick)="handleSortButtonClick(value.click)"
              >
              </p-radioButton>
            </div>
          </ng-template>
        </p-overlayPanel>
      </div>
    </div>

    <div *ngIf="mode === deskNavigationModeEnum.Task">
      <h1>{{ selectedLanguage.task }}</h1>
      <button
        class="p-button-outlined"
        (click)="handleArchiveTask(true)"
        pButton
        type="button"
        [label]="selectedLanguage.finish_task"
      ></button>
    </div>
    <div *ngIf="mode === deskNavigationModeEnum.ArchivedTask">
      <h1>{{ selectedLanguage.archived_task }}</h1>
      <button
        class="p-button-outlined"
        (click)="handleArchiveTask(false)"
        pButton
        type="button"
        [label]="selectedLanguage.unarchive_task"
      ></button>
    </div>
    <div *ngIf="mode === deskNavigationModeEnum.CreateTask">
      <h1>{{ selectedLanguage.create_task }}</h1>
    </div>

    <div *ngIf="mode === deskNavigationModeEnum.Settings">
      <h1>{{ selectedLanguage.settings }}</h1>

      <p-tabMenu
        [model]="settingsMenuItems"
        [activeItem]="settingsMenuActiveItem"
      ></p-tabMenu>
    </div>

    <div *ngIf="mode === deskNavigationModeEnum.CreateUser">
      <h1>{{ selectedLanguage.create_user }}</h1>
      <p-tabMenu
        [model]="settingsMenuItems"
        [activeItem]="settingsMenuActiveItem"
      ></p-tabMenu>
    </div>

    <div *ngIf="mode === deskNavigationModeEnum.User">
      <h1>{{ selectedLanguage.user_details }}</h1>
      <p-tabMenu
        [model]="settingsMenuItems"
        [activeItem]="settingsMenuActiveItem"
      ></p-tabMenu>
    </div>

    <div *ngIf="mode === deskNavigationModeEnum.Profile">
      <h1>{{ selectedLanguage.profile }}</h1>
    </div>

    <div *ngIf="mode === deskNavigationModeEnum.Company">
      <h1>{{ selectedLanguage.company_details }}</h1>
      <p-tabMenu
        [model]="settingsMenuItems"
        [activeItem]="settingsMenuActiveItem"
      ></p-tabMenu>
    </div>

    <div *ngIf="mode === deskNavigationModeEnum.CreateCompany">
      <h1>{{ selectedLanguage.create_company }}</h1>
      <p-tabMenu
        [model]="settingsMenuItems"
        [activeItem]="settingsMenuActiveItem"
      ></p-tabMenu>
    </div>
    <div *ngIf="mode === deskNavigationModeEnum.Language">
      <h1>{{ selectedLanguage.language }}</h1>
      <p-tabMenu
        [model]="settingsMenuItems"
        [activeItem]="settingsMenuActiveItem"
      ></p-tabMenu>
    </div>
  </ng-container>
</ng-container>
