import { ChangeDetectorRef } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import * as l from 'lodash';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, takeUntil, tap } from 'rxjs/operators';

import { environment } from '../../../environments/environment';
import { IDropdownOption } from '../../types/types/interfaces';
import { IInput } from '../../types/types/interfaces/input.interface';
import { LocalizationStore } from '../stores/localization.store';

export class HelperService {
  private localizationStore: LocalizationStore;

  construct(localizationStore: LocalizationStore): void {
    this.localizationStore = localizationStore;
  }

  dropdownOptionsFromEnum(e: Record<string, any>): Promise<IDropdownOption[]> {
    return Promise.all(
      (Object.entries(e) as [string, string][]).map(
        async ([k, v]: [string, string]) => {
          let label = (
            (await this.localizationStore?.selectedLanguage()) as Record<
              string,
              any
            >
          )[v];

          label = label ? label : k;

          return {
            label,
            value: v,
          };
        }
      )
    );
  }

  pathToArray(path: string): string[] {
    return path.split('/').filter((el) => el !== '');
  }

  defensiveIncludes(a: string, b: string): boolean {
    return a.toLowerCase().includes(b.toLocaleLowerCase());
  }

  defensiveIndexOf(a: string, b: string): number {
    return a.toLowerCase().indexOf(b.toLocaleLowerCase());
  }

  errorMessageFromErrors(errors: ValidationErrors): string {
    let result = '';

    if (errors) {
      result = Object.entries(errors).map(([k, _]) => k)[0];

      switch (result) {
        case 'required': {
          result = 'this_field_is_required';
          break;
        }
      }
    }

    return result;
  }

  resolveField<T>(value: Record<string, any>, field: string): T {
    const fieldElements = field.split('.');

    let result: any = value;
    fieldElements.forEach((el) => {
      result = result[el] || null;
    });

    return result;
  }

  async lastMarble<T>(observable: Observable<T>): Promise<T> {
    return await observable
      .pipe(
        filter((val) => val !== undefined),
        first()
      )
      .toPromise();
  }

  hasOverlap<T>(a: T[] | T, b: T[] | T): boolean {
    const squeeze = (x: T[] | T) => {
      let result = x ? x : [];
      result = Array.isArray(x) ? x : [x];
      return result;
    };

    a = squeeze(a);
    b = squeeze(b);

    return !!l.intersection(a, b).length;
  }

  detectChanges(cd: ChangeDetectorRef): void {
    if (!(cd as any).destroyed) {
      cd.detectChanges();
    }
  }

  regularlyCall(fn: () => any): () => void {
    let clearFn = () => {};

    fn();

    if (environment.realTime) {
      const interval = setInterval(fn, 5000);
      clearFn = () => clearInterval(interval);
    }

    return clearFn;
  }

  updateFromInput(
    input: IInput,
    previousAsInput?: IInput
  ): Record<string, any> {
    let nonce = Object.entries(input);

    if (previousAsInput) {
      nonce = nonce.filter(([k, v]) => (previousAsInput as any)[k] !== v);
    }

    return nonce.reduce((accum, [k, v]) => {
      (accum as any)[k] = { value: v, change: true };
      return accum;
    }, {});
  }

  insertIntoListSubject<T extends { value: { id: string } } | { id: string }>(
    listSubject: BehaviorSubject<T[]>,
    element: T
  ): void {
    const users = l.cloneDeep(listSubject.value);
    const el: any = element;

    const userIndex = users
      .map((user: any) => (user.value ? user.value.id : user.id))
      .indexOf(el.value ? el.value.id : el.id);

    if (userIndex !== -1) {
      users[userIndex] = element;
    } else {
      users.push(element);
    }

    listSubject.next(l.cloneDeep(users));
  }
}
