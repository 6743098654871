
    <div class="p-grid p-field" [ngClass]="to.wrapperClasses">
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col-3 p-pb-0">
        <p-calendar
          [disabled]="to.disabled"
          [touchUI]="true"
          [showIcon]="true"
          (onFocus)="to.handleInput()"
          (onSelect)="handleChange(field, $event)"
          [(ngModel)]="to.defaultValue"
          [dateFormat]="to.dateFormat"
          [showTime]="to.showTime"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
        >
        </p-calendar>
      </div>
    </div>
  