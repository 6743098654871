import { gql } from 'apollo-angular';

export const CreateProfileImageMutation = gql`
  mutation CreateProfileImage($profileImage: ProfileImageInput!) {
    createProfileImage(profileImage: $profileImage) {
      id
      profileImage
      profileImageTitle
      profileImageType
    }
  }
`;
