<div class="card">
  <div class="error404">Error 404</div>
  <div class="text">
    <p>The Page you are looking for does not exist.</p>
    <p>Please go back to our Login-Page or visit us on our Website!</p>
  </div>
  <div class="buttons p-mt-6">
    <button
      pButton
      routerLink="/"
      type="button"
      label="Login"
      icon="pi pi-home"
      class="p-button-success p-button-outlined p-button-lg p-mr-6"
    ></button>
    <button
      (click)="navigateToWebsite()"
      pButton
      type="button"
      label="Website"
      icon="pi pi-globe"
      class="p-button-info p-button-outlined p-button-lg"
    ></button>
  </div>
  <div pRipple class="styled-box-green"></div>
</div>
