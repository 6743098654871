import * as l from 'lodash';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, first, map, tap } from 'rxjs/operators';

import {
  CreateCompanyMutation,
  CreateLocationMutation,
  EditCompanyMutation,
  EditLocationMutation,
} from '../../graphql/mutations';
import {
  CompaniesQuery,
  CompanyQuery,
  LocationsQuery,
  MyselfLocationsQuery,
} from '../../graphql/queries';
import {
  Company,
  CompanyInput,
  CompanyUpdate,
  Location,
  LocationInput,
  LocationUpdate,
  User,
} from '../../types/graphql';
import { IDropdownOption } from '../../types/types/interfaces';
import { GraphqlService } from '../services/graphql.service';
import { HelperService } from '../services/helper.service';

import { CompanyStore } from './company.store';
import { LocalizationStore } from './localization.store';

/**
 * @description The CompanysStore takes care of fetching companys
 */
export class CompaniesStore {
  companies$: Observable<CompanyStore[]>;
  locations$: Observable<Location[]>;

  private graphqlService: GraphqlService;
  private localizationStore: LocalizationStore;

  private locationsSubject = new BehaviorSubject<Location[]>([]);

  private companiesSubject = new BehaviorSubject<CompanyStore[]>([]);

  constructor(
    private helperService: HelperService,
    private messageService: MessageService
  ) {
    this.companies$ = this.companiesSubject.asObservable();
    this.locations$ = this.locationsSubject.asObservable();
  }

  /***
   * @description Not an update function!
   * This converts a LocationInput into a LocationUpdate
   */
  private async locationUpdateFromLocationInput(
    locationId: string,
    locationInput: Partial<LocationInput>
  ): Promise<Partial<LocationUpdate>> {
    return await this.locations$
      .pipe(
        first(),
        map((locations: Location[]) =>
          locations.find((el) => el.id === locationId)
        ),
        map((location: Location) => {
          return this.helperService.updateFromInput(
            locationInput,
            this.locationInputFromLocation(location)
          );
        })
      )
      .toPromise();
  }

  /***
   * @description Not an update function!
   * This converts a CompanyInput into a CompanyUpdate
   */
  private async companyUpdateFromCompanyInput(
    companyId: string,
    companyInput: Partial<CompanyInput>
  ): Promise<Partial<CompanyUpdate>> {
    return await this.companies$
      .pipe(
        first(),
        map((companies: CompanyStore[]) => companies.map((el) => el.value)),
        map((companies: Company[]) =>
          companies.find((el) => el.id === companyId)
        ),
        map((company: Company) => {
          return this.helperService.updateFromInput(
            companyInput,
            this.companyInputFromCompany(company)
          );
        })
      )
      .toPromise();
  }

  private insertCompany(companyStore: CompanyStore): void {
    this.helperService.insertIntoListSubject(
      this.companiesSubject,
      companyStore
    );
  }

  private insertLocation(location: Location): void {
    this.helperService.insertIntoListSubject(this.locationsSubject, location);
  }

  construct(
    graphqlService: GraphqlService,
    localizationStore: LocalizationStore
  ): void {
    this.graphqlService = graphqlService;
    this.localizationStore = localizationStore;
  }

  async fetchLocations(): Promise<void> {
    (
      await this.graphqlService.sendRequest<Location[]>({
        field: 'locations',
        query: LocationsQuery,
        loadingMessage: '', // Localize
      })
    )
      .pipe(
        tap((locations) => {
          this.locationsSubject.next(locations);
        })
      )
      .subscribe();
  }

  async fetchCompanies(): Promise<void> {
    (
      await this.graphqlService.sendRequest<Company[]>({
        field: 'companies',
        query: CompaniesQuery,
        loadingMessage: (
          await this.localizationStore?.selectedLanguage$
            .pipe(first())
            .toPromise()
        )?.loading_get_companies,
      })
    )
      .pipe(
        tap((companies) => {
          this.companiesSubject.next(
            companies.map((company) => new CompanyStore(company))
          );
        })
      )
      .subscribe();
  }

  async updateLocation(
    locationId: string,
    locationInput: Partial<LocationInput>
  ): Promise<void> {
    const locationUpdate = await this.locationUpdateFromLocationInput(
      locationId,
      locationInput
    );
    const t = await this.localizationStore.selectedLanguage();

    await this.graphqlService
      .sendRequest<Location>({
        field: 'editLocation',
        mutation: EditLocationMutation,
        variables: { location: locationUpdate, locationId },
        loadingMessage: t.loading_update_location,
      })
      .pipe(
        tap((location) => {
          this.insertLocation(location);
        }),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t.feedback_updated_location,
          })
        ),
        first()
      )
      .toPromise();
  }

  async updateCompany(
    companyId: string,
    companyInput: Partial<CompanyInput>
  ): Promise<void> {
    const companyUpdate = await this.companyUpdateFromCompanyInput(
      companyId,
      companyInput
    );

    await this.graphqlService
      .sendRequest<Company>({
        field: 'editCompany',
        mutation: EditCompanyMutation,
        variables: { company: companyUpdate, companyId },
        loadingMessage: (
          await this.localizationStore?.selectedLanguage$
            .pipe(first())
            .toPromise()
        )?.loading_update_company,
      })
      .pipe(
        map((company) => new CompanyStore(company)),
        tap((company) => {
          this.insertCompany(company);
        }),
        first()
      )
      .toPromise();
  }

  async createCompany(
    companyInput: CompanyInput
  ): Promise<Observable<CompanyStore>> {
    const t = await this.localizationStore.selectedLanguage();

    return this.graphqlService
      .sendRequest<Company>({
        field: 'createCompany',
        mutation: CreateCompanyMutation,
        variables: { company: companyInput },
        loadingMessage: t.loading_create_company,
      })
      .pipe(
        map((company) => new CompanyStore(company)),
        tap(this.insertCompany.bind(this)),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t.feedback_created_company,
          })
        ),
        first()
      );
  }

  getCompany(companyId: string): Observable<CompanyStore> {
    return this.companies$.pipe(
      map((companies) => {
        return companies.find((company) => company.value.id === companyId);
      })
    );
  }

  getLocation(locationId: string): Observable<Location> {
    return this.locations$.pipe(
      map((locations) => {
        return locations.find((location) => location.id === locationId);
      })
    );
  }

  dropdownOptionFromCompany({ companyName, id }: Company): IDropdownOption {
    return {
      label: companyName,
      value: id,
    };
  }

  dropdownOptionFromLocation({ name, id }: Location): IDropdownOption {
    return { label: name, value: id };
  }

  async fetchMyCompanyLocations(): Promise<Location[]> {
    const t = await this.localizationStore?.selectedLanguage();

    return await this.graphqlService
      .sendRequest<User>({
        field: 'myself',
        query: MyselfLocationsQuery,
      })
      .pipe(
        filter((val) => !!val?.assignedLocation?.assignedCompany?.locations),
        map((user) => user.assignedLocation.assignedCompany.locations),
        first()
      )
      .toPromise();
  }

  companyInputFromCompany(company: Company): CompanyInput {
    return l.omit(company, [
      'id',
      '__typename',
      'created_at',
      'updated_at',
      'deletedAt',
    ]) as CompanyInput;
  }

  locationInputFromLocation(location: Location): LocationInput {
    return l.omit(location, [
      'id',
      '__typename',
      'created_at',
      'updated_at',
      'deletedAt',
      'errorMessage',
      'buildingNumber', // HOTFIX: delete unused Fields
      'latitude',
      'longitude',
      'mobileNumber',
      'phoneNumber',
      'postcode',
      'state',
      'streetName',
    ]) as unknown as LocationInput;
  }

  async fetchCompany(companyId: string): Promise<void> {
    const t = await this.localizationStore?.selectedLanguage();

    await this.graphqlService
      .sendRequest<Company>({
        field: 'company',
        query: CompanyQuery,
        variables: { companyId },
        loadingMessage: t.loading_get_company,
      })
      .pipe(
        map((company) => new CompanyStore(company)),
        tap(this.insertCompany.bind(this)),
        first()
      )
      .toPromise();
  }

  /**
   * @description this doesnt mutate the companiesSubject!
   */
  async createLocation(
    locationInput: LocationInput,
    companyId: string
  ): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();

    await this.graphqlService
      .sendRequest<Location>({
        field: 'createLocation',
        mutation: CreateLocationMutation,
        variables: { location: locationInput, companyId },
        loadingMessage: t.loading_create_location,
      })
      .pipe(
        map((location) => location),
        tap(this.insertLocation.bind(this)),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t.feedback_created_location,
          })
        ),
        first()
      )
      .toPromise();
  }

  /**
   * @description this doesnt mutate the companiesSubject!
   */
  async editLocation(
    locationInput: LocationInput,
    locationId: string
  ): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();
    const locationUpdate = await this.locationUpdateFromLocationInput(
      locationId,
      locationInput
    );

    await this.graphqlService
      .sendRequest<Location>({
        field: 'editLocation',
        mutation: EditLocationMutation,
        variables: { location: locationUpdate, locationId },
        loadingMessage: t.loading_update_location,
      })
      .pipe(
        map((location) => location),
        tap(this.insertLocation.bind(this)),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t.feedback_updated_location,
          })
        ),
        first()
      )
      .toPromise();
  }
}
