export * from './create-task.mutation';
export * from './edit-task.mutation';
export * from './edit-language.mutation';
export * from './create-file.mutation';
export * from './delete-file.mutation';
export * from './edit-company.mutation';
export * from './create-user.mutation';
export * from './create-comment.mutation';
export * from './edit-location.mutation';
export * from './create-location.mutation';
export * from './create-company.mutation';
export * from './add-follower.mutation';
export * from './remove-follower.mutation';
export * from './archive-task.mutation';
export * from './create-profile-image.mutation';
export * from './delete-profile-image.mutation';
