import { gql } from 'apollo-angular';

export const CreateTaskMutation = gql`
  mutation CreateTask($task: TaskInput!) {
    createTask(task: $task) {
      __typename
      id
    }
  }
`;
