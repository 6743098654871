import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { Subject, Subscription } from 'rxjs';
import { delay, first, takeUntil, tap } from 'rxjs/operators';

import { RootStore } from './store/root.store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  loadingMessages$ = this.root.applicationStore.loadingMessages$.pipe(
    delay(400)
  );

  private componentDestroyedSubject = new Subject();
  private profileSubscription: Subscription;
  constructor(public root: RootStore, private primengConfig: PrimeNGConfig) {}

  @HostListener('window:beforeunload', ['$event'])
  async doSomething($event: any): Promise<void> {
    if (
      await this.root.applicationStore.unsavedChanges$.pipe(first()).toPromise()
    ) {
      $event.returnValue = (
        await this.root.localizationStore.selectedLanguage()
      ).warning_unsaved_changes;
    }
  }

  ngOnInit(): void {
    this.primengConfig.ripple = true;

    this.profileSubscription = this.root.authService.myself$
      .pipe(
        takeUntil(this.componentDestroyedSubject),
        tap((myself) => {
          // TODO: Localstorage language persistence
          this.root.localizationStore
            .fetchLanguages()
            .then(() =>
              this.root.localizationStore.selectLanguage(
                myself.assignedLanguage.language_code
              )
            );
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.componentDestroyedSubject.next();
    this.componentDestroyedSubject.complete();
  }
}
