import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doesnt-exist',
  templateUrl: './doesnt-exist.component.html',
  styleUrls: ['./doesnt-exist.component.scss'],
})
export class DoesntExistComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}

  navigateToWebsite(): void {
    window.open('https://www.forstlogistik.ch/', '_blank');
  }
}
