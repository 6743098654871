
    <div class="p-grid p-field" [ngClass]="to.wrapperClasses">
      <label class="p-col-3">{{ to.label }}{{ to.required ? '*' : '' }}</label>
      <div class="p-col p-pb-0">
        <p-multiSelect
          *ngIf="to.multiple; else dropdown"
          selectedItemsLabel="..."
          [disabled]="to.disabled"
          [ngModel]="to.defaultValue"
          [options]="to.options | formlySelectOptions: field | async"
          [formControl]="$any(formControl)"
          [formlyAttributes]="field"
          (onChange)="handleChange(field, $event)"
        ></p-multiSelect>
        <ng-template #dropdown>
          <p-dropdown
            [disabled]="to.disabled"
            [ngModel]="to.defaultValue"
            [filter]="to.filter"
            filterBy="label"
            [options]="to.options | formlySelectOptions: field | async"
            [formControl]="$any(formControl)"
            [formlyAttributes]="field"
            (onChange)="handleChange(field, $event)"
          >
            <ng-container *ngIf="to.displayAsTags">
              <ng-template pTemplate="selectedItem">
                <p-tag
                  styleClass="p-mr-2 {{ formControl.value }}"
                  [value]="texts[formControl.value]"
                  [rounded]="true"
                ></p-tag>
              </ng-template>
              <ng-template let-item pTemplate="item">
                <p-tag
                  styleClass="p-mr-2 {{ item.value }}"
                  [value]="item.label"
                  [rounded]="true"
                ></p-tag>
              </ng-template>
            </ng-container>
          </p-dropdown>
        </ng-template>
      </div>
    </div>
  