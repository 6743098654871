import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Apollo } from 'apollo-angular';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  catchError,
  filter,
  finalize,
  first,
  map,
  pluck,
  retry,
  tap,
} from 'rxjs/operators';

import { ApplicationStore } from '../stores/application.store';
import { LocalizationStore } from '../stores/localization.store';

import { AuthService } from './auth.service';

interface RequestArgs {
  field: string;
  mutation?: any;
  query?: any;
  variables?: Record<string, any>;
  loadingMessage?: string;
  useMultipart?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class GraphqlService {
  queriesInProgress$: Observable<number>;
  mutationsInProgress$: Observable<number>;

  private queriesInProgressSubject = new BehaviorSubject<number>(0);
  private mutationsInProgressSubject = new BehaviorSubject<number>(0);

  private authService: AuthService;
  private localizationStore: LocalizationStore;
  private applicationStore: ApplicationStore;

  constructor(
    private apollo: Apollo,
    private messageService: MessageService,
    private router: Router
  ) {
    this.queriesInProgress$ = this.queriesInProgressSubject.asObservable();
    this.mutationsInProgress$ = this.mutationsInProgressSubject.asObservable();
  }

  private async handleInsufficientRights(): Promise<void> {
    const t = await this.localizationStore.selectedLanguage();
    this.router.navigateByUrl('/tasks');
    this.messageService.add({
      severity: 'error',
      summary: t.insufficient_rights,
      detail: t.insufficient_rights_message,
    });
  }

  private request({
    field,
    query,
    mutation,
    variables,
    loadingMessage,
    useMultipart,
  }: RequestArgs): Observable<any> {
    let result: Observable<any>;

    const fetchPolicy = 'no-cache';

    const context = {
      headers: new HttpHeaders().set(
        'Authorization',
        'Bearer ' + this.authService.getAccessToken()
      ),
      useMultipart: useMultipart == null ? false : useMultipart,
    };

    if (query) {
      result = this.apollo.watchQuery({
        query,
        variables,
        fetchPolicy,
        context,
      }).valueChanges;
    } else {
      result = this.apollo.mutate({
        mutation,
        variables,
        fetchPolicy,
        context,
      });
    }

    // Handle failed request
    result = result.pipe(
      retry(1),
      catchError(async (err: Error) => {
        console.error(err);
        this.messageService.add({
          severity: 'error',
          summary: (await this.localizationStore?.selectedLanguage())
            ?.summary_general_error,
          detail: err.message,
        });

        // TODO: Improve logic
        if (
          query &&
          err.message.toLowerCase().includes('internal server error')
        ) {
          this.handleInsufficientRights();
        }

        if (err.message.toLowerCase().includes('unauthenticated')) {
          this.authService.logout();
        }

        return { data: null };
      }),
      pluck('data'),
      filter((val) => !!val),
      tap((val) => {
        (async () => {
          if (val.errorMessage) {
            this.messageService.add({
              severity: 'error',
              summary: (await this.localizationStore.selectedLanguage())
                ?.summary_general_error,
              detail: val.errorMessage,
            });
          } else if (!val[field]) {
            this.handleInsufficientRights();
          }
        })();
      }),
      map((val) => {
        return val[field] || null;
      }),
      first()
    );

    return result;
  }

  /***
   * @description This function has to be called by the parent once
   * It should not contain any logic!
   */
  construct(
    authService: AuthService,
    localizationStore: LocalizationStore,
    applicationStore: ApplicationStore
  ): void {
    this.authService = authService;
    this.localizationStore = localizationStore;
    this.applicationStore = applicationStore;
  }

  sendRequest<T>(requestArgs: RequestArgs): Observable<T> {
    if (requestArgs.loadingMessage) {
      this.applicationStore.addLoadingMessage(
        requestArgs.loadingMessage,
        !!requestArgs.mutation
      );
    }

    const isQuery = !!requestArgs.query;

    if (isQuery) {
      this.queriesInProgressSubject.next(
        this.queriesInProgressSubject.value + 1
      );
    } else {
      this.mutationsInProgressSubject.next(
        this.mutationsInProgressSubject.value + 1
      );
    }

    return this.request(requestArgs).pipe(
      finalize(() => {
        if (requestArgs.loadingMessage) {
          this.applicationStore.removeLoadingMessage(
            requestArgs.loadingMessage
          );
        }

        if (isQuery) {
          this.queriesInProgressSubject.next(
            this.queriesInProgressSubject.value - 1
          );
        } else {
          this.mutationsInProgressSubject.next(
            this.mutationsInProgressSubject.value - 1
          );
        }
      })
    );
  }
}
