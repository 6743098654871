<div
  class="p-grid"
  *ngIf="root.localizationStore.selectedLanguage$ | async as selectedLanguage"
>
  <div class="p-col-6">
    <h2 class="p-mb-6">{{ selectedLanguage.company }}</h2>
    <div class="p-fluid">
      <app-company-form (submitForm)="handleSubmit($event)"></app-company-form>
    </div>
  </div>
  <div class="p-col-5"></div>
</div>
