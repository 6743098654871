import { gql } from 'apollo-angular';

export const TasksQuery = gql`
  {
    tasks {
      __typename
      id
      createdAt
      updatedAt
      deletedAt
      archivedAt
      dueDate
      title
      description
      status
      category
      isHighPriority
      files {
        id
        fileTitle
        fileDisplayTitle
      }
      assignedLocation {
        __typename
        id
        name
      }
      assignedCompany {
        __typename
        id
        companyLogo
        companyName
        createdAt
        deletedAt
        updatedAt
      }
      assignedUser {
        __typename
        id
        createdAt
        updatedAt
        deletedAt
        emailVerifiedAt
        birthday
        salutation
        firstName
        lastName
        email
        telephone
        mobile
        positionInCompany
      }
      followers {
        id
        firstName
        lastName
      }
      user {
        __typename
        id
        createdAt
        updatedAt
        deletedAt
        emailVerifiedAt
        birthday
        salutation
        firstName
        lastName
        email
        telephone
        mobile
        positionInCompany
      }
    }
  }
`;
