import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';

import { RootStore } from '../../../store/root.store';
import { TaskStore } from '../../../store/stores/task.store';

@Component({
  selector: 'app-task-card',
  templateUrl: './task-card.component.html',
  styleUrls: ['./task-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TaskCardComponent implements OnInit {
  @Input() taskStore: TaskStore;
  constructor(public root: RootStore) {}

  ngOnInit(): void {}
}
