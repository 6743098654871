import { gql } from 'apollo-angular';

export const CreateLocationMutation = gql`
  mutation CreateLocation($location: LocationInput!, $companyId: String!) {
    createLocation(location: $location, companyId: $companyId) {
      __typename
      id
    }
  }
`;
