import * as l from 'lodash';
import { MessageService } from 'primeng/api';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map, shareReplay, tap } from 'rxjs/operators';

import { EditLanguageMutation } from '../../graphql/mutations';
import { LanguagesQuery } from '../../graphql/queries';
import { Language, LanguageInput } from '../../types/graphql';
import { IDropdownOption, ILanguageRow } from '../../types/types/interfaces';
import { GraphqlService } from '../services/graphql.service';
import { HelperService } from '../services/helper.service';

export class LocalizationStore {
  languages$: Observable<Language[]>;
  selectedLanguage$: Observable<Language>;

  private helperService: HelperService;
  private graphqlService: GraphqlService;

  private languagesSubject = new BehaviorSubject<Language[]>([]);
  private messageService: MessageService;

  private noFluffLanguageTuplesFromLanguage(
    language: Language
  ): [string, string][] {
    if (language) {
      return Object.entries(language).filter(
        ([k, _]) => k !== 'id' && k !== '__typename'
      );
    } else {
      return [];
    }
  }

  construct(
    helperService: HelperService,
    graphqlService: GraphqlService,
    messageService: MessageService
  ): void {
    this.helperService = helperService;
    this.graphqlService = graphqlService;
    this.messageService = messageService;
  }

  initialize(): void {
    this.languages$ = this.languagesSubject.asObservable();
    this.selectLanguage('en');
  }

  async selectedLanguage(): Promise<Language | undefined> {
    return this.helperService?.lastMarble(this.selectedLanguage$);
  }

  /***
   * @description This function has side effect(s)
   */
  selectLanguage(lang: string): void {
    this.selectedLanguage$ = this.languages$.pipe(
      map((languages) =>
        languages.find((language) => language.language_code === lang)
      ),
      shareReplay()
    );
  }

  /***
   * @description This function has side effect(s)
   */
  async fetchLanguages(): Promise<void> {
    (
      await this.graphqlService.sendRequest<Language[]>({
        field: 'languages',
        query: LanguagesQuery,
      })
    )
      .pipe(tap((languages) => this.languagesSubject.next(languages)))
      .subscribe();
  }

  /***
   * @description This function has side effect(s)
   */
  async updateLanguage(
    languageId: string,
    languageInput: LanguageInput
  ): Promise<void> {
    const t = await this.selectedLanguage();
    (
      await this.graphqlService.sendRequest<Language>({
        field: 'editLanguage',
        mutation: EditLanguageMutation,
        variables: { language: languageInput, languageId },
        loadingMessage: t.loading_update_language,
      })
    )
      .pipe(
        tap((language) =>
          this.languagesSubject.next([
            ...this.languagesSubject.value.filter(
              (lang) => languageId !== lang.id
            ),
            language,
          ])
        ),
        tap(() =>
          this.messageService.add({
            severity: 'success',
            summary: t.feedback_updated_language,
          })
        )
      )
      .subscribe();
  }

  translationPercentage(languageId: string): Observable<number> {
    return this.languages$.pipe(
      map((languages) => {
        const language = languages.find((el) => el.id === languageId);
        const languageEntries =
          this.noFluffLanguageTuplesFromLanguage(language);

        const countOfFields = languageEntries.length;
        const countOfFieldsFilled: number = languageEntries.filter(
          ([_, v]) => !!v?.length
        ).length;

        return Math.floor((countOfFieldsFilled / countOfFields) * 100);
      })
    );
  }

  languageRows(languageId: string): Observable<ILanguageRow[]> {
    if (languageId) {
      return this.languages$.pipe(
        map((languages) => {
          const english = this.noFluffLanguageTuplesFromLanguage(
            languages.find((el) => el.language_code === 'en')
          );

          const language = this.noFluffLanguageTuplesFromLanguage(
            languages.find((el) => el.id === languageId)
          );

          return language.map(([key, value]) => {
            const eng = english.find(([k, _]) => k === key);

            return {
              key,
              eng: eng ? (eng[1] ? eng[1] : '') : '',
              value,
            };
          });
        })
      );
    } else {
      return of([]);
    }
  }

  languageInputFromLang(language: Language): LanguageInput {
    return l.omit(language, ['id', '__typename']);
  }

  dropdownOptionFromLanguage({ language_name, id }: Language): IDropdownOption {
    return {
      label: language_name,
      value: id,
    };
  }
}
