import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import * as l from 'lodash';
import { BehaviorSubject, combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RootStore } from '../../store/root.store';
import { Location } from '../../types/graphql';
import { Column } from '../../types/types/interfaces';

@Component({
  selector: 'app-companies-settings',
  templateUrl: './companies-settings.component.html',
  styleUrls: ['./companies-settings.component.scss'],
})
export class CompaniesSettingsComponent implements OnInit {
  displayedLocations$: Observable<Location[]>;

  columns: Column[];

  private searchTextSubject = new BehaviorSubject('');

  set searchText(text: string) {
    this.searchTextSubject.next(text);
  }

  constructor(public root: RootStore, private router: Router) {}

  async ngOnInit(): Promise<void> {
    await this.root.companiesStore.fetchLocations();
    this.displayedLocations$ = combineLatest([
      this.root.companiesStore.locations$,
      this.searchTextSubject,
    ]).pipe(
      map(([locations, text]) =>
        locations
          .filter(
            (location) =>
              !text ||
              [
                location.assignedCompany.companyName,
                location.name,
                location.city,
                location.country,
                location.users?.length?.toString() || '',
              ]
                .map((el) => el?.toLowerCase())
                .find((el) => el?.includes(text?.toLowerCase()))
          )
          .map((el) => l.mapValues(el, (val) => val || '') as Location)
      )
    );
    const t = await this.root.localizationStore.selectedLanguage();

    this.columns = (() => {
      const names = [
        t.companies,
        t.location_name,
        t.location_city,
        t.country,
        t.number_of_employees,
      ];
      const fields = [
        'assignedCompany.companyName',
        'name',
        'city',
        'country',
        'users.length',
      ];
      return names.map((name, i) => ({
        name: name || '',
        field: fields[i],
      }));
    })();

    this.root.companiesStore.fetchLocations();
  }

  navigateToCompany(location: Location): void {
    this.router.navigateByUrl(
      'settings/companies/' + location.assignedCompany.id
    );
  }

  async toggleLockForLocation(location: Location): Promise<void> {
    await this.root.companiesStore.updateLocation(
      location.id,
      this.root.companiesStore.locationInputFromLocation({
        ...location,
        isActive: !location.isActive,
      })
    );
  }
}
